import React, { useState} from 'react';
import {Form, Col} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import {currencies} from "../../../../../../config/data/currencies";
import countryList from "react-select-country-list";
import { findCountry } from '../../../../../../common/functions/utils';
import ModalButton from "../../../../../../components/ModalButton";
import { Field } from 'formik';
import styles from '../../rentals.module.css';
import { useSelector } from 'react-redux';
import TimezoneSelect from 'react-timezone-select';
import Loading from '../../../../../../components/loading';

function PropertyForm(props){
    const {
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
    } = props;
    const [color, setColor] = useState('#dcdcdc');
    const [primaryPhone, setPrimaryPhone] = useState(values.phoneNumber);
    const user = useSelector(({user})=> user && user.user);
    const code = values?.country ? findCountry(values?.country) : findCountry(user.country);
    const colorDictionary = useSelector(({ generalSetting }) => {
        const colorsSetting = generalSetting && generalSetting.setting.colors;
        const map = [];
        for (const key in colorsSetting) {
            if (key.startsWith('color')) {
                map.push(colorsSetting[key]);
            }
        };
        return map;
    });
    const countryOptions = countryList().getData();
    const currencyOptions = currencies;
    const { setting, actionForSettings, actionForSettingsEdit } = useSelector(({ generalSetting }) => generalSetting && generalSetting);
    const [timezone, setTimezone] = useState((values?.timezone ?? setting?.timezone));
    
    return (
        <Form onSubmit={handleSubmit} >
            <Loading loadingStatus={actionForSettings.loading || actionForSettingsEdit.loading } />
            <Form.Row className="py-1 ">
                <Form.Label className="col-sm-4 col-form-label">Property name*: </Form.Label>
                <Col>
                    <Form.Control
                        type="text"
                        name="name"
                        onChange={handleChange}
                        isInvalid={!!errors.name }
                        value={values.name}
                        style={{fontSize:'inherit'}}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Col>
            </Form.Row>
            <Form.Row className="py-1">
                <Form.Label className="col-sm-4 col-form-label">Street and no: </Form.Label>
                <Col>
                    <Form.Control
                        type="text"
                        name="street"
                        onChange={handleChange}
                        isInvalid={!!errors.street }
                        value={values.street}
                        style={{fontSize:'inherit'}}
                    />
                    <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
                </Col>
            </Form.Row>
            <Form.Row className="py-1">
                <Form.Label className="col-sm-4 col-form-label">City: </Form.Label>
                <Col>
                    <Form.Control
                        type="text"
                        name="city"
                        onChange={handleChange}
                        isInvalid={!!errors.city }
                        value={values.city}
                        style={{fontSize:'inherit'}}
                    />
                    <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                </Col>
            </Form.Row>
            <Form.Row className="py-1">
                <Form.Label className="col-sm-4 col-form-label">Postal code: </Form.Label>
                <Col>
                    <Form.Control
                        type="text"
                        name="postalCode"
                        onChange={handleChange}
                        isInvalid={!!errors.postalCode }
                        value={values.postalCode}
                        style={{fontSize:'inherit'}}
                    />
                    <Form.Control.Feedback type="invalid">{errors.postalCode}</Form.Control.Feedback>
                </Col>
            </Form.Row>
            <Form.Row className="py-1">
                <Form.Label className="col-sm-4 col-form-label">Select country: </Form.Label>
                <Col>
                    <Form.Control
                        as="select"
                        type="text"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.country}
                        style={{ fontSize: 'inherit' }}
                    >
                        { countryOptions.map( option => (
                                <option key={option.label} value={option.label} selected={values.country === option.label}>
                                    {option.label}
                                </option>
                        ))}
                    </Form.Control>
                </Col>
            </Form.Row>
            <Form.Row className="py-1">
                <Form.Label className="col-sm-4 col-form-label">Timezone: </Form.Label>
                <Col>
                    <TimezoneSelect
                        value={timezone}
                        onChange={(data) => {
                            setTimezone(data?.value);
                            setFieldValue('timezone',data?.value);
                        }}
                    />
                </Col>
            </Form.Row>
            <Form.Row className="py-1 ">
                <Form.Label className="col-sm-4 col-form-label">Licence/registration number: </Form.Label>
                <Col>
                    <Form.Control
                        type="text"
                        name="licenseNumber"
                        onChange={handleChange}
                        isInvalid={!!errors.licenseNumber }
                        value={values.licenseNumber}
                        style={{fontSize:'inherit'}}
                    />
                    <Form.Control.Feedback type="invalid">{errors.licenseNumber}</Form.Control.Feedback>
                </Col>
            </Form.Row>
            <Form.Row className="py-1">
                <Form.Label className="col-sm-4 col-form-label">Check-in time*: </Form.Label>
                <Col className={`d-sm-flex`}>
                <div className="m-0 p-0 mb-2 mb-sm-0 pr-sm-1 col-sm-5">
                    <Form.Control
                        as="select"
                        name="checkInTimeAt"
                        onChange={handleChange}
                        isInvalid={!!errors.checkInTimeAt}
                        value={values.checkInTimeAt}
                        style={{fontSize:'inherit'}}
                    >
                        <option value="at" key="at" selected={values.checkInTimeAt === "at"} >at &gt;&gt;</option>
                        <option value="from" key="from" selected={values.checkInTimeAt === "from"} >from &gt;&gt;</option>
                    </Form.Control>
                </div>
                <div className="m-0 p-0 mb-2 mb-sm-0 pl-sm-1 col-sm-7">
                    <Form.Control
                        type="time"
                        name="checkInTime"
                        onChange={handleChange}
                        isInvalid={!!errors.checkInTime }
                        value={values.checkInTime}
                        style={{fontSize:'inherit'}}
                    />
                    <Form.Control.Feedback type="invalid">{errors.checkInTime}</Form.Control.Feedback>
                </div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1">
                <Form.Label className="col-sm-4 col-form-label">Check-out time*: </Form.Label>
                <Col className={`d-sm-flex`}>
                <div className="m-0 p-0 mb-2 mb-sm-0 pr-sm-1 col-sm-5">
                    <Form.Control
                        as="select"
                        name="checkOutTimeAt"
                        onChange={handleChange}
                        isInvalid={!!errors.checkOutTimeAt}
                        value={values.checkOutTimeAt}
                        style={{fontSize:'inherit'}}
                    >
                        <option value="at" key="at" selected={values.checkOutTimeAt === "at"} >at &gt;&gt;</option>
                        <option value="until" key="until" selected={values.checkOutTimeAt === "until"} >until &gt;&gt;</option>
                    </Form.Control>
                </div>
                <div className="m-0 p-0 mb-2 mb-sm-0 pl-sm-1 col-sm-7">
                    <Form.Control
                        type="time"
                        name="checkOutTime"
                        onChange={handleChange}
                        isInvalid={!!errors.checkOutTime }
                        value={values.checkOutTime}
                        style={{fontSize:'inherit'}}
                    />
                    <Form.Control.Feedback type="invalid">{errors.checkOutTime}</Form.Control.Feedback>
                </div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1">
                <Form.Label className="col-sm-4 col-form-label">Currency: </Form.Label>
                <Col>
                    <Form.Control
                        as="select"
                        type="text"
                        name="currency"
                        value={values.currency}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.currency}
                        style={{ fontSize: 'inherit' }}
                    >
                        { currencyOptions.map( option => (
                                <option key={option.label} value={option.label} selected={values.currency === option.label}>
                                    {option.label}
                                </option>
                        ))}
                    </Form.Control>
                </Col>
            </Form.Row>
            <hr/>
                <label>Contact information</label>

                <Form.Row className="py-1 ">
                <Form.Label className="col-sm-4 col-form-label">Name: </Form.Label>
                <Col>
                    <Form.Control
                        type="text"
                        name="contactName"
                        onChange={handleChange}
                        isInvalid={!!errors.contactName }
                        value={values.contactName}
                        style={{fontSize:'inherit'}}
                    />
                    <Form.Control.Feedback type="invalid">{errors.contactName}</Form.Control.Feedback>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 ">
                <Form.Label className="col-sm-4 col-form-label">Phone number: </Form.Label>
                <Col>
                    <PhoneInput
                        country={code}
                        value={primaryPhone}
                        onChange={(value, data) => {
                            const rawVal = value.slice(data.dialCode.length);
                            const phone = rawVal ? `+${data.dialCode} ${rawVal}` : "";
                            setFieldValue("phoneNumber", phone);
                            setPrimaryPhone(phone || data.dialCode);
                        }}
                        inputStyle={{width: '100%'}}
                        countryCodeEditable={false}
                        enableSearch={true}
                        disableSearchIcon={true}
                    />
                    <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 ">
                <Form.Label className="col-sm-4 col-form-label">E-mail: </Form.Label>
                <Col>
                    <Form.Control
                        type="text"
                        name="email"
                        onChange={handleChange}
                        isInvalid={!!errors.email }
                        value={values.email}
                        style={{fontSize:'inherit'}}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Col>
            </Form.Row>
            <hr/>
            <Form.Row>
              <Form.Label className="col-2 col-sm-4 col-form-label">Colour:</Form.Label>
              <Col>
                <Col className={styles.containerColor}>
                    {colorDictionary.map((item) => (
                        <div
                        className={`${styles.containerBox} ${color === item ? styles.active : ``}`}
                        style={{ background: `${item}` }}
                        onClick={() => {
                            setColor(item);
                            values.color = item;
                        }}
                        key={item}
                        />
                    ))}
                </Col>
                <Col className="p-0 mt-2 ml-2 d-flex align-items-center">
                    <span className={styles.color_picker_text}>Colour Picker:</span>
                    <Field
                        type={'color'}
                        name={'color'}
                        value={values.color}
                        onChange={handleChange}
                        onClick={() => setColor(null)}
                        className={styles.form_entry_color}
                        required
                    />
                </Col>
              </Col>
            </Form.Row>
            <ModalButton btnText={"Save changes"} />
        </Form>
    )}
export default PropertyForm;

import {
    ADD_BOOKING,
    EDIT_BOOKING,
    EDIT_BOOKING_COLOR,
    LOAD_BOOKING,
    BOOKING_FLAG,
    CLEAR_BOOKING,
    SAVE_SPECIAL_BOOKING_DATA,
    CLEAR_SPECIAL_BOOKING_DATA,
    DELETE_BOOKING,
    GET_BOOKING_BY_GUEST_ID,
    GET_FEES_TAXES,
    DELETE_BLOCK_DATE,
    CHARGE_CALCULATION,
    RESET_CHARGES,
    ADD_DISCOUNT,
    FETCH_DISCOUNT,
    FETCH_DISCOUNT_BY_BOOKING,
    EDIT_DISCOUNT, FETCH_BOOKING_BY_ID,
    CLEAR_FEESTAXES,
} from './types';
import {BACKEND_API} from "../../config/middleware";
import API from "../../config/api-url";

export const addNewBooking = data => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:ADD_BOOKING,
            method: 'post',
            url: API.ADD_BOOKING,
            data
        },
    )
});

export const editBooking=data=>({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:EDIT_BOOKING,
            method: 'put',
            url: API.EDIT_BOOKING,
            data
        },
    )
})

export const editBookingColor = (data) => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type: EDIT_BOOKING_COLOR,
            method: 'put',
            url: API.EDIT_BOOKING_COLOR,
            data
        },
    )
})

export const loadBooking=()=>({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:LOAD_BOOKING,
            method: 'get',
            url: API.BOOKING,
        },
    )
})

export const resetBookingFlag=()=>({
    type:BOOKING_FLAG
})

export const clearBooking=()=>({
    type:CLEAR_BOOKING
})

export const saveSpecialBookingData = (data) => ({
    type: SAVE_SPECIAL_BOOKING_DATA,
    payload: data,
})

export const clearSpecialBookingData = () => ({
    type: CLEAR_SPECIAL_BOOKING_DATA
})

export const deleteBooking=(id)=>({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type: DELETE_BOOKING,
            method: 'delete',
            url:  `${API.BOOKING}${id}`,
            id,
        },
    )
})

export const getBookingByGuestId=(id)=>({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type: GET_BOOKING_BY_GUEST_ID,
            method: 'get',
            url: `${API.BOOKING}getBookingByGuestId/${id}`,
        },
    )
})

export const deleteBlockDate=(id)=>({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type: DELETE_BLOCK_DATE,
            method: 'delete',
            url:  `${API.BOOKING}${id}`,
            id,
        },
    )
})

export const chargeCalculation=(data)=>({
    type:BACKEND_API,
    payload: Object.assign(
        {
            type:CHARGE_CALCULATION,
            method:'post',
            url:`${API.CHARGES_CALC}`,
            data
        }
    )
})

export const addDiscount=(data)=>({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:ADD_DISCOUNT,
            method: 'post',
            url: API.DISCOUNT,
            data
        },
    )
});

export const editDiscount=(data,id)=>({
    type:BACKEND_API,
    payload: Object.assign({
        type:EDIT_DISCOUNT,
        method:'put',
        url:`${API.DISCOUNT}/${id}`,
        data
    })
});

export const fetchDiscount=()=>({
    type:BACKEND_API,
    payload:Object.assign(
        {
            type:FETCH_DISCOUNT,
            method:'get',
            url:API.DISCOUNT,
        }
    )
})

export const fetchDiscountByBooking=(id)=>({
    type:BACKEND_API,
    payload:Object.assign(
        {
            type:FETCH_DISCOUNT_BY_BOOKING,
            method:'get',
            url:`${API.DISCOUNT}/${id}`,
        }
    )
})

export const fetchFeesTaxesById = (id) => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type: GET_FEES_TAXES,
            method: 'get',
            url: `${API.FEES_TAXES}/${id}`,
        },
    )
});

export const clearFessTaxes = () => ({
    type: CLEAR_FEESTAXES
})

export const fetchBookingById=(id)=>({
    type:BACKEND_API,
    payload:Object.assign(
        {
            type:FETCH_BOOKING_BY_ID,
            method:'get',
            url:`${API.BOOKING}${id}`
        }
    )
})

export const resetCharges=()=>({
    type:RESET_CHARGES
})
import { isoCountries } from "../../config/data/iso2Country";

export const actionForState = (action, state, message) => ({
  ...action,
  success: state === "success",
  loading: state === "pending",
  error: state === "error",
  message: message || state,
});

export const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const zeroPad = (num, places) => String(num).padStart(places, "0");

export const hideTawkComponent = (hide) => {
  const tawkComponent = document.getElementsByTagName("iframe")[0];
  if (tawkComponent) {
    tawkComponent.style.display = hide ? "none" : "block";
  }
};

export const setCookie = (name, value, expiration) => {
  var expires = "";
  if (expiration) {
    var date = new Date();
    date.setTime(date.getTime() + expiration * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name) => {
  const value = document.cookie;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const findCountry = (val) => {
  if (val) {
    for (const key in isoCountries) {
      if (isoCountries[key] === val) {
        return key.toLocaleLowerCase();
      }
    }
  } else {
    return "us";
  }
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const customSort = (array, order, orderBy, key) => {
  const comparator = getComparator(order, orderBy);

  // Separate parents and children
  const parents = array.filter((item) => item.parentRentalId === null);
  const children = array.filter((item) => item.parentRentalId !== null);

  // Sorts only parents
  const stabilizedParents = parents.map((el, index) => [el, index]);
  stabilizedParents.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const orderedParents = stabilizedParents.map((el) => el[0]);

  // Sorts only children
  const stabilizedChildren = children.map((el, index) => [el, index]);
  stabilizedChildren.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const orderedChildren = stabilizedChildren.map((el) => el[0]);

  // Attach the children to their parents
  const orderedData = [];
  orderedParents.forEach((parent) => {
    orderedData.push(parent); // Add parent
    orderedChildren.forEach((child) => {
      if (child.parentRentalId === parent[`${key}`]) {
        orderedData.push(child); // Add the child right after the parent
      }
    });
  });

  return orderedData;
};

export const getLocalTimestamp = (isoTimestamp, gmt) => {
  // convert the UTC time to local time 
  const date = new Date(isoTimestamp);
  if (isNaN(date.getTime())) {
    throw new Error(`Invalid ISO timestamp specified: ${isoTimestamp}`);
  }

  // Define the locale and options 
  const options = {
      timeZone: gmt,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Use 24-hour format
  };

  // Convert the date to the specified timezone and format it
  let localDateString;
  // Convert the date to the specified timezone and format it
  try {
    localDateString = new Intl.DateTimeFormat('en-US', options).format(date);
  } catch (error) {
    throw new Error(`Invalid time zone specified: ${gmt}`);
  }

  // Replace commas and rearrange to a more typical ISO-like format without T
  return localDateString.replace(/, /g, ' ').replace(/(\d+)\/(\d+)\/(\d{4}) (\d+:\d+:\d+)/, '$3-$1-$2 $4');
}

import React, { useState, useEffect } from "react";
import {withStyles, makeStyles, lighten, MuiThemeProvider, createTheme} from "@material-ui/core/styles";
import clsx from "clsx";

import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import FilterListIcon from "@material-ui/icons/FilterList";
import styles from "./table.module.css";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F0F0F0',
    color: '#555',
    fontWeight: 550,
    lineHeight: '1.5rem',
  },
  root: {
    fontFamily: `'Montserrat','roboto'`,
    padding: '1em 0.4em',
    margin: 0,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
    fontWeight: "600",
  },
  subtitle: {
    flex: "1 1 100%",
  },
  palette: {
    primary: {
      // works
      main: "#439a86",
      contrastText: "#fff",
    },
  },
}));

const myTheme=createTheme({
  palette: {
    primary: { // works
      main: '#439a86',
      contrastText: '#fff',
    },
  },
});

function TableData(props) {
  const classes = useStyles();
  const handleDelete = props.handleDelete;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(props.startKey);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected([]);
  }, [props.rows]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.subtitle}
            color="inherit"
            variant="inherit"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="inherit"
            id="tableTitle"
            component="div"
          >
            {props.heading}
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon onClick={() => handleDelete(selected)} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon style={{ display: "none" }} />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  };

  return (
      <MuiThemeProvider theme={myTheme}>
      {props.heading && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          heading={props.heading}
        />
      )}
      <TableContainer component={Paper}>
        <Table aria-label="caption table" {...props.tableProps}>
          <TableHead>
            <TableRow>
              {props.heading && (
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < props.rows.length
                    }
                    checked={
                      props.rows.length > 0 &&
                      selected.length === props.rows.length
                    }
                    onChange={handleSelectAllClick}
                    color="primary"
                    inputProps={{ "aria-label": "select all desserts" }}
                    style={myTheme.palette.primary}
                  />
                </StyledTableCell>
              )}
              {props.cols.map((col, index) => (
                <StyledTableCell
                  align="left"
                  key={index}
                  sortDirection={orderBy === col.key ? order : false}
                >
                  {col.key !== "" ? (
                    <span className={styles.wrapFieldTh}>
                      <TableSortLabel
                        active={orderBy === col.key}
                        direction={orderBy === col.key ? order : "asc"}
                        onClick={() => handleRequestSort(col.key)}
                      >
                        {col.lable}
                        {orderBy === col.key ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </span>
                  ) : (
                    <span
                      className={`${styles.wrapFieldTh} ${props.columnStyle}`}
                    >
                      {col.lable}
                    </span>
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows &&
              props.rows.length !== 0 &&
              stableSort(props.rows, getComparator(order, orderBy)).map(
                (row) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      key={row.id}
                      aria-checked={isItemSelected}
                      style={myTheme.palette.primary}
                    >
                      {props.heading && (
                        <StyledTableCell>
                          <Checkbox
                            checked={isItemSelected}
                            color="primary"
                            inputProps={{ "aria-labelledby": row.id }}
                            size={"small"}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </StyledTableCell>
                      )}
                      {props.cols.map((field) => (
                        <StyledTableCell align="left" key={field.key}>
                          <span
                            className={`${styles.wrapField} ${field.lable === "Actions"? props.actionColumnStyle : props.columnStyle}`}
                          >
                            {field.lable === "Actions" ? (
                              <div className={styles.iconContainer}>
                                {props.onEdit && (
                                  <span className={styles.editIconBox}>
                                    <i
                                      className={`${styles.editIcon} fa fa-pencil-square-o`}
                                      onClick={() => props.onEdit(row)}
                                    />
                                  </span>
                                )}
                                {props.onDelete ? (
                                  props.confirmDeleteDialog ? (
                                    <span className={styles.icon}>
                                      <i
                                        className="fa fa-trash"
                                        onClick={(e) =>
                                          props.handleConfirmDialog(e, row)
                                        }
                                        aria-describedby="popover-1"
                                        style={{
                                          fontSize: "17px",
                                          marginLeft: "8px",
                                        }}
                                      />
                                      {props.confirmDeleteDialog()}
                                    </span>
                                  ) : (
                                    <span className={styles.icon}>
                                      <i
                                        className="fa fa-trash"
                                        onClick={() => props.onDelete(row)}
                                        style={{
                                          fontSize: "17px",
                                          marginLeft: "8px",
                                        }}
                                      />
                                    </span>
                                  )
                                ) : null}
                                {props.onDownload && (
                                  <span className={styles.icon}>
                                    <i
                                      className="fas fa-download"
                                      onClick={() => props.onDownload(row)}
                                    />
                                  </span>
                                )}
                                {props.onSendGuest && (
                                  <span className={styles.icon}>
                                    <i
                                      className="fas fa-mail-bulk"
                                      onClick={() => props.onSendGuest(row)}
                                    />
                                  </span>
                                )}
                              </div>
                            ) : (
                              <>
                                {field.key === "color" ? (
                                  <div
                                    style={{ backgroundColor: row[field.key] }}
                                    className={styles.color_box}
                                  />
                                ) : (
                                  <span>
                                    {field.isDate
                                      ? `${moment(row[field.key]).format(
                                          props.dateFormat
                                        )}`
                                      : `${
                                          row[field.key] || row[field.key] === 0 ? row[field.key] === 0 ? "0" : row[field.key] : "-"
                                        }`}
                                  </span>
                                )}
                              </>
                            )}
                          </span>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  );
                }
              )}
          </TableBody>
          {props.rows && props.rows.length === 0 && (
            <caption>No data found.</caption>
          )}
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  );
}

export default TableData;
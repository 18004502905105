import React, { useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

import { Form, Col } from 'react-bootstrap';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";

import MultiSelect from "../../../Rates/components/BulkDefaultChanges/MultiSelect";
import ModalButton from "../../../../../../components/ModalButton";
import rentalType from "../../../../../../config/data/rentalType";
import styles from "../../rentals.module.css";

import { toast } from "react-toastify";

toast.configure();


function RentalForm(props) {
    const [propertyInfo, setPropertyInfo] = useState(null);
    const [parentRentalList, setParentRentalList] = useState([]);
    const [selectedRentals, setSelectedRentals] = useState([]);
    
    const properties = useSelector(({ rentals }) => rentals && rentals.properties);
    const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
    const groups = useSelector(({ rentals }) => rentals && rentals.groups);

    const {
        modalType,
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit
    } = props;

    
    useEffect(() => {

        const sameProperty = hasTheSameProperty();
        const propertyId = Number(values.propertyId ? values.propertyId : sameProperty?.propertyId);        
        setParentRentalList(findParentRental(rentals, propertyId));

        validatePropertyField();
        validateParentRentalField();
        
        const property = properties.find(property => property.id === propertyId);
        if (property) {
            const address = `${property?.street}, ${property.postalCode} ${property.city}`;
            setPropertyInfo({
                CheckIn: property?.checkinTime || "N/A",
                CheckOut: property?.checkoutTime || "N/A",
                Currency: property?.currency || "N/A",
                License: property?.licenseNumber || "N/A",
                Address: (property?.street && property?.postalCode && property?.city && address) || "N/A",
                Country: property?.country || "N/A",
            });
            return
        };
        setPropertyInfo(null);

    }, [values.id, values.propertyId]);


    const textWarning = {
        groupId: "Selected child rentals' group will be defined by the parent rental’s group if you don't select a group.",
        parentRentalId: 'All selected rentals must belong to the same property to have a parent rental or you can update the properties of all selected rentals by filling in the "Select property" field.',
        maxOccup: 'Maximum occupancy chosen. Now fill in the field "Maximum number of children" with the maximum number of occupants that can be children, please.',
        maxChild: 'Maximum children chosen. Now fill in the field "Maximum number of total occupants" with the maximum number of occupants including children and adults, please.',
    };
    
    const showWarning = (show, message) => {
        if (show) {
            toast.warn(message);
        };
    };

    const getOptions = (array, placeholder=false) => {
        const options = placeholder ? [{ value: '', label: 'Select' }] : [];
        array.forEach(item => options.push(item?.id ? { value: item.id, label: (item.name || item.groupName) } : item));
        return options;
    };

    const findParentRental = (rentals, propertyId) => {
        const rentalInput = (values.id ? values.id  : []);
        const options = ((modalType !== 'editMultiple' && [{ value: '', label: 'None' }]) || [{ value: '', label: 'Select' }, { value: '0', label: 'None' }]);
        
        // can't add a parent to a rental that has children
        const findChildren = rentals.find(rental => rentalInput.includes(rental.parentRentalId));
        if (!findChildren) {
            rentals.forEach(rental => {
                if ( // has the same property
                    rental.propertyId === propertyId
                     // it can't has a parent
                    && rental.parentRentalId === null
                     // it can't be own parent
                    && !rentalInput.includes(rental.id)
                ) {
                    options.push({ value: rental.id, label: rental.name });
                };
            });
        };

        return options;
    };

    const hasTheSameProperty = () => {
        const selectedRentalsObjects = values.id ? rentals.filter(rental => values.id.includes(rental.id)) : [];
        
        if (selectedRentalsObjects.length > 0) {
            const rentalOne = selectedRentalsObjects[0];
            const sameProperty = selectedRentalsObjects.every(rental => rental.propertyId === rentalOne.propertyId);
            if (sameProperty) {
                return rentalOne;
            };
        };

        return 0;
    };
    
    const hasTheSameParentRental = () => {
        const selectedRentalsObjects = values.id ? rentals.filter(rental => values.id.includes(rental.id)) : [];

        if (selectedRentalsObjects.length > 0) {
            const rentalOne = selectedRentalsObjects[0];
            const sameParentRental = selectedRentalsObjects.every((rental) => rental.parentRentalId === rentalOne.parentRentalId);
            if (sameParentRental) {
                return rentalOne;
            };
        };

        return 0;
    };
    
    const hasTheSameGroup = () => {
        const selectedRentalsObjects = values.id ? rentals.filter(rental => values.id.includes(rental.id)) : [];
        
        if (selectedRentalsObjects.length > 0) {
            const rentalOne = selectedRentalsObjects[0];
            const sameGroup = selectedRentalsObjects.every(rental => rental.groupId === rentalOne.groupId);
            if (sameGroup) {
                return rentalOne;
            };
        };

        return 0;
    };

    const maxGuestsWarning = () => {
        if (values.maxOccup > 10) {
            toast.warn(`Maximum number of guests ${values.maxOccup}. Are you sure ${values.maxOccup} persons can stay in this single rental util?`)
        };
    };

    const validatePropertyField = () => {
        const sameParentRental = hasTheSameParentRental();
        const sameProperty = (values.propertyId === String(sameParentRental?.propertyId));

        if (sameProperty) {
            setFieldValue('newParentRentalId', sameParentRental.parentRentalId);
            setFieldValue('validateParentRentalId', true);
            
            validateGroupField(sameParentRental.parentRentalId);
            return true;
        } else {
            setFieldValue('newParentRentalId', '');
            setFieldValue('validateParentRentalId', false);
            return false;
        };

    };
    
    const validateParentRentalField = () => {

        if (!values?.propertyId) {
            const sameProperty = hasTheSameProperty();
            if (sameProperty) {
                setFieldValue('newPropertyId', sameProperty.propertyId);
                setFieldValue('validatePropertyId', true);
                return true;
            } else {
                setFieldValue('newPropertyId', '');
                setFieldValue('validatePropertyId', false);
                return false;
            };
        };

        return true;

    };
    
    const validateGroupField = (parentRentalId) => {

        if (!values?.groupId) {
            const parentRentalObject = rentals.find(rental => rental.id === Number(parentRentalId));
            const sameGroup = hasTheSameGroup();

            if (parentRentalObject) {
                setFieldValue('newGroupId', parentRentalObject.groupId);
                setFieldValue('validateGroupId', true);

                if (sameGroup.groupId === parentRentalObject.groupId) {
                    return true;
                } else {
                    return false;
                };
                
            } else {
                setFieldValue('newGroupId', '');
                setFieldValue('validateGroupId', false);
                return true;
            };
            
        };
        
        return true;

    };


    return (
        <Form onSubmit={handleSubmit}>
            { modalType === 'editMultiple' ?
                <>
                    <p style={{fontSize:'15px'}}>
                    Select the fields you would like to change by clicking on the checkbox to the right.
                    </p>
                    <Form.Row className="py-1 align-items-center">
                        <Form.Label className="col-sm-4 col-form-label">Select Rentals*:</Form.Label>
                        <Col style={{ marginRight: '35px' }}>
                            <MultiSelect
                            options={getOptions(rentals)}
                            value={selectedRentals}
                            name="id"
                            onChange={(selectedOptions) => {
                                const selectedIds = selectedOptions.map(option => option.value);
                                setFieldValue('id', selectedIds);
                                setSelectedRentals(selectedOptions);
                                setFieldValue('parentRentalId', '');
                                setFieldValue('checkParentRentalId', false);
                            }}
                            onBlur={handleBlur}
                            fieldHasError={errors.id}
                            placeholder={"---Select Rental---"}
                            />
                            <div className={`${styles.errorMessage}`} >{errors.id}</div>
                        </Col>
                    </Form.Row>
                </>
            :
                <>
                    { modalType === 'edit' ? <></> :
                    <section>
                    <Form.Row className="py-1 align-items-center">
                        <Form.Label className="col-sm-4 col-form-label">
                            Number of <span className="d-none d-sm-inline"><br /></span>rentals to add:
                        </Form.Label>
                        <Col>
                            <div className="form-check form-check-inline">
                            <Form.Check
                                type="radio"
                                name="numberOfUnits"
                                label="one"
                                value="1"
                                checked={values.numberOfUnits === "1"}
                                onChange={handleChange}
                                className={values.numberOfUnits === "1" ? "radio-checked" : ""}
                            />
                            </div>
                            <div className="form-check form-check-inline">
                            <Form.Check
                                type="radio"
                                name="numberOfUnits"
                                label="multiple"
                                value={values.numberOfUnits !== "1" ? values.numberOfUnits : "2"}
                                checked={values.numberOfUnits !== "1"}
                                onChange={handleChange}
                                className={values.numberOfUnits !== "1" ? "radio-checked" : ""}
                            />
                            </div>
                        </Col>
                    </Form.Row>
                    { values.numberOfUnits === "1" ? <></> :
                        <Form.Row className="py-1 align-items-center">
                            <Form.Label className="col-sm-4 col-form-label">Number of rentals:</Form.Label>
                            <Col>
                                <div className="d-flex align-items-center">
                                    <Form.Control
                                        as="select"
                                        type="text"
                                        name="numberOfUnits"
                                        value={values.numberOfUnits}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.numberOfUnits}
                                        style={{ fontSize: 'inherit' }}
                                        >
                                        {[...Array(24).keys()].map( item => {
                                            const option = item+2;
                                            
                                            return (
                                                <option key={option} value={option} selected={values.numberOfUnits === option}>
                                                    {option}
                                                </option>
                                            );
                                        })}
                                    </Form.Control>
                                </div>
                            </Col>
                        </Form.Row>
                    }
                    <hr />
                    </section>
                    }
                    <Form.Row className="py-1 align-items-center">
                        <Form.Label className="col-sm-4 col-form-label">Rental name{`${modalType === 'editMultiple'?'':'*'}`}:</Form.Label>
                        <Col>
                            <div className="d-flex align-items-center">
                                <Form.Control
                                    autoComplete='off'
                                    type="text"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.name}
                                    disabled={!values.checkName && modalType === 'editMultiple'}
                                    style={{ fontSize: 'inherit' }}
                                />
                                { modalType !== 'editMultiple' ? <></> :
                                    <div className="ml-3">
                                        <Form.Check
                                            type="checkbox"
                                            id="checkName"
                                            name="checkName"
                                            checked={values.checkName}
                                            onChange={() => {
                                                setFieldValue('name', (values.name != undefined ? values.name : ''));
                                                setFieldValue('checkName', !values.checkName);
                                                if (modalType === 'editMultiple' && values.checkName == true) {
                                                    setFieldValue('name', '');
                                                }
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            <div className={`${styles.errorMessage}`} >{errors.name}</div>
                        </Col>
                    </Form.Row>
                </>
            }
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">
                    Select property{`${modalType === 'editMultiple'?'':'*'}`}:
                </Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            as="select"
                            type="text"
                            name="propertyId"
                            value={values.propertyId}
                            onChange={(e) => {
                                handleChange(e);
                                setFieldValue('parentRentalId', '');
                                setFieldValue('checkParentRentalId', false);
                            }}
                            onBlur={handleBlur}
                            isInvalid={errors.propertyId}
                            disabled={!values.checkPropertyId && modalType === 'editMultiple'}
                            style={{ fontSize: 'inherit' }}
                            >
                            { getOptions(properties, (modalType === 'editMultiple')).map( option => (
                                    <option key={option.value} value={option.value} selected={values.propertyId === option.value}>
                                        {option.label}
                                    </option>
                            ))}
                        </Form.Control>
                        { modalType !== 'editMultiple' ? <></> :
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkPropertyId"
                                    name="checkPropertyId"
                                    checked={values.checkPropertyId}
                                    onChange={() => {
                                        setFieldValue('propertyId', (values.propertyId != undefined ? values.propertyId : ''));
                                        setFieldValue('checkPropertyId', !values.checkPropertyId);
                                        if (modalType === 'editMultiple' && values.checkPropertyId == true) {
                                            setFieldValue('propertyId', '');
                                            setFieldValue('parentRentalId', '');
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className={`${styles.errorMessage}`} >{errors.propertyId}</div>
                </Col>
            </Form.Row>
            { propertyInfo && values.propertyId && (
                <Form.Row>
                    <Form.Label className="m-0 p-0 col-sm-4 col-form-label"></Form.Label>
                    <Col className='ml-2 p-0'>
                        <p style={{color:'#888888'}}>
                            Check-in: {propertyInfo?.CheckIn}<br />
                            Check-out: {propertyInfo?.CheckOut}<br />
                            Currency: {propertyInfo?.Currency}<br />
                            License: {propertyInfo?.License}<br />
                            Address: {propertyInfo?.Address}<br />
                            Country: {propertyInfo?.Country}
                        </p>
                    </Col>
                </Form.Row>
            )}
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Additional address information:</Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            autoComplete='off'
                            type="text"
                            name="addressInformation"
                            value={values.addressInformation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.addressInformation}
                            disabled={!values.checkAddressInformation && modalType === 'editMultiple'}
                            style={{ fontSize: 'inherit' }}
                        />
                        { modalType !== 'editMultiple' ? <></> :
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkAddressInformation"
                                    name="checkAddressInformation"
                                    checked={values.checkAddressInformation}
                                    onChange={() => {
                                        setFieldValue('addressInformation', (values.addressInformation != undefined ? values.addressInformation : ''));
                                        setFieldValue('checkAddressInformation', !values.checkAddressInformation);
                                        if (modalType === 'editMultiple' && values.checkAddressInformation == true) {
                                            setFieldValue('addressInformation', '');
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className={`${styles.errorMessage}`} >{errors.addressInformation}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Select group:</Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            as="select"
                            type="text"
                            name="groupId"
                            value={values.groupId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.groupId}
                            disabled={!values.checkGroupId && modalType === 'editMultiple'}
                            style={{ fontSize: 'inherit' }}
                            >
                            { getOptions(groups, (modalType === 'editMultiple')).map( option => (
                                    <option key={option.value} value={option.value} selected={values.groupId === option.value}>
                                        {option.label === 'Default' ? 'None' : option.label}
                                    </option>
                            ))}
                        </Form.Control>
                        { modalType !== 'editMultiple' ? <></> :
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkGroupId"
                                    name="checkGroupId"
                                    checked={values.checkGroupId}
                                    onChange={() => {
                                        setFieldValue('checkGroupId', !values.checkGroupId);
                                        setFieldValue('groupId', (values.groupId != undefined ? values.groupId : ''))
                                        if (modalType === 'editMultiple' && values.checkGroupId == true) {
                                            setFieldValue('groupId', '');
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className={`${styles.errorMessage}`} >{errors.groupId}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Rental type:</Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            as="select"
                            type="text"
                            name="rentalType"
                            value={values.rentalType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.rentalType}
                            disabled={!values.checkRentalType && modalType === 'editMultiple'}
                            style={{ fontSize: 'inherit' }}
                            >
                            { getOptions(rentalType, (modalType === 'editMultiple')).map( option => (
                                    <option key={option.value} value={option.value} selected={values.rentalType === option.value}>
                                        {option.label}
                                    </option>
                            ))}
                        </Form.Control>
                        { modalType !== 'editMultiple' ? <></> :
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkRentalType"
                                    name="checkRentalType"
                                    checked={values.checkRentalType}
                                    onChange={() => {
                                        setFieldValue('checkRentalType', !values.checkRentalType);
                                        setFieldValue('rentalType', (values.rentalType != undefined ? values.rentalType : ''))
                                        if (modalType === 'editMultiple' && values.checkRentalType == true) {
                                            setFieldValue('rentalType', '');
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className={`${styles.errorMessage}`} >{errors.rentalType}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">
                    Maximum number <span className="d-none d-sm-inline"><br /></span>of total occupants{`${modalType === 'editMultiple'?'':'*'}`}:
                </Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            type="number"
                            name="maxOccup"
                            value={values.maxOccup}
                            onChange={handleChange}
                            onBlur={(e) => {
                                handleBlur(e);
                                maxGuestsWarning();
                                showWarning((values.checkMaxOccup && !values.checkMaxChild), textWarning.maxOccup);
                            }}
                            isInvalid={errors.maxOccup}
                            disabled={!values.checkMaxOccup && modalType === 'editMultiple'}
                            style={{ fontSize: 'inherit' }}
                            min={0}
                        />
                        { modalType !== 'editMultiple' ? <></> :
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkMaxOccup"
                                    name="checkMaxOccup"
                                    checked={values.checkMaxOccup}
                                    onChange={() => {
                                        setFieldValue('checkMaxOccup', !values.checkMaxOccup);
                                        setFieldValue('maxOccup', (values.maxOccup != undefined ? values.maxOccup : ''))
                                        if (modalType === 'editMultiple' && values.checkMaxOccup == true) {
                                            setFieldValue('maxOccup', '');
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className={`${styles.errorMessage}`} >{errors.maxOccup}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">
                    Maximum number <span className="d-none d-sm-inline"><br /></span>of children{`${modalType === 'editMultiple'?'':'*'}`}:
                    <InfoOutlinedIcon
                        fontSize="small"
                        color="inherit"
                        data-tip
                        data-for="maxChild"
                        className={styles.toolTipIcon}
                    />
                    <ReactTooltip place="bottom" type="dark" id="maxChild" effect="solid" className={styles.toolTip}>
                    <span>
                        Maximum number of children can be a number up to the maximum number of occupants minus 1.
                        This number influences the children number in the Booking module.
                    </span>
                    </ReactTooltip>
                </Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            autoComplete='off'
                            type="number"
                            name="maxChild"
                            value={values.maxChild}
                            onChange={handleChange}
                            onBlur={(e) => {
                                handleBlur(e);
                                showWarning((values.checkMaxChild && !values.checkMaxOccup), textWarning.maxChild);
                            }}
                            isInvalid={errors.maxChild}
                            disabled={!values.checkMaxChild && modalType === 'editMultiple'}
                            style={{ fontSize: 'inherit' }}
                            min={0}
                        />
                        { modalType !== 'editMultiple' ? <></> :
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkMaxChild"
                                    name="checkMaxChild"
                                    checked={values.checkMaxChild}
                                    onChange={() => {
                                        setFieldValue('checkMaxChild', !values.checkMaxChild);
                                        setFieldValue('maxChild', (values.maxChild != undefined ? values.maxChild : ''))
                                        if (modalType === 'editMultiple' && values.checkMaxChild == true) {
                                            setFieldValue('maxChild', '');
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className={`${styles.errorMessage}`} >{errors.maxChild}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">
                    Parent rental:
                    <InfoOutlinedIcon
                        fontSize="small"
                        color="inherit"
                        data-tip
                        data-for="parentRentalId"
                        className={styles.toolTipIcon}
                    />
                    <ReactTooltip place="bottom" type="dark" id="parentRentalId" effect="solid" className={styles.toolTip}>
                    <span>
                        If you rent your unit either as an entire place or each bedroom independently, you can set up rentals for the entire place and each bedroom.
                        Link the bedrooms to the entire place by choosing the entire place in the rental list.
                        They should belong to the same property. Child listings cannot be a parent of other rentals.
                        Establishing this connection will block the availability on the parent every time there is a reservation on the child, and vice-versa.
                    </span>
                    </ReactTooltip>
                </Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            as="select"
                            type="text"
                            name="parentRentalId"
                            value={values.parentRentalId}
                            onChange={(e) => {
                                handleChange(e);
                                showWarning(!validateGroupField(e.target.value) && modalType === 'editMultiple', textWarning.groupId);
                            }}
                            onBlur={handleBlur}
                            isInvalid={errors.parentRentalId}
                            disabled={!values.checkParentRentalId && modalType === 'editMultiple'}
                            style={{ fontSize: 'inherit' }}
                            >
                            { parentRentalList.map((option, index) => (
                                    <option key={index} value={option.value} selected={values.parentRentalId === option.value}>
                                        {option.label}
                                    </option>
                            ))}
                        </Form.Control>
                        { modalType !== 'editMultiple' ? <></> :
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkParentRentalId"
                                    name="checkParentRentalId"
                                    checked={values.checkParentRentalId}
                                    onChange={() => {
                                        setFieldValue('checkParentRentalId', !values.checkParentRentalId);
                                        setFieldValue('parentRentalId', (values.parentRentalId != undefined ? values.parentRentalId : ''))
                                        if (modalType === 'editMultiple' && values.checkParentRentalId == true) {
                                            setFieldValue('parentRentalId', '');
                                        } else {
                                            showWarning(!validateParentRentalField(), textWarning.parentRentalId);
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className={`${styles.errorMessage}`} >{errors.parentRentalId}</div>
                </Col>
            </Form.Row>
            <hr />
            <Form.Row className="d-none">
                {/* bug fix - for credit card fields*/}
                <Form.Control
                    type="text"
                    name="bugFix"
                    value={''}
                />
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Wi-Fi name:</Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            type="text"
                            name="wifiName"
                            value={values.wifiName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.wifiName}
                            disabled={!values.checkWifiName && modalType === 'editMultiple'}
                            style={{ fontSize: 'inherit' }}
                        />
                        { modalType !== 'editMultiple' ? <></> :
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkWifiName"
                                    name="checkWifiName"
                                    checked={values.checkWifiName}
                                    onChange={() => {
                                        setFieldValue('checkWifiName', !values.checkWifiName);
                                        setFieldValue('wifiName', (values.wifiName != undefined ? values.wifiName : ''))
                                        if (modalType === 'editMultiple' && values.checkWifiName == true) {
                                            setFieldValue('wifiName', '');
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className={`${styles.errorMessage}`} >{errors.wifiName}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Wi-Fi password:</Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            autoComplete='off'
                            type="text"
                            name="wifiPassword"
                            value={values.wifiPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.wifiPassword}
                            disabled={!values.checkWifiPassword && modalType === 'editMultiple'}
                            style={{ fontSize: 'inherit' }}
                        />
                        { modalType !== 'editMultiple' ? <></> :
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkWifiPassword"
                                    name="checkWifiPassword"
                                    checked={values.checkWifiPassword}
                                    onChange={() => {
                                        setFieldValue('checkWifiPassword', !values.checkWifiPassword);
                                        setFieldValue('wifiPassword', (values.wifiPassword != undefined ? values.wifiPassword : ''))
                                        if (modalType === 'editMultiple' && values.checkWifiPassword == true) {
                                            setFieldValue('wifiPassword', '');
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className={`${styles.errorMessage}`} >{errors.wifiPassword}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Door/security code:</Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            autoComplete='off'
                            type="text"
                            name="securityCode"
                            value={values.securityCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.securityCode}
                            disabled={!values.checkSecurityCode && modalType === 'editMultiple'}
                            style={{ fontSize: 'inherit' }}
                        />
                        { modalType !== 'editMultiple' ? <></> :
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkSecurityCode"
                                    name="checkSecurityCode"
                                    checked={values.checkSecurityCode}
                                    onChange={() => {
                                        setFieldValue('checkSecurityCode', !values.checkSecurityCode);
                                        setFieldValue('securityCode', (values.securityCode != undefined ? values.securityCode : ''))
                                        if (modalType === 'editMultiple' && values.checkSecurityCode == true) {
                                            setFieldValue('securityCode', '');
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className={`${styles.errorMessage}`} >{errors.securityCode}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Notes:</Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            autoComplete='off'
                            as="textarea"
                            rows={2}
                            type="text"
                            name="notes"
                            value={values.notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.notes}
                            disabled={!values.checkNotes && modalType === 'editMultiple'}
                            style={{ fontSize: 'inherit' }}
                        />
                        { modalType !== 'editMultiple' ? <></> :
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkNotes"
                                    name="checkNotes"
                                    checked={values.checkNotes}
                                    onChange={() => {
                                        setFieldValue('checkNotes', !values.checkNotes);
                                        setFieldValue('notes', (values.notes != undefined ? values.notes : ''))
                                        if (modalType === 'editMultiple' && values.checkNotes == true) {
                                            setFieldValue('notes', '');
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className={`${styles.errorMessage}`} >{errors.notes}</div>
                </Col>
            </Form.Row>
            <br/>
            <ModalButton btnText={"Save changes"}/>
        </Form>
    );

}

export default RentalForm;

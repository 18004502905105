// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".table_wrapField__HfmMV {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  width: 115px;\n  display: block;\n  font-size: 12px;\n}\n.table_wrapFieldTh__38btB{\n  width: 115px;\n  display: block;\n  font-size: 14px;\n}\n.table_icon__3u-R0 {\n  cursor: pointer;\n  padding: 0 !important;\n  margin-left: 8px !important;\n}\n.table_editIconBox__3_t87 {\n  cursor: pointer;\n  padding: 0 4px !important;\n  height: 22px !important;\n  width: 22px !important;\n}\n.table_editIcon__6Fvmx {\n  font-size: 18px !important;\n  margin-top: 3px !important;\n}\n.table_iconContainer__RLHsY {\n  display: flex;\n  align-items: center;\n}\n\ncaption {\n  font-family: 'Montserrat', 'Roboto' !important;\n  text-align: center !important;\n}\n\n.table_color_box__2UWYY {\n  width: 20px;\n  height: 20px;\n}\n.table_popperBox__2LD-N{\n  z-index: 10000;\n}\n\n@media screen and (max-width: 767px) {\n  .table_wrapField__HfmMV, .table_wrapFieldTh__38btB {\n    width: 70px;\n    font-size: 0.75rem !important;\n  }\n  .table_wrapFieldTh__38btB{\n    white-space: normal;\n  }\n  caption {\n    font-size: inherit !important;\n  }\n}\n\n@media screen and (max-width: 420px) {\n  .table_wrapField__HfmMV , .table_wrapFieldTh__38btB {\n    width: 65px;\n    font-size: 0.75rem !important;\n  }\n}", ""]);
// Exports
exports.locals = {
	"wrapField": "table_wrapField__HfmMV",
	"wrapFieldTh": "table_wrapFieldTh__38btB",
	"icon": "table_icon__3u-R0",
	"editIconBox": "table_editIconBox__3_t87",
	"editIcon": "table_editIcon__6Fvmx",
	"iconContainer": "table_iconContainer__RLHsY",
	"color_box": "table_color_box__2UWYY",
	"popperBox": "table_popperBox__2LD-N"
};
module.exports = exports;

import React from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import styles from './blockdate.module.css';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import CustomDate from '../../../../../../components/CustomDate';

function AddBlockDate({handleSave, initValues, handleEdit, isEdit}) {
  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
  const dateDisplay = useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.dateDisplay);
  const {numberOf, allowBookingFor} = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
  //=============================================
  // SCHEMA REQUIREMENT FOR FORM VALIDATION
  //=============================================
  const BlockDateSchema = yup.object().shape({
    rental: yup.string().required('Rental is required!'),
    eventName: yup.string().max(50, 'Must be less than 50 characters'),
    arrival: yup.date().required('Start date is required'),
    departure: yup.date().required('End date is required!'),
  });

  const initialValues = {
    rental:isEdit? initValues.rentalId : '',
    eventName: isEdit ? initValues.title : '',
    arrival: isEdit ? new Date(moment(initValues.arrive)) : '',
    departure: isEdit ? new Date(moment(initValues.depart)) : '',
  }

  //=============================================
  // Handle Save
  //=============================================

  function handleSubmit(data) {
    const payload = {
      id: (isEdit)? initValues.id: undefined,
      rentalId: Number(data.rental),
      price:0,
      noOfAdults:0,
      arrive: moment(data.arrival).utc(true).format('YYYY-MM-DD'),
      depart: moment(data.departure).utc(true).format('YYYY-MM-DD'),
      arriveUTC : moment(data.arrival).utc(true).format('YYYY-MM-DD HH:mm:ss'),
      departUTC : moment(data.departure).utc(true).format('YYYY-MM-DD HH:mm:ss'),
      noOfChildren: 0,
      noOfBabies:0,
      source:"Beehaz",
      checkInTime:"15:00",
      checkOutTime:"11:00",
      bookingType: 'blockdates',
      status:"Booked",
      noOfGuests: 0,
      paymentStatus:"Paid",
      title: (data.eventName!=="")?data.eventName:"Blocked",
      nights:moment(data.departure,"YYYY-MM-DD").diff(moment(data.arrival,"YYYY-MM-DD"),'day'),
      color: "#D3D3D3",
      discountName: "Discount",
      discountAmount:0,
      chargeName:"Extra charge",
      chargeAmount:0,
      fees:0,
      tax:0,
      extraGuestFee:0,
      paymentStatus:"Paid",
      miniNotes:"",
      baseRate:0,
      miniNotes:"",
      notes:'',
    }
    // console.log("Payload", payload);
    isEdit ? handleEdit(payload) : handleSave(payload);
  }

  //=============================================

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className={styles.modalTitle}>Add event/block dates</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={BlockDateSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue }) => {
            return (
              <>
                <Form>
                  <div className={styles.form_wrapper}>
                    <div className={styles.form_title}>Select rental: </div>
                    <Field
                      as="select"
                      name="rental"
                      className={errors.rental && touched.rental ? styles.form_entry_err : styles.form_entry}
                    > <option key={""} value={""}>{"---Select Rental---"}</option>
                      {rentals && rentals.map((r) => <option key={r.id} value={r.id} >{r.name}</option>)}
                    </Field>
                    <div className={errors.rental && touched.rental ? styles.form_error : styles.form_error_hidden}>
                      {errors.rental}
                    </div>
                  </div>

                  <div className={styles.form_wrapper}>
                    <div className={styles.form_title}>Name of event: </div>
                    <Field
                      name="eventName"
                      className={styles.form_entry}
                    />
                    <div className={errors.eventName && touched.eventName ? styles.form_error : styles.form_error_hidden}>
                      {errors.eventName}
                    </div>
                  </div>
                  <div className={styles.form_wrapper}>
                    <div className={styles.form_title}>Start date: </div>
                    <CustomDate
                      id={"block_date_arrival"}
                      name={"arrival"}
                      handleChange={(date) => setFieldValue("arrival", date)}
                      value={values.arrival}
                      hasError={errors.arrival}
                      max={values.departure ? values.departure : new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                    ></CustomDate>
                    <div className={errors.arrival && touched.arrival ? styles.form_error : styles.form_error_hidden}>
                      {errors.arrival}
                    </div>
                  </div>

                  <div className={styles.form_wrapper}>
                    <div className={styles.form_title}>End date: </div>
                    <CustomDate
                      id={"block_date_departure"}
                      name={"departure"}
                      handleChange={(date) => setFieldValue("departure", date)}
                      value={values.departure}
                      min={values.arrival}
                      hasError={errors.departure}
                      max={new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                    ></CustomDate>
                    <div
                      className={errors.departure && touched.departure ? styles.form_error : styles.form_error_hidden}
                    >
                      {errors.departure}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className={styles.settings_save} type="submit">
                      Save changes
                    </button>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </Modal.Body>
    </>
  );
}

export default AddBlockDate;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteSendInvoiceNw,
    cancelScheduledInvoice
} from '../../../../general_redux/invoice/actions';

import DeleteConfirm from '../../Components/DeleteConfirm/DeleteConfirm';
import styles from './InvoiceHistoryItem.module.css';

import moment from 'moment';


const InvoiceHistoryItem = (props) => {

    const {
        handleMarkSent,
        data,
        invoice,
        showSentInvoice,
        showScheduledInvoice,
        showInvoiceManuallyMarkedSent
    } = props;
    

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenPopover = (e) => {
        setAnchorEl( anchorEl ? null : e.currentTarget);
    };
    
    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const settings = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
    const timeMarkedSent = useSelector(({ invoice }) => {
        const item = invoice && invoice.invoiceList.find(item => item.id === data.id);
        return item ? item.timeMarkedSent : null;
    });
    
    const dispatch = useDispatch();


    const getFormattedDate = (date, timeDisplay, timeZone) => {
        const originalDate = new Date(date);
        const dateInTimeZone = new Date(originalDate.toLocaleString('en-US', { timeZone }));
    
        const momentDate = moment(dateInTimeZone);
        const formattedDate = momentDate.format('DD MMMM YYYY');
        // TODO: Fix timeDisplay values in backend and frontend
        // const formattedTime = momentDate.format(timeDisplay);
        const formattedTime = momentDate.format('HH:mm');
        
        return [formattedDate, formattedTime];
    };
    
    function cleanHtmlTags(html) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = `<dib>${html}</dib>`.replace(/<br\s*\/?>/g, ' ');
        return tempDiv.textContent || tempDiv.innerText || '';
    };

    const time = (
        showSentInvoice && data.emailSentTime ||
        showScheduledInvoice && data.scheduledTime ||
        showInvoiceManuallyMarkedSent && timeMarkedSent
    );
    
    const [formattedDate, formattedTime] = getFormattedDate(time, settings.timeDisplay, settings.timezone);

    const formattedData = !showInvoiceManuallyMarkedSent ? {
        id: showScheduledInvoice ? data.scheduledEmailId : data.id,
        date: formattedDate,
        time: formattedTime,
        guestEmail: invoice?.recipientDetail?.email,
        sendCopyToUser: data.sendCopyToUser == true ? 'active' : 'not active',
        stripeButton: data.stripeButton == true ? 'enabled' : 'not enabled',
        emailSubject: data.emailSubject,
        text: cleanHtmlTags(data.emailBody),
        downloadLink: invoice?.downloadLink,
    } : {
        id: data.id,
        date: formattedDate,
        time: formattedTime
    };

    const handleDelete = (id) => {

        if (showSentInvoice) {
            dispatch(deleteSendInvoiceNw(id));
        } else if (showScheduledInvoice) {
            dispatch(cancelScheduledInvoice({scheduledEmailId: id}));
        } else {
            handleMarkSent(id);
        }

        handleClosePopover();

    };

    const handleDownload = (downloadLink) => {
        window.open(downloadLink, "_parent");
    };

    return (
        <div className={`${styles.invoiceHistoryItem}`}>
            <DeleteConfirm
                id={"popover-del-confirm-2"}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                onConfirmAct={() => handleDelete(formattedData.id)}
            />
            <div className={`float-right row mr-2 ${styles.invoiceHistoryButtons}`}>
                { !showInvoiceManuallyMarkedSent && (
                    <span onClick={() => handleDownload(formattedData?.downloadLink)} className={styles.icon}>
                        <i className="fas fa-download"/>
                    </span>
                )}
                <span aria-describedby="popover-del-confirm-2" onClick={handleOpenPopover} className={styles.icon}>
                    <i className="fa fa-trash"/>
                </span>
            </div>
            { (showSentInvoice && formattedData) && (
                <>
                    <b>Invoice sent</b>
                    <p className={`${styles.invoiceHistoryItemText}`}>
                        Date sent: {formattedData.date}<br />
                        Time: {formattedData.time}<br />
                        Sent to: {formattedData.guestEmail}<br />
                        Status: success<br />
                        Send me an email copy: {formattedData.sendCopyToUser}<br />
                        Stripe button: {formattedData.stripeButton}<br />
                        Title: {formattedData.emailSubject}<br />
                        Text: {formattedData.text}<br />
                    </p>
                </>
            )}
            { (showScheduledInvoice && formattedData) && (
                <>
                    <b>Invoice scheduled</b>
                    <p className={`${styles.invoiceHistoryItemText}`}>
                        Date scheduled: {formattedData.date}<br />
                        Time: {formattedData.time}<br />
                        Send to: {formattedData.guestEmail}<br />
                        Status: scheduled<br />
                        Send me an email copy: {formattedData.sendCopyToUser}<br />
                        Stripe button: {formattedData.stripeButton}<br />
                        Title: {formattedData.emailSubject}<br />
                        Text: {formattedData.text}<br />
                    </p>
                </>
            )}
            { (showInvoiceManuallyMarkedSent && formattedData) && (
                <p>
                    <b>Invoice manually marked as sent</b><br />
                    Date marked: {formattedData.date}<br />
                    Time: {formattedData.time}<br />
                </p>
            )}
        </div>
    )
}

export default InvoiceHistoryItem;
import {toast} from "react-toastify";
import {
    ADD_NEW_RATE,
    GET_RATE_LIST,
    ADD_FEE,
    DELETE_FEE,
    EDIT_FEE,
    GET_FEE_LIST,
    EDIT_RATES,
    ADD_NEW_TAX,
    DELETE_TAX,
    EDIT_TAX_LIST,
    EDIT_BULK_DEFAULT,
    CLEAR_ACTIONS,
    FETCH_RATE_CALENDAR,
    SET_SPECIAL_RATE,
    CHANGERATE_TIMELINE,
    SET_SPECIAL_RATE_MULTIPLE,
    FETCH_TAXES,
    SET_EXTRA_GUEST_FEE,
    CLEAR_CALENDAR_ACTION,
    ADD_NEW_WEEKLY_FIXED_RATE,
    ADD_NEW_WEEKLY_FIXED_FEE,
    ADD_NEW_WEEKLY_FIXED_TAX,
    ADD_NEW_MONTHLY_FIXED_RATE,
    ADD_NEW_MONTHLY_FIXED_FEE,
    ADD_NEW_MONTHLY_FIXED_TAX,
    SET_RATE_ADJUSTMENT,
    GET_RATE_ADJUSTMENT,
    GET_WEEKLY_FIXED_RATE,
    GET_WEEKLY_FIXED_FEE,
    GET_WEEKLY_FIXED_TAX,
    GET_MONTHLY_FIXED_RATE,
    GET_MONTHLY_FIXED_FEE,
    GET_MONTHLY_FIXED_TAX
  } from './types';
import {actionForState} from "../../common/functions/utils";
toast.configure();

  const INITIAL_STATE = {
    rateCalendar:[],
    changedRatesTimeline:[],
    channelAdjustmentRate: 0,
    rateList: [],
    feesList: [],
    taxList: [],
    weeklyRate: [],
    weeklyFee: [],
    weeklyTax: [],
    monthlyRate: [],
    monthlyFee: [],
    monthlyTax: [],
    rateSettings: [],
      action:{
          loading:false,
          success:undefined
      },
      actionForRate:{
          loading: false,
          success: false,
          error:false
      },
      actionForRateList:{
          loading: false,
          success: false,
          error:false
      },
      actionForEditRate:{
          loading: false,
          success: false,
          error:false
      },
      actionForGetFeeList:{
        loading: false,
        success: false,
        error:false
      },
      actionForAddFee:{
        loading: false,
        success: false,
        error:false
      },
      actionForEditFee:{
        loading: false,
        success: false,
        error:false
      },
      actionForDeleteFee:{
        loading: false,
        success: false,
        error:false
      },
      actionForBulkDefault:{
        loading: false,
        success: false,
        error:false
      },
      actionForRateCal:{
        loading:false,
        success: false,
        error: false
      },
      actionForSpecialRate:{
        loading:false,
        success:false,
        error:false,
      },
      actionForRatesTimeline:{
        loading:false,
        success:false,
        error:false,
      },
      actionForMultipleRateSet:{
        loading:false,
        success:false,
        error:false
      },
      actionForFetchTaxes:{
        loading:false,
        success:false,
        error:false,
      },
      actionForAddTax:{
        loading:false,
        success:false,
        error:false
      },
      actionForEditTax:{
        loading:false,
        success:false,
        error:false
      },
      actionForDeleteTax:{
        loading:false,
        success:false,
        error:false
      },
      actionForExtraGuest:{
        loading:false,
        success:false,
        error:false
      },
      actionForAddWeeklyFixedRate:{
        loading:false,
        success:false,
        error:false
      },
      actionForAddWeeklyFixedFee:{
        loading:false,
        success:false,
        error:false
      },
      actionForAddWeeklyFixedTax:{
        loading:false,
        success:false,
        error:false
      },
      actionForAddMonthlyFixedRate:{
        loading:false,
        success:false,
        error:false
      },
      actionForAddMonthlyFixedFee:{
        loading:false,
        success:false,
        error:false
      },
      actionForAddMonthlyFixedTax:{
        loading:false,
        success:false,
        error:false
      },      
      actionForGetWeeklyFixedRate:{
        loading:false,
        success:false,
        error:false
      },
      actionForGetWeeklyFixedFee:{
        loading:false,
        success:false,
        error:false
      },
      actionForGetWeeklyFixedTax:{
        loading:false,
        success:false,
        error:false
      },
      actionForGetMonthlyFixedRate:{
        loading:false,
        success:false,
        error:false
      },
      actionForGetMonthlyFixedFee:{
        loading:false,
        success:false,
        error:false
      },
      actionForGetMonthlyFixedTax:{
        loading:false,
        success:false,
        error:false
      },
      actionForGetChannelAdjustmentRate:{
        loading: false,
        success: false,
        error: false
      },
      actionForEditChannelAdjustmentRate:{
        loading: false,
        success: false,
        error: false
      },
  };
  
  export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

      case GET_FEE_LIST.pending:
        return {
          ...state,
          actionForGetFeeList: actionForState(state.action, 'pending'), 
        };
      case GET_FEE_LIST.success:
        return {
          ...state,
          actionForGetFeeList: actionForState(state.action, 'success'), 
          feesList: action.payload.fee,
        };
      case GET_FEE_LIST.error:
        toast.error(action.payload.message);
        return {
          ...state,
          actionForGetFeeList: actionForState(state.action, 'error',action.payload.message),
        };

      case ADD_FEE.pending:
        return {
          ...state,
          actionForAddFee: actionForState(state.action, 'pending'), 
        };
      case ADD_FEE.success:
        toast.success("Fee has been added successfully.")
        return {
          ...state,
          feesList: [...new Set(state.feesList.concat(action.payload.data))],
          actionForAddFee: actionForState(state.action, 'success'), 
        };
      case ADD_FEE.error:
        toast.error(action.payload.message)
        return {
          ...state,
          actionForAddFee: actionForState(state.action, 'error',action.payload.message),
        };
        
      case EDIT_FEE.pending:    
        return {    
            ...state,    
            actionForEditFee: actionForState(state.action, 'pending'),    
        };
      case EDIT_FEE.success:
        toast.success("Fee has been successfully edited.")
        const FeeArrayData = state.feesList;
        const updateIndex = FeeArrayData.findIndex(({id}) => id === action.payload.data.id);
        if (updateIndex >= 0) {
          FeeArrayData[updateIndex] = action.payload.data
        } 
        return {    
            ...state,    
            feesList: FeeArrayData,
            actionForEditFee: actionForState(state.action, 'success'),
        };
      case EDIT_FEE.error:
        toast.error(action.payload.message) 
        return {    
            ...state,    
            actionForEditFee: actionForState(state.action, 'error',action.payload.message),    
        };
      case DELETE_FEE.pending:
        return {    
            ...state,    
            actionForDeleteFee: actionForState(state.action, 'pending'),    
        };
      case DELETE_FEE.success:
        toast.success("Fee deleted successfully.")
        return {    
            ...state,    
            feesList: state.feesList.filter(item => item.id !== parseInt(action.payload.id)),
            actionForDeleteFee: actionForState(state.action, 'success'),  
        };
      case DELETE_FEE.error:
        toast.error(action.payload.message) 
        return {    
            ...state,
            actionForDeleteFee: actionForState(state.action, 'error',action.payload.message),   
        };

      case ADD_NEW_RATE.pending:
        return {
            ...state,
            actionForRate: actionForState(state.action, 'pending'),
        }
      case ADD_NEW_RATE.success:
        toast.success("Rate has been added!")
         return {
            ...state,
            actionForRate: actionForState(state.action, 'success'),
            rateSettings:state.rateSettings.concat(action.payload)
         }
      case ADD_NEW_RATE.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForRate: actionForState(state.action, 'error',action.payload.message),
        }
      case GET_RATE_LIST.pending:
        return{
            ...state,
            actionForRateList: actionForState(state.action,'pending')
        }
      case GET_RATE_LIST.success:
        return{
            ...state,
            rateList: action.payload.data,
            rateSettings: action.payload.data,
            actionForRateList: actionForState(state.action,'success')
        }
      case GET_RATE_LIST.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForRateList: actionForState(state.action,'error',action.payload.message)
        }
      case EDIT_RATES.pending:
        return{
            ...state,
            actionForEditRate:actionForState(state.action,'pending')
        }
        case EDIT_RATES.success:
          toast.success("Rate has been updated successfully!")
          if (action.payload.data) {
              const updateIndexRate = state.rateSettings.findIndex((row) => row.id === action.payload.data.id);
              let RateArrayData=[...state.rateSettings];
              RateArrayData[updateIndexRate]=action.payload.data;
              return {
                  ...state,    
                  rateSettings: RateArrayData   
              };
          }

      case EDIT_RATES.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForEditRate:actionForState(state.action,'error',action.payload.message)
        }
      case EDIT_BULK_DEFAULT.pending:
        return{
            ...state,
            actionForBulkDefault:actionForState(state.action,'pending')
        }
      case EDIT_BULK_DEFAULT.success:
        toast.success("Rate has been updated successfully!")
        let RateArrayData2=[...state.rateSettings];
        action.payload.data.forEach((rate)=>{
            const indx=state.rateSettings.findIndex((row) => row.id === rate.id);
            if(indx!==-1){
                RateArrayData2[indx]=rate;
            }
        })
        return{
            ...state,
            rateSettings: RateArrayData2,
            actionForBulkDefault: actionForState(state.action,'success')
        }
      case EDIT_BULK_DEFAULT.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForBulkDefault: actionForState(state.action,'error',action.payload.message)
        }
      case FETCH_TAXES.pending:
        return{
            ...state,
            actionForFetchTaxes: actionForState(state.action,'pending')
        }
      case FETCH_TAXES.success:
        return{
            ...state,
            taxList: action.payload.data,
            actionForFetchTaxes: actionForState(state.action,'success')
        }
      case FETCH_TAXES.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForFetchTaxes: actionForState(state.action,'error',action.payload.message)
        }
      case ADD_NEW_TAX.pending:
        return {
            ...state,
            actionForAddTax:actionForState(state.action,'pending')
        };
      case ADD_NEW_TAX.success:
        toast.success("Tax added successfully!")
        return{
            ...state,
            taxList: state.taxList.concat(action.payload.data),
            actionForAddTax:actionForState(state.action,'success')
        }
      case ADD_NEW_TAX.error:
        toast.error(action.payload.message)
        return{
           ...state,
           actionForAddTax: actionForState(state.action,'error',action.payload.message)
        }
      case DELETE_TAX.pending:
        return{
            ...state,
            actionForDeleteTax: actionForState(state.action,'pending')
        }
      case DELETE_TAX.success:
        toast.success("Tax deleted successfully!")
        return {    
            ...state,    
            taxList: state.taxList.filter(item => item.id !== Number(action.payload.id)),
            actionForDeleteTax:actionForState(state.action,'success')
        };
      case DELETE_TAX.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForDeleteTax: actionForState(state.action,'error',action.payload.message)
        }
      case EDIT_TAX_LIST.pending:
        return{
            ...state,
            actionForEditTax: actionForState(state.action,'pending')
        }
      case EDIT_TAX_LIST.success:
        toast.success("Tax edited successfully!")
        const TaxArrayData = state.taxList;
        const updateIndexTax = TaxArrayData.findIndex(({id}) => id === action.payload.data.id);
        if (updateIndexTax >= 0) {
            TaxArrayData[updateIndexTax] = action.payload.data
        } 
        return {    
            ...state,
            actionForEditTax: actionForState(state.action,'success'),
            taxList: TaxArrayData   
        };
      case EDIT_TAX_LIST.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForEditTax: actionForState(state.action,'error')
        }
      case CLEAR_ACTIONS:
        return{
            ...state,
            actionForGetFeeList:INITIAL_STATE.actionForGetFeeList,
            actionForAddFee:INITIAL_STATE.actionForAddFee,
            actionForEditFee:INITIAL_STATE.actionForEditFee,
            actionForDeleteFee:INITIAL_STATE.actionForDeleteFee,
        }
      case CLEAR_CALENDAR_ACTION:
        return{
            ...state,
            actionForMultipleRateSet:INITIAL_STATE.actionForMultipleRateSet,
            actionForRateCal:INITIAL_STATE.actionForRateCal,
            actionForSpecialRate:INITIAL_STATE.actionForSpecialRate,
            actionForRatesTimeline:INITIAL_STATE.actionForRatesTimeline
        }
      case FETCH_RATE_CALENDAR.pending:
        return{
            ...state,
            actionForRateCal: actionForState(state.action,'pending')
        }
      case FETCH_RATE_CALENDAR.success:
        return{
            ...state,
            rateCalendar: action.payload.data,
            actionForRateCal: actionForState(state.action,'success')
        }
      case FETCH_RATE_CALENDAR.error:
        toast.error(action.payload.message);
        return{
            ...state,
            actionForRateCal: actionForState(state.action,'error',action.payload.message)
        }
      case SET_SPECIAL_RATE.pending:
        return{
            ...state,
            actionForSpecialRate: actionForState(state.action,'pending')
        }
      case SET_SPECIAL_RATE.success:
        const tmp=state.rateCalendar;
        if(action.payload.flag==="minStay"){
            toast.success("Minimum stay modified successfully!");
        }else{
            toast.success("Rate modified successfully!");
        }
        action.payload.data.forEach((row)=>{
            if(tmp[row.rentalId]){
                if(tmp[row.rentalId][row.changeDate]){
                    tmp[row.rentalId][row.changeDate]['rate']=row.dailyRate;
                    tmp[row.rentalId][row.changeDate]['channelRates']=row.channelRates;
                    tmp[row.rentalId][row.changeDate]['minimumStayRequirement']=row.minimumStayRequirement;
                }else{
                    tmp[row.rentalId][`${row.changeDate}`]={
                        "rate":row.dailyRate,
                        "channelRates":row.channelRates,
                        "minimumStayRequirement":row.minimumStayRequirement,
                        "booking":false,
                    };
                }
            }
        })
        return{
            ...state,
            rateCalendar: tmp,
            actionForSpecialRate: actionForState(state.action,'success')
        }
      case SET_SPECIAL_RATE.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForSpecialRate: actionForState(state.action,'error')
        }
      case SET_SPECIAL_RATE_MULTIPLE.pending:
        return{
            ...state,
            actionForMultipleRateSet: actionForState(state.action,'pending')
        }
      case SET_SPECIAL_RATE_MULTIPLE.success:
        if(action.payload.flag==="minStay"){
            toast.success("Minimum stay modified successfully!");
        }else{
            toast.success("Rate modified successfully!");
        }
        return {
            ...state,
            actionForMultipleRateSet: actionForState(state.action,'success')
        }
      case SET_SPECIAL_RATE_MULTIPLE.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForMultipleRateSet: actionForState(state.action,'error')
        }
      case CHANGERATE_TIMELINE.pending:
        return{
          ...state,
          actionForRatesTimeline:actionForState(state.action,'pending')
        }
      case CHANGERATE_TIMELINE.success:
        return{
          ...state,
          actionForRatesTimeline:actionForState(state.action,'success'),
          changedRatesTimeline:action.payload.data
        }
      case CHANGERATE_TIMELINE.error:
        toast.error(action.payload.message)
        return{
          ...state,
          actionForRatesTimeline:actionForState(state.action,'error')
        }
      case SET_EXTRA_GUEST_FEE.pending:
        return{
            ...state,
            actionForExtraGuest: actionForState(state.action, 'pending')
        }
      case SET_EXTRA_GUEST_FEE.success:
        toast.success("Extra guest fee successfully updated!")
        const rateSettingArray = state.rateSettings;
        const updateRates = action.payload.data;
        updateRates.forEach(newRate => {
          const findIndex = rateSettingArray.findIndex((rate) => Number(rate.rentalId) === Number(newRate.rentalId));
          if(findIndex !== -1) {
            rateSettingArray[findIndex] = {...rateSettingArray[findIndex], ...newRate};
          };
        })
        return{
            ...state,
            rateSettings: rateSettingArray,
            rateList: rateSettingArray,
            actionForExtraGuest: actionForState(state.action, 'success')
        }
      case SET_EXTRA_GUEST_FEE.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForExtraGuest: actionForState(state.action, 'error')
        }
      default:
        return state;

      case ADD_NEW_WEEKLY_FIXED_RATE.pending:
        return {
            ...state,
            actionForAddWeeklyFixedRate:actionForState(state.action,'pending')
        };
      case ADD_NEW_WEEKLY_FIXED_RATE.success:
        toast.success("Rate added successfully!")
        
        const weeklyRatesRentalIds = [];
        action.payload.data.forEach(rate => {
          if (!weeklyRatesRentalIds.includes(rate.rentalId)) {
            weeklyRatesRentalIds.push(rate.rentalId);
          }
        });
        const clearWeeklyRates = [...state.weeklyRate].filter(rate => !weeklyRatesRentalIds.includes(rate.rentalId));
        const updatedWeeklyRates = clearWeeklyRates.concat(action.payload.data);

        return {
            ...state,
            weeklyRate: updatedWeeklyRates,
            actionForAddWeeklyFixedRate:actionForState(state.action,'success')
        }
      case ADD_NEW_WEEKLY_FIXED_RATE.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForAddWeeklyFixedRate: actionForState(state.action,'error')
        }     
        
      case ADD_NEW_WEEKLY_FIXED_FEE.pending:
        return {
            ...state,
            actionForAddWeeklyFixedFee:actionForState(state.action,'pending')
        };
      case ADD_NEW_WEEKLY_FIXED_FEE.success:
        toast.success("Fee added successfully!")

        const weeklyFeesRentalIds = [];
        action.payload.data.forEach(fee => {
          if (!weeklyFeesRentalIds.includes(fee.rentalId)) {
            weeklyFeesRentalIds.push(fee.rentalId);
          }
        });
        const clearWeeklyFees = [...state.weeklyFee].filter(fee => !weeklyFeesRentalIds.includes(fee.rentalId));
        const updatedWeeklyFees = clearWeeklyFees.concat(action.payload.data);

        return {
            ...state,
            weeklyFee: updatedWeeklyFees,
            actionForAddWeeklyFixedFee:actionForState(state.action,'success')
        }
      case ADD_NEW_WEEKLY_FIXED_FEE.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForAddWeeklyFixedFee: actionForState(state.action,'error')
        }
      
      case ADD_NEW_WEEKLY_FIXED_TAX.pending:
        return {
            ...state,
            actionForAddWeeklyFixedTax:actionForState(state.action,'pending')
        };
      case ADD_NEW_WEEKLY_FIXED_TAX.success:
        toast.success("Tax added successfully!")
        
        const weeklyTaxesRentalIds = [];
        action.payload.data.forEach(tax => {
          if (!weeklyTaxesRentalIds.includes(tax.rentalId)) {
            weeklyTaxesRentalIds.push(tax.rentalId);
          }
        });
        const clearWeeklyTaxes = [...state.weeklyTax].filter(tax => !weeklyTaxesRentalIds.includes(tax.rentalId));
        const updatedWeeklyTaxes = clearWeeklyTaxes.concat(action.payload.data);

        return {
            ...state,
            weeklyTax: updatedWeeklyTaxes,
            actionForAddWeeklyFixedTax:actionForState(state.action,'success')
        }
      case ADD_NEW_WEEKLY_FIXED_TAX.error:
        toast.error(action.payload.message)
        return{
            ...state,
            actionForAddWeeklyFixedTax: actionForState(state.action,'error')
        }

        case ADD_NEW_MONTHLY_FIXED_RATE.pending:
          return {
              ...state,
              actionForAddMonthlyFixedRate:actionForState(state.action,'pending')
          };
        case ADD_NEW_MONTHLY_FIXED_RATE.success:
          toast.success("Rate added successfully!")

          const monthlyRatesRentalIds = [];
          action.payload.data.forEach(rate => {
            if (!monthlyRatesRentalIds.includes(rate.rentalId)) {
              monthlyRatesRentalIds.push(rate.rentalId);
            }
          });
          const clearMonthlyRates = [...state.monthlyRate].filter(rate => !monthlyRatesRentalIds.includes(rate.rentalId));
          const updatedMonthlyRates = clearMonthlyRates.concat(action.payload.data);

          return {
              ...state,
              monthlyRate: updatedMonthlyRates,
              actionForAddMonthlyFixedRate:actionForState(state.action,'success')
          }
        case ADD_NEW_MONTHLY_FIXED_RATE.error:
          toast.error(action.payload.message)
          return{
              ...state,
              actionForAddMonthlyFixedRate: actionForState(state.action,'error')
          }     
          
        case ADD_NEW_MONTHLY_FIXED_FEE.pending:
          return {
              ...state,
              actionForAddMonthlyFixedFee:actionForState(state.action,'pending')
          };
        case ADD_NEW_MONTHLY_FIXED_FEE.success:
          toast.success("Fee added successfully!")
          
          const monthlyFeesRentalIds = [];
          action.payload.data.forEach(fee => {
            if (!monthlyFeesRentalIds.includes(fee.rentalId)) {
              monthlyFeesRentalIds.push(fee.rentalId);
            }
          });
          const clearMonthlyFees = [...state.monthlyFee].filter(fee => !monthlyFeesRentalIds.includes(fee.rentalId));
          const updatedMonthlyFees = clearMonthlyFees.concat(action.payload.data);

          return {
              ...state,
              monthlyFee: updatedMonthlyFees,
              actionForAddMonthlyFixedFee:actionForState(state.action,'success')
          }
        case ADD_NEW_MONTHLY_FIXED_FEE.error:
          toast.error(action.payload.message)
          return{
              ...state,
              actionForAddMonthlyFixedFee: actionForState(state.action,'error')
          }
        
        case ADD_NEW_MONTHLY_FIXED_TAX.pending:
          return {
              ...state,
              actionForAddMonthlyFixedTax:actionForState(state.action,'pending')
          };
        case ADD_NEW_MONTHLY_FIXED_TAX.success:
          toast.success("Tax added successfully!")

          const monthlyTaxesRentalIds = [];
          action.payload.data.forEach(tax => {
            if (!monthlyTaxesRentalIds.includes(tax.rentalId)) {
              monthlyTaxesRentalIds.push(tax.rentalId);
            }
          });
          const clearMonthlyTaxes = [...state.monthlyTax].filter(tax => !monthlyTaxesRentalIds.includes(tax.rentalId));
          const updatedMonthlyTaxes = clearMonthlyTaxes.concat(action.payload.data);

          return {
              ...state,
              monthlyTax: updatedMonthlyTaxes,
              actionForAddMonthlyFixedTax:actionForState(state.action,'success')
          }
        case ADD_NEW_MONTHLY_FIXED_TAX.error:
          toast.error(action.payload.message)
          return{
              ...state,
              actionForAddMonthlyFixedTax: actionForState(state.action,'error')
          }
          case SET_RATE_ADJUSTMENT.pending:
            return{
                ...state,
                actionForEditChannelAdjustmentRate: actionForState(state.action,'pending')
            }
            case SET_RATE_ADJUSTMENT.success:
              toast.success('Channel rates updates successfully!')

              return{
                ...state,
                channelAdjustmentRate: action.payload.data.percentage,
                actionForEditChannelAdjustmentRate: actionForState(state.action,'success')
              }

          case SET_RATE_ADJUSTMENT.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForEditChannelAdjustmentRate: actionForState(state.action,'error')
            }

            case GET_RATE_ADJUSTMENT.pending:
              return {
                  ...state,
                  actionForGetChannelAdjustmentRate: actionForState(state.action,'pending')
              };
            case GET_RATE_ADJUSTMENT.success:
              return{
                  ...state,
                  channelAdjustmentRate: action.payload.data[0].percentage,
                  actionForGetChannelAdjustmentRate: actionForState(state.action,'success')
              }
              
            case GET_RATE_ADJUSTMENT.error:
              toast.error(action.payload.message)
              return{
                  ...state,
                  actionForGetChannelAdjustmentRate: actionForState(state.action,'error')
              } 

            case GET_WEEKLY_FIXED_RATE.pending:
              return {
                  ...state,
                  actionForGetWeeklyFixedRate:actionForState(state.action,'pending')
              };
            case GET_WEEKLY_FIXED_RATE.success:
              return{
                  ...state,
                  weeklyRate: action.payload.data,
                  actionForGetWeeklyFixedRate:actionForState(state.action,'success')
              }
            case GET_WEEKLY_FIXED_RATE.error:
              toast.error(action.payload.message)
              return{
                  ...state,
                  actionForGetWeeklyFixedRate: actionForState(state.action,'error')
              }     
              
            case GET_WEEKLY_FIXED_FEE.pending:
              return {
                  ...state,
                  actionForGetWeeklyFixedFee:actionForState(state.action,'pending')
              };
            case GET_WEEKLY_FIXED_FEE.success:
              return{
                  ...state,
                  weeklyFee:action.payload.fee,
                  actionForGetWeeklyFixedFee:actionForState(state.action,'success')
              }
            case GET_WEEKLY_FIXED_FEE.error:
              toast.error(action.payload.message)
              return{
                  ...state,
                  actionForGetWeeklyFixedFee: actionForState(state.action,'error')
              }
            
            case GET_WEEKLY_FIXED_TAX.pending:
              return {
                  ...state,
                  actionForGetWeeklyFixedTax:actionForState(state.action,'pending')
              };
            case GET_WEEKLY_FIXED_TAX.success:
              return{
                  ...state,
                  weeklyTax: action.payload.data,
                  actionForGetWeeklyFixedTax:actionForState(state.action,'success')
              }
            case GET_WEEKLY_FIXED_TAX.error:
              toast.error(action.payload.message)
              return{
                  ...state,
                  actionForGetWeeklyFixedTax: actionForState(state.action,'error')
              }
      
              case GET_MONTHLY_FIXED_RATE.pending:
                return {
                    ...state,
                    actionForGetMonthlyFixedRate:actionForState(state.action,'pending')
                };
              case GET_MONTHLY_FIXED_RATE.success:
                return{
                    ...state,
                    monthlyRate: action.payload.data,
                    actionForGetMonthlyFixedRate:actionForState(state.action,'success')
                }
              case GET_MONTHLY_FIXED_RATE.error:
                toast.error(action.payload.message)
                return{
                    ...state,
                    actionForGetMonthlyFixedRate: actionForState(state.action,'error')
                }     
                
              case GET_MONTHLY_FIXED_FEE.pending:
                return {
                    ...state,
                    actionForGetMonthlyFixedFee:actionForState(state.action,'pending')
                };
              case GET_MONTHLY_FIXED_FEE.success:
                return{
                    ...state,
                    monthlyFee: action.payload.fee,
                    actionForGetMonthlyFixedFee:actionForState(state.action,'success')
                }
              case GET_MONTHLY_FIXED_FEE.error:
                toast.error(action.payload.message)
                return{
                    ...state,
                    actionForGetMonthlyFixedFee: actionForState(state.action,'error')
                }
              
              case GET_MONTHLY_FIXED_TAX.pending:
                return {
                    ...state,
                    actionForGetMonthlyFixedTax:actionForState(state.action,'pending')
                };
              case GET_MONTHLY_FIXED_TAX.success:
                return{
                    ...state,
                    monthlyTax: action.payload.data,
                    actionForGetMonthlyFixedTax:actionForState(state.action,'success')
                }
              case GET_MONTHLY_FIXED_TAX.error:
                toast.error(action.payload.message)
                return{
                    ...state,
                    actionForGetMonthlyFixedTax: actionForState(state.action,'error')
                }
    }
  }

  
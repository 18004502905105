import React,{useState,useEffect} from "react";
import { useSelector,useDispatch } from "react-redux";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";
import Toggle from "react-toggle";
import { toast } from 'react-toastify';
import CustomDate from "../../components/CustomDate";
import styles from "./invoiceSend.module.css";
import {
  markInvoiceAsSent,
  sendInvoiceNw,
  scheduleInvoice,
  getSendInvoiceNw,
  getScheduledInvoice,
  cancelScheduledInvoice,
  resetInvoices
} from '../../general_redux/invoice/actions';
import Loading from '../loading';
import InvoiceHistoryItem from './Components/InvoiceHistoryItem/InvoiceHistoryItem';
import moment from 'moment';
import TextEditor from '../../components/TextEditor';


toast.configure();


function MainFile(props){
  const dispatch = useDispatch();
  const {bookingId, invoiceData, onEdit} = props;
  const actionForSendInvoice = useSelector(({invoice})=>invoice && invoice.actionForSendInvoice);
  const actionForScheduleInvoice = useSelector(({invoice})=> invoice && invoice.actionForScheduleInvoice);
  const sendInvoiceData = useSelector(({invoice})=>invoice && invoice.sendInvoiceData?.find(item => Number(item.invoiceId) === Number(invoiceData.id)));
  const scheduledInvoiceData = useSelector(({invoice}) => invoice && invoice.scheduledInvoiceData?.find(item => Number(item.invoiceId) === Number(invoiceData.id)));
  const actionForCancelInvoice = useSelector(({invoice})=> invoice && invoice.actionForCancelInvoice);
  const bookingData = useSelector(({calendar}) => calendar &&
      calendar.allBooking.filter((row)=>row.id===bookingId)[0]);
  const rentalDetail = useSelector(({rentals})=>rentals
      && rentals.rentals.filter((row)=> {
        if(bookingData){
          return row.id === bookingData.rentalId
        }
      })[0]);
  const timezone = useSelector(({generalSetting})=> generalSetting && generalSetting.setting && generalSetting.setting.timezone);
  const [markSend,setMarkSend] = useState(invoiceData.markSent);
  const [sendCopy,setSendCopy] = useState(true);
  const userDetail = useSelector(({user}) => user && user.user);
  const [emailInfo, setEmailInfo] = useState({
    emailTitle: `Invoice from ${userDetail.companyName || userDetail.name}`,
      message: `Dear ${invoiceData.recipientDetail.name},<br/>Please find attached your invoice.`});
  const [scheduleData, setScheduleData] = useState({sendDate: "", sendTime: ""});
  const [emailError, setEmailError] = useState({emailTitle: "",message: ""});
  const [scheduleError, setScheduleError] = useState({sendDate: "",sendTime: ""});
  const [scheduleFlag, setScheduleFlag] = useState(false);
  
  useEffect(()=>{
    return()=>{
      dispatch(resetInvoices());
    }
  },[])
  
  useEffect(() => {
    dispatch(getSendInvoiceNw());
    dispatch(getScheduledInvoice());
  }, []);

  useEffect(()=>{

    if(scheduledInvoiceData && scheduledInvoiceData.scheduledTime && !scheduledInvoiceData.emailSent ){
      const tmp = moment(scheduledInvoiceData.scheduledTime).utc(true);
      setScheduleData({
        sendDate: new Date(tmp),
        sendTime: tmp.format('HH:mm')
      })
      setScheduleFlag(true);
    } else if (actionForCancelInvoice.success) {
      setScheduleData({sendDate: "", sendTime: ""})
      setScheduleFlag(false);
    };

  },[scheduledInvoiceData, timezone])

  const handleEmailChange = (e) => {
    setEmailInfo({...emailInfo, [e.target.name]: e.target.value});
  }

  const handleScheduleChange = (e) => {
    setScheduleData({...scheduleData, [e.target.name]: e.target.value});
  }

  const handleSendNow = () => {
    let errorInfo = {emailTitle: "",message: ""};
    if(!emailInfo.emailTitle || emailInfo.emailTitle === ""){
      errorInfo.emailTitle = "Email title is required";
    }
    if(!emailInfo.message || emailInfo.message === ""){
      errorInfo.message = "Email message is required";
    }
    setEmailError(errorInfo);

    if(emailInfo.message && emailInfo.emailTitle && emailInfo.message !== "" && emailInfo.emailTitle!==""){
      const payload= {
        guestId: invoiceData?.guestId,
        id: invoiceData?.id,
        emailSubject:emailInfo.emailTitle,
        emailBody: emailInfo.message.concat("<br/><br/><b>Thank you in advance<b/><br/>The Beehaz team.<br/>"),
        sendCopyToUser: sendCopy 
      }
      dispatch(sendInvoiceNw(payload));
    }
    if(errorInfo.emailTitle !== "" || errorInfo.message !== ""){
      return false;
    }
  }

  const handleSchedule = () => {
    let errorInfo = {sendDate: "",sendTime: ""};
    if(!scheduleData.sendDate || scheduleData.sendDate === ""){
      errorInfo.sendDate = "Schedule date is required";
    }
    if(!scheduleData.sendTime || scheduleData.sendTime === ""){
      errorInfo.sendTime = "Schedule time is required";
    }
    setScheduleError(errorInfo);
    if(errorInfo.sendDate !== "" || errorInfo.sendTime !== ""){
      return false;
    }
    if(userDetail.accountType === "free"){
      toast.error("This feature is only enabled in the Plus Plan.");
    } else {
      
      const scheduleTimeUTC = moment(`${moment(scheduleData.sendDate).format('YYYY-MM-DD')} ${scheduleData.sendTime}`).utc(false);
      const payload={
        guestId: invoiceData.guestId,
        id: invoiceData.id,
        emailSubject:emailInfo.emailTitle,
        emailBody: emailInfo.message,
        sendCopyToUser: sendCopy,
        scheduleTime: scheduleTimeUTC.format('YYYY-MM-DD HH:mm:00'),
      }
      // console.log(moment(scheduleData.sendDate),scheduleData);
      dispatch(scheduleInvoice(payload));
    }
  }

  const handleMarkSent = (e) => {
    setMarkSend(!markSend);
    const payload = {
      id: invoiceData.id,
      markSent: !markSend,
      timeMarkedSent: moment(new Date()).utc(false).format('YYYY-MM-DD HH:mm:00'),
    }
    dispatch(markInvoiceAsSent(payload)); 
  }

  const handleCancel = ()=>{
    if(scheduledInvoiceData && scheduledInvoiceData.scheduledEmailId){
      const payload={
        scheduledEmailId : scheduledInvoiceData.scheduledEmailId,
      }
      dispatch(cancelScheduledInvoice(payload));
    }
    setScheduleFlag(false);
  }

  return(
    <>
      <div className={styles.mainContainer}>
      <Loading
        loadingStatus={actionForSendInvoice.loading || actionForScheduleInvoice.loading || actionForCancelInvoice.loading}
      />
        <div className={`row`}>
          <div className={`col-lg-6 col-md-12 px-xs-1`}>
            <div className="pb-2">
              <div>
                <div className="form-group row mb-0">
                  {(sendInvoiceData || (scheduledInvoiceData && scheduledInvoiceData.emailSent)) &&
                    <label className={`col-12`}>
                      This invoice has already been sent. <br />
                      See invoice history bellow.
                    </label>
                  }
                </div>
                <br />
                <div className="form-group row mb-0">
                  <label>
                    <span className={`col-sm-2 col-md-3 col-xl-2 col-form-label`}>
                      {`Mark invoice as sent`}
                      <InfoOutlinedIcon
                        fontSize="small"
                        color="inherit"
                        data-tip
                        data-for="markSend"
                        className={styles.toolTipIcon}
                      />
                      <ReactTooltip place="bottom" type="dark" id="markSend" effect="solid" className={styles.toolTip}>
                        <span>
                          If you have already downloaded this invoice and sent by email, you can mark it as sent here. You do not
                          manually have to mark this invoice as sent if you are sending it from Beehaz.
                        </span>
                      </ReactTooltip>
                    </span>
                    <Toggle
                      icons={false}
                      id={"markSendIn"}
                      checked={markSend}
                      onChange={(e)=>handleMarkSent(e)}
                    />
                  </label>
                </div>
                <div className="form-group row mb-0">
                  <label>
                    <span className={`col-sm-2 col-md-3 col-xl-2 col-form-label`}>Send me an email copy</span>
                    <Toggle
                      checked={sendCopy}
                      icons={false}
                      id={"sendEmailCopy"}
                      onChange={(e)=>setSendCopy(!sendCopy)}
                    />
                  </label>
                </div>
              </div>
              <div className={`${styles.subHeader} mt-2 mb-1`}>{"Invoice Details"}</div>
              <div>
                <div>Invoice number: {invoiceData.invoiceNumber} </div>
                <div>{`Invoice total: ${invoiceData.total} ${rentalDetail?rentalDetail.currency:""}`}</div>
                <div>Stripe button: not enabled</div>
                <div>Send to: {invoiceData?.recipientDetail?.email}</div>
              </div>
              <div className={styles.modalFooter}>
                <button className={styles.savBtn} onClick={handleSendNow}>Send now</button>
                <button className={styles.editBtn} onClick={() => onEdit(invoiceData)}>Edit Invoice</button>
              </div>
              <div className="pb-2 pt-1">
                <div className={`${styles.subHeader} mb-2 pt-2`}>{"Schedule"}
                  <InfoOutlinedIcon
                    fontSize="small"
                    color="inherit"
                    data-tip
                    data-for="paymentIcon"
                    className={styles.toolTipIcon}
                  />
                  <ReactTooltip place="bottom" type="dark" id="paymentIcon" effect="solid" className={styles.toolTip}>
                    <span>
                      If you do not wish to send the invoice now, you can schedule to send this invoice at a future date and time.
                      This is only possible in the Plus Plan.
                    </span>
                  </ReactTooltip>
                </div>
                <div className={styles.InputLabels}>
                  <label className={styles.inputLabel}>Date</label>
                  <div>
                    <CustomDate
                        id={"sendDate"}
                        name="sendDate"
                        handleChange = {(date)=>{
                          setScheduleData({...scheduleData, sendDate: date});
                        }}
                        value={scheduleData.sendDate}
                        hasError={scheduleError.sendDate!=="" && styles.form_entry_error}
                    />
                    {
                      scheduleError.sendDate !== "" && (
                          <div className={styles.form_error}>{scheduleError.sendDate}</div>
                      )
                    }
                  </div>
                </div>
                <div className={styles.InputLabels}>
                  <label className={styles.inputLabel}>Time</label>
                  <div>
                    <input
                        type={"time"}
                        name={"sendTime"}
                        onChange={handleScheduleChange}
                        className={`${styles.form_entry} ${scheduleError.sendTime !== "" && styles.form_entry_error}`}
                        value={scheduleData.sendTime}
                    />
                    {
                      scheduleError.sendTime !== "" && (
                          <div className={styles.form_error}>{scheduleError.sendTime}</div>
                      )
                    }
                  </div>
                </div>
                <div>
                  <button
                      className={styles.savBtn}
                      onClick={handleSchedule}
                      disabled={scheduleFlag}
                  >
                    Schedule
                  </button>
                  <button
                      className={styles.delBtn2}
                      onClick={handleCancel}
                      disabled={!scheduleFlag}
                  >
                    Cancel schedule
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={`col-lg-6 col-md-12`}>
            <div className="pb-2">
              <div className={`${styles.subHeader} mb-2`}>{'Email Title'}</div>
              <input
                className={`${styles.textClass} ${emailError.emailTitle !== "" && styles.textClassError}`}
                type={'text'}
                name="emailTitle"
                placeholder={`Invoice from [Company Name]`}
                value={emailInfo.emailTitle}
                onChange={handleEmailChange}
              />
              {
                emailError.emailTitle !== "" && (
                    <div className={styles.form_error}>{emailError.emailTitle}</div>
                )
              }
              <div className={`${styles.subHeader} mb-2 mt-3`}>
                {"Email Message"}
              </div>
              <TextEditor 
                theme={"snow"}
                onChange={handleEmailChange}
                value={emailInfo.message}
                placeholder={`Dear [Guest Name],Please find attached your invoice.`}
                className={styles.textArea}
              />
              {
                emailError.message !== "" && (
                    <div className={styles.form_error}>{emailError.message}</div>
                )
              }
            </div>
          </div>
        </div>
        <hr />
        <div className={`p-0 col-12`}>
          <div className={`mb-3 ${styles.subHeader}`}>Invoice history</div>
          <>
            { sendInvoiceData && 
              <InvoiceHistoryItem
                invoice={invoiceData}
                data={sendInvoiceData}
                showSentInvoice={sendInvoiceData && true} />
            }
            { scheduledInvoiceData && 
              <InvoiceHistoryItem
                invoice={invoiceData}
                data={scheduledInvoiceData}
                showScheduledInvoice={scheduledInvoiceData && true} />
            }
            { markSend && 
              <InvoiceHistoryItem
                data={invoiceData}
                showInvoiceManuallyMarkedSent={markSend} 
                handleMarkSent={handleMarkSent}/>
            }
          </>
        </div>
      </div>
    </>
  )
};

export default MainFile;
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import WeeklyRateForm from './WeeklyRateForm';
import styles from '../../../../../Rentals/rentals.module.css';

import {
    addWeeklyFixedRate,
    addWeeklyFixedFee,
    addWeeklyFixedTax
} from '../../../../../../../../general_redux/rates/actions';


const WeeklyRate = (props) => {

    const dispatch = useDispatch();

    const {
        show,
        onHide,
        editMode,
        rateData,
        feeData,
        taxData,
        currentRentalId,
    } = props;

    const validationSchema = yup.object().shape({
        rentalIds: rateData ? yup.array() : yup.array().of(yup.number()).test('minLength', 'Rental selection is a required field', value => value && value.length > 0),
        rate: yup.number().min(0, 'Must be a number greater than 0'),
        extraRateNight: yup.number().min(0, 'Must be a number greater than 0'),
    });

    const initialValues = {
        rentalIds: currentRentalId ? [currentRentalId] : [],
        rate: rateData?.rate || 0,
        extraRateNight: rateData?.extraRateNight || 0,
        fees: feeData,
        taxes: taxData,
    };

    const handleSubmit = (data) => {

        const payloadRate = data.rentalIds.map((rentalId) => ({
            rentalId: rentalId,
            rate: parseFloat(parseFloat(data.rate || 0).toFixed(2)),
            extraRateNight: parseFloat(parseFloat(data.extraRateNight || 0).toFixed(2)),
        }));

        const payloadFee = {
            rentals: data.rentalIds,
            fees: data.fees
        };

        const payloadTax = {
            rentals: data.rentalIds,
            taxes: data.taxes
        };

        // console.log(payloadRate);
        dispatch(addWeeklyFixedRate(payloadRate));

        if (payloadFee.fees.length) {
            // console.log(payloadFee);
            dispatch(addWeeklyFixedFee(payloadFee));
        };

        if (payloadTax.taxes.length) {
            // console.log(payloadTax);
            dispatch(addWeeklyFixedTax(payloadTax));
        };

        onHide();
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
                    Add/edit weekly rate, fees, and taxes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.deleteModalBody}>
                <p>
                    Weekly rate corresponds to a fixed amount charged for 7 nights
                    independent of the number of guests. This rate will only be used when
                    adding a special type of weekly booking (using the button ‘add fixed’
                    on the calendar).
                </p>
                <p>
                    Regular fees and taxes (including extra guest fees) do not apply here.
                    Set the weekly rate including all fees and taxes applicable. You should
                    set your fees and taxes applicable to this rate here. You can set
                    up to 5 different fees and 5 different taxes.
                </p>
                <WeeklyRateForm
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    handleSubmit={handleSubmit}
                    onHide={onHide}
                    editMode={editMode}
                    feeData={feeData}
                    taxData={taxData}
                    currentRentalId={currentRentalId}
                />
            </Modal.Body>
        </Modal>
    );
};

export default WeeklyRate;

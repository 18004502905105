import React from 'react';
import {Modal} from 'react-bootstrap';
import * as yup from 'yup';
import { withFormik } from 'formik';
import PropertyForm from "./PropertyForm";

import styles from '../../rentals.module.css';
import {useSelector} from "react-redux";

function AddProperty(props){
    const initValue=props.value;
    const user = useSelector(({user})=> user && user.user);
    
    const handleSubmit=(data)=>{
        const payload={
            name: data.name,
            street: data.street,
            city: data.city,
            postalCode: data.postalCode,
            country: data.country,
            currency: data.currency,
            latitude: data.latitude,
            longitude: data.longitude,
            timezone: data.timezone,
            licenseNumber: data.licenseNumber,
            checkInTimeAt: data.checkInTimeAt,
            checkOutTimeAt: data.checkOutTimeAt,
            checkinTime:data.checkInTime,
            checkoutTime:data.checkOutTime,
            contactName: data.contactName,
            phoneNumber: data.phoneNumber,
            email: data.email,
            color: data.color
        }
        if(props.value){
            payload['id'] = initValue?.id
            props.onEdit(payload);
            // console.log(payload);
        }else{
            payload['numberOfUnits'] = 1
            props.onHide(payload);
            // console.log(payload);
        }
    }

    const FormikEnhancer = withFormik({
        validationSchema:yup.object({
            name: yup.string().max(50, 'Must be 50 characters or less').required('Property name is a required field'),
            street: yup.string().max(50, 'Must be 50 characters or less'),
            city: yup.string().max(50, 'Must be 50 characters or less'),
            postalCode: yup.string().max(20, 'Must be 20 characters or less'),
            licenseNumber: yup.string().max(250, 'Must be 250 characters or less'),
            checkInTime: yup.string().required('Check-in Time is required'),
            checkOutTime: yup.string().required('Check-out Time is required'),
            contactName: yup.string().max(50, 'Must be 50 characters or less'),
            phoneNumber: yup.string().max(50, 'Must be 50 characters or less'),
            email: yup.string().email('Incorrect email format').max(100, 'Must be 100 characters or less'),
        }),
        validateOnBlur:false,
        validateOnChange:false,
        mapPropsToValues: () => ({
            name: initValue?.name || "",
            street: initValue?.street || "",
            city: initValue?.city || "",
            postalCode: initValue?.postalCode || "",
            currency: initValue?.currency || user.currency,
            country: initValue?.country || user.country,
            latitude: initValue?.latitude || "", // TODO: getCoordinates(city, country),
            longitude: initValue?.longitude || "", // --- getCoordinates(user?.address2, user?.country),
            timezone: initValue?.timezone || (user.timezone || ""),
            checkInTimeAt: initValue?.checkInTimeAt || "from",
            checkOutTimeAt: initValue?.checkOutTimeAt || "until",
            checkInTime: initValue?.checkinTime || "15:00",
            checkOutTime: initValue?.checkoutTime || "11:00",
            licenseNumber: initValue?.licenseNumber || "",
            contactName: initValue?.contactName || "",
            phoneNumber: initValue?.phoneNumber || "",
            email: initValue?.email || "",
            color: initValue?.color || "#dcdcdc",
        }),
        handleSubmit :  (values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          } ,
        displayName: 'PropertyForm',
      })(PropertyForm);

    return(
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
                    { initValue?.id ? 
                        'Edit property'
                    :
                        'Add property'
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.deleteModalBody}>
                <FormikEnhancer/>
            </Modal.Body>
        </Modal>
    )
}
export default AddProperty;
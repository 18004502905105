import React, { forwardRef,useState,useEffect,useImperativeHandle,useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from 'react-toastify';
import { Spring } from "react-spring/renderprops";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import Timeline, {
    TimelineHeaders,
    DateHeader,
    SidebarHeader,
    TimelineMarkers,
    CustomMarker,
} from "react-calendar-timeline";
import styles from "./customTimeLien.module.css";
import 'react-calendar-timeline/lib/Timeline.css';

const CustomTimeLine=forwardRef((props,ref)=> {
    const { width, rentalObject, allBookings, setCurrentSelectMonth, setCurrentSelectYear, itemStatus, actionForRental, clickItem, selectEmptyNight, removeNewItem } = props;
    const dateInput=useRef();
    //calendar props
    const d = new Date();
    const now = new Date().getUTCFullYear();
    const minTime =moment(`${now-3}-01-01 00:00`,"YYYY-MM-dd HH:mm").valueOf()
    const maxTime = moment(`${now+4}-01-01 00:00`,"YYYY-MM-dd HH:mm").valueOf()
    const myLast = now + 3;
    const years = Array(myLast - (myLast - 7))
        .fill('')
        .map((v, idx) => myLast - idx)
        .reverse();

    const [currentMonth,setCurrentMonth]=useState(d.getMonth());
    const [itemList, setItemList] = useState(allBookings);
    const [arrive , setArrive] = useState('');
    const [rentalId, setRentalId] = useState(0);
    const [newEvent, setNewEvent] = useState(false);
    const [currentYear, setCurrentYear] = useState(years.length - 4);
    const [sidebarWidth,setSidebarWidth]=useState(width<=575?70:width<=900?100:130);
    const [totalDays,setTotalDays]= useState(width<400?5 : width < 575 ? 8 : width<700? 12 : width<1000?15 :width<1260?18 : width < 1400 ? 20 : width >= 1700 ? 30 : 22);
    const [visibleTimeStart,setVisibleTimeStart] = useState(moment().add(-1, "day").valueOf());
    const [visibleTimeEnd,setVisibleTimeEnd] = useState(moment().add(totalDays, "day").valueOf());

    const getGroups = useSelector(({rentals}) => rentals && rentals.groups);
    const getProperties = useSelector(({rentals}) => rentals && rentals.properties);
    const generalSetting = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
    
    const today = moment().format('YYYY-MM-DD');
    const startOfLockedDates = moment(today).add(generalSetting.numberOf, generalSetting.allowBookingFor);
    const endOfLockedDates = moment(`${now+4}-01-01`);
    let endOfLockedDatesStyles;

    useEffect(()=>{
        setSidebarWidth(width<=575?70:width<=900?100:130);
        setTotalDays(width<400?5 : width < 575 ? 8 : width<700? 12 : width<1000?15 :width<1260?18 : width < 1400 ? 20 : width >= 1700 ? 30 : 22);
    },[width])
    
    useEffect(()=>{
        setItemList([...allBookings]);
    }, [allBookings])
    
    useEffect(()=>{
        if (!removeNewItem) {
            setItemList([...allBookings]);
        };
    }, [removeNewItem])

    useEffect(()=>{

        if (newEvent) {

            document.getElementById('emptyNight').addEventListener('click', (event) => {
                const element = document.getElementById('emptyNight');
                const rect = element.getBoundingClientRect();
                const distanceToScreenMargin = {
                    right: window.innerWidth - rect.right,
                    bottom: window.innerHeight - rect.bottom,
                };

                selectEmptyNight(rentalId, arrive, event, distanceToScreenMargin);
            });

            const triggerButtonClick = () => {
                const button = document.getElementById('emptyNight');
                button.click();
            }
        
            triggerButtonClick();
            setNewEvent(false);
        };

    }, [newEvent])

    useEffect(()=>{
        setVisibleTimeStart(moment().add(-1, "day").valueOf());
        setVisibleTimeEnd(moment().add(totalDays, "day").valueOf());
    },[totalDays])

    const findYear=(val)=>{
        return years.findIndex((year) => year === val);
    }

    useImperativeHandle(ref, () => ({
        onPrevClick(){
            const zoom = visibleTimeEnd - visibleTimeStart;
            if (visibleTimeStart-zoom > minTime){
                setVisibleTimeStart(visibleTimeStart - zoom);
                setVisibleTimeEnd(visibleTimeEnd-zoom);
                const myDate = moment(visibleTimeStart-zoom).format("DD-M-YYYY").split('-');
                if(currentMonth!==Number(myDate[1])-1){
                    setCurrentMonth(Number(myDate[1])-1);
                    setCurrentSelectMonth(Number(myDate[1])-1);
                }
                if(currentYear!==findYear(Number(myDate[2]))){
                    setCurrentYear(findYear(Number(myDate[2])));
                    setCurrentSelectYear(findYear(Number(myDate[2])));
                }
            }
        },
        onNextClick(){
            const zoom = visibleTimeEnd - visibleTimeStart;
            if(visibleTimeEnd+zoom <maxTime){
                setVisibleTimeStart(visibleTimeStart + zoom);
                setVisibleTimeEnd(visibleTimeEnd + zoom);
                const myDate = moment(visibleTimeStart + zoom).format("DD-M-YYYY").split('-');
                if(currentMonth!==Number(myDate[1])-1){
                    setCurrentMonth(Number(myDate[1])-1);
                    setCurrentSelectMonth(Number(myDate[1])-1);
                }
                if(currentYear!==findYear(Number(myDate[2]))){
                    setCurrentYear(findYear(Number(myDate[2])));
                    setCurrentSelectYear(findYear(Number(myDate[2])));
                }
            }
        },
        renderToday(){
            setVisibleTimeStart(moment().add(-1, "day").valueOf());
            setVisibleTimeEnd(moment().add(totalDays, "day").valueOf());
            setCurrentMonth(d.getMonth());
            setCurrentSelectMonth(d.getMonth());
            setCurrentYear(years.length - 4);
            setCurrentSelectYear(years.length - 4);
        },
        onMonthUpdate(index){
            setCurrentMonth(index);
            setVisibleTimeStart(moment(`1-${index+1}-${years[currentYear]}`, "DD-MM-YYYY").add(-1, "day").valueOf());
            setVisibleTimeEnd(moment(`1-${index+1}-${years[currentYear]}`, "DD-MM-YYYY").add(totalDays, "day").valueOf());
        },
        onYearUpdate(index){
            setCurrentYear(index);
            setCurrentSelectYear(index);
            setVisibleTimeStart(moment(`1-${currentMonth+1}-${years[index]}`,"DD-MM-YYYY").add(-1,"day").valueOf());
            setVisibleTimeEnd(moment(`1-${currentMonth+1}-${years[index]}`,"DD-MM-YYYY").add(totalDays,"day").valueOf());
        }
    }));

    const handleTimeChange = (visibleTimeStart, visibleTimeEnd,updateScrollCanvas) => {
        if(visibleTimeStart < minTime && visibleTimeEnd > maxTime){
            setVisibleTimeStart(minTime)
            setVisibleTimeEnd(maxTime);
            updateScrollCanvas(minTime, maxTime);
        }else if (visibleTimeStart < minTime){
            setVisibleTimeStart(minTime);
            setVisibleTimeEnd(minTime + (visibleTimeEnd - visibleTimeStart));
            updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart))
        }else if(visibleTimeEnd > maxTime){
            setVisibleTimeStart(maxTime - (visibleTimeEnd - visibleTimeStart))
            setVisibleTimeEnd(maxTime);
            updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)
        }else{
            setVisibleTimeStart(visibleTimeStart);
            setVisibleTimeEnd(visibleTimeEnd);
            const myDate = moment(visibleTimeStart).format("DD-M-YYYY").split('-');
            if(currentMonth!==Number(myDate[1])-1) {
                setCurrentMonth(Number(myDate[1]) - 1);
                setCurrentSelectMonth(Number(myDate[1]) - 1);
            }
            if(currentYear!==findYear(Number(myDate[2]))){
                setCurrentYear(findYear(Number(myDate[2])));
                setCurrentSelectYear(findYear(Number(myDate[2])));
            }
            updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
        }
    };
    //renderer
    const groupRenderer = ({ group: rental }) => {
        
        const currentRental = rentalObject.find(rentalObj => rentalObj?.id === rental.id);
        const currentGroup = getGroups.find(groupObj => groupObj?.id === currentRental.groupId);
        const currentProperty = getProperties.find(propertyObj => propertyObj?.id === currentRental.propertyId);

        return (
            <div className={`${styles.customGroup}`}>
                <div className={styles.propertyColor} style={{backgroundColor: `${currentProperty?.color}`}}></div>
                <span className={styles.title}>
                    {currentRental.parentRentalId &&
                        <SubdirectoryArrowRightIcon
                            fontSize="small"
                            style={{ color: "#9e9e9e" }}
                        />
                    }
                    {currentRental.title}
                </span>
                <div className={styles.groupColor} style={{borderBottom: `15px solid ${currentGroup?.color}`}}></div>
            </div>
        )
    }

    const itemRenderer = ({
              item,
              itemContext,
              getItemProps,
              getResizeProps,
          }) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
        return (
            <div key={item.id} {...getItemProps(item.itemProps)} className={item.type !== "emptyNight" ? styles.itemBlock : styles.itemBlockEmptyNight} >
                {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
                {["booking", "weekly", "monthly", "channelBooking"].includes(item.type) && (
                    <div
                        className={styles.rctItemContent}
                        style={{ maxHeight: `${itemContext.dimensions.height}`, borderLeft:`5px solid ${item.color}`}}
                    >
                        <span className={styles.itemText}>{item.title}</span>
                        <span className={styles.itemStatus} style={{backgroundColor: `${(item.paymentStatus==="Payment Pending")
                                ?"#dc3545":((item.paymentStatus==="Partially Paid")?"#f5b60e":"#439a86")}`}}/>
                    </div>
                )}
                {item.type==="blockdates" && (
                    <div className={styles.blockdate}>
                        <span className={styles.blockDateText}>{item.title}</span>
                    </div>
                )}
                {item.type==="emptyNight" && (
                    <div id="emptyNight" />
                )}
                {["blockNightsBefore", "blockNightsAfter", "notAvailable"].includes(item.type) && (
                    <div className={item.type==="notAvailable" ? styles.notAvailable : styles.blockdate}>
                        <span className={styles.notAvailableText}>{item.title}</span>
                    </div>
                )}
                {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
            </div>
        )}

    const handleCanvasClick = async (groupId, time) => {

        const arrive = moment(time).format('YYYY-MM-DD');
        const depart = moment(time).format('YYYY-MM-DD');
        
        const isBlockedDate = moment(depart).isBefore(startOfLockedDates);

        if (isBlockedDate) {
            const emptyNight = {
                type: 'emptyNight',
                group: groupId,
                status: false,
                start_time: moment(`${arrive} 12:00`, "YYYY-MM-DD HH:mm"),
                end_time: moment(`${depart} 12:05`, "YYYY-MM-DD HH:mm"),
            };

            setItemList([
                ...itemList,
                emptyNight
            ]);

            setNewEvent(true);
            setRentalId(groupId);
            setArrive(time);
        } else {
            toast.warning('This date is blocked. Future dates are blocked according to the calendar windows settings.');
        };
    };

    return (
            <div >
                {(width && !itemStatus.loading && !actionForRental.loading) && (
                    <>
                        <Spring
                            config={{ duration: 250 }}
                            to={{ visibleTimeStart, visibleTimeEnd }}
                            immediate={true}
                        >
                            {() => (
                                <Timeline
                                    groups={rentalObject}
                                    items={itemList}
                                    itemTouchSendsClick={false}
                                    stackItems
                                    fixedHeader="fixed"
                                    groupRenderer={groupRenderer}
                                    itemRenderer={itemRenderer}
                                    showCursorLine
                                    sidebarWidth={sidebarWidth}
                                    headerLabelHeight={40}
                                    headerLabelGroupHeight={40}
                                    lineHeight={40}
                                    className={`p-2 ${styles.mainClass}`}
                                    canMove={false}
                                    canResize={false}
                                    visibleTimeStart={visibleTimeStart}
                                    visibleTimeEnd={visibleTimeEnd}
                                    onTimeChange={handleTimeChange}
                                    onItemSelect={(itemId, e, time)=>clickItem(itemId)}
                                    onItemClick = {(itemId, e, time)=>clickItem(itemId)}
                                    clickTolerance={30}
                                    onCanvasClick={handleCanvasClick}
                                >
                                    <TimelineMarkers>
                                        <CustomMarker date={moment().startOf("day")}>
                                            {/* custom renderer for this marker */}
                                            {({ styles, date }) => {
                                                let customStyles;
                                                customStyles = (width<1200)?{
                                                    ...styles,
                                                    backgroundColor: 'transparent',
                                                    width:`${dateInput.current.scrollWidth+1}px`,
                                                    border:'3px solid #439a86',
                                                    borderTop:'none',
                                                    zIndex:70,
                                                    bottom:`3px`,
                                                }:{
                                                    ...styles,
                                                    backgroundColor: 'transparent',
                                                    width:`${dateInput.current.scrollWidth+1}px`,
                                                    border:'3px solid #439a86',
                                                    borderTop:'none',
                                                    zIndex:70,
                                                    bottom:`6px`,
                                                }
                                                return <div style={customStyles} />
                                            }}
                                        </CustomMarker>
                                        <CustomMarker date={endOfLockedDates}>
                                            {({ styles }) => {
                                            endOfLockedDatesStyles = styles;
                                            return <div style={{ ...styles, backgroundColor: 'transparent' }}></div>;
                                            }}
                                        </CustomMarker>
                                        <CustomMarker date={startOfLockedDates}>
                                            {({ styles }) => {
                                            const width = (endOfLockedDatesStyles.left - styles.left);
                                            return (
                                                <div
                                                  style={{
                                                    ...styles,
                                                    backgroundColor: 'rgba(128, 128, 128, 0.2)',
                                                    zIndex: 70,
                                                    width: `${width}px`,
                                                  }}
                                                ></div>
                                            )}}
                                        </CustomMarker>
                                    </TimelineMarkers>
                                    <TimelineHeaders className={`${styles.header} sticky`} style={{ color: 'black'}}>
                                        <SidebarHeader>
                                            {({ getRootProps }) => {
                                                return <div className={`${styles.rentalHeader}`} {...getRootProps()}>{`Rentals`}</div>;
                                            }}
                                        </SidebarHeader>
                                        <DateHeader
                                            unit="month"
                                            labelFormat={"MMMM-YYYY"}
                                            // height={21}
                                            intervalRenderer={({
                                                                   getIntervalProps,
                                                                   intervalContext,
                                                                   data
                                                               }) => {
                                                const text =
                                                    intervalContext.intervalText &&
                                                    intervalContext.intervalText.split("-");
                                                return (
                                                    <div {...getIntervalProps()}>
                                                        {text && text.length > 1 ? (
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        textAlign: "center",
                                                                        color: "Black",
                                                                        backgroundColor: "#f3f6f5",
                                                                        fontSize: "13px",
                                                                        padding: "5px 0px"
                                                                    }}
                                                                >
                                                                    <span>{`${text[0]}, ${text[1]}`}</span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            intervalContext.intervalText
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        />
                                        <DateHeader labelFormat="DD-ddd-MMM" unit="day" height={50}
                                                    intervalRenderer={({ getIntervalProps, intervalContext, data }) => {
                                                        const text = intervalContext.intervalText && intervalContext.intervalText.split('-')
                                                        return <div {...getIntervalProps()} className={
                                                            (intervalContext.intervalText===moment().format("DD-ddd-MMM"))?
                                                                `${styles.dataToday}`:`${styles.dataHeader}`}>

                                                            {text && text.length > 1 ? (
                                                                <div className={`d-flex flex-column ${styles.dataHeaderBox}`} ref={dateInput}>
                                                                    <span className={(text[1]==="Sat"||text[1]==="Sun")?styles.holidayHead:styles.dayHead}>{text[1]}</span>
                                                                    <span className={(text[1]==="Sat"||text[1]==="Sun")?styles.holidateHead:styles.dateHead} >{text[0]}</span>
                                                                    <span className={(text[1]==="Sat"||text[1]==="Sun")?styles.holiMonthHead:styles.monthHead}>{text[2]}</span> 
                                                                </div>
                                                            ) : intervalContext.intervalText}
                                                        </div>
                                                    }}
                                        />
                                    </TimelineHeaders>
                                </Timeline>
                            )}
                        </Spring>
                    </>
                )}
            </div>
        );
})
export default CustomTimeLine;
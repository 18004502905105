import React, { useEffect, useState } from 'react';
import styles from '../editBooking.module.css';
import { Form, Col } from 'react-bootstrap';
import Loading from '../../../../../../../components/loading';
import { useDispatch,useSelector } from 'react-redux';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import * as yup from 'yup';
import moment from 'moment';
import { Formik } from 'formik';
import ReactTooltip from 'react-tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PhoneInput from 'react-phone-input-2';
import { editGuest } from '../../../../../../../general_redux/guest/actions';
import { findCountry } from '../../../../../../../common/functions/utils';
import {getGuestByBooking} from "../../../../../../../general_redux/guest/actions";
import CustomDate from "../../../../../../../components/CustomDate";

const GuestDetails = ({bookingId}) => {
  const phoneRegExp = /^[0-9-+\s()]*$/;
  const user= useSelector(({user})=>user && user.user);
  const actionForUpdateGuest = useSelector(({guests})=> guests && guests.actionForUpdateGuest);
  const guestDetails = useSelector(({guests})=>guests && guests?.guestByBooking[0]);
  const dispatch = useDispatch();
  const countries = countryList().getData();
  const [dialCode, setDialCode] = useState(1);
  const [primaryPhone, setPrimaryPhone] = useState(guestDetails?.phoneNo || '');
  const [secondaryPhone, setSecondaryPhone] = useState(guestDetails?.secondaryPhoneNo || '');

  const validationSchema=yup.object({
    name: yup.string().required('Guest name is required'),
    company: yup.string(),
    emailId:yup.string().email('Invalid email').required('Email address is required'),
    secondaryEmailId :yup.string().email('Invalid email'),
    phoneNo:yup.string().matches(phoneRegExp, 'Only numbers, space, parenthesis, + and - are accepted.'),
    secondaryPhoneNo:yup.string().matches(phoneRegExp, 'Only numbers, space, parenthesis, + and - are accepted.'),
    country: yup.object(),
    address: yup.string(),
    postalCode: yup.string(),
    street: yup.string(),
    state: yup.string(),
    nationality:yup.string(),
    language:yup.string(),
    dob: yup.string().nullable(),
    notes:yup.string(),
  });

  useEffect(()=>{
    if(actionForUpdateGuest.success){
      dispatch(getGuestByBooking(bookingId));
    }
  },[actionForUpdateGuest])

  const code = guestDetails?.country ? findCountry(guestDetails?.country) : findCountry(user.country);

  const handleSubmit = (data) => {
    const payload = {
      id: guestDetails?.id,
      name: data.name,
      emailId:data.emailId,
      phoneNo: data.phoneNo.split(" ")?.length ===1 ? "" : data.phoneNo,
      secondaryPhoneNo: data.secondaryPhoneNo.split(" ")?.length ===1 ? "" : data.secondaryPhoneNo,
      postalCode: data.postalCode,
      guestType: data.guestType,
      secondaryEmailId: data.secondaryEmailId,
      country: data.country?.label || "",
      address: data.address,
      street: data.street,
      state:data.state,
      nationality:data.nationality,
      language:data.language,
      company:data.company,
      notes:data.notes,
      dob: data.dob ? moment(data.dob).format("YYYY-MM-DD") : ''
    };
    // console.log(payload);
    dispatch(editGuest(payload));
  };

  const initialValues = {
    name: guestDetails?.name || '',
    company: guestDetails?.company || '',
    emailId: guestDetails?.emailId || '',
    secondaryEmailId: guestDetails?.secondaryEmailId || '',
    phoneNo: guestDetails?.phoneNo || '',
    guestType: guestDetails?.guestType || 'guest',
    secondaryPhoneNo: guestDetails?.secondaryPhoneNo || '',
    postalCode: guestDetails?.postalCode || '',
    state: guestDetails?.state || '',
    nationality: guestDetails?.nationality || '',
    language: guestDetails?.language || '',
    country: guestDetails?.country && {value: guestDetails.country, label: guestDetails.country} || '',
    address: guestDetails?.address || '',
    street: guestDetails?.street || '',
    dob: (guestDetails?.dob && guestDetails?.dob !== "Invalid da") ? new Date(moment(guestDetails.dob)) : "",
    notes: guestDetails?.notes || '',
  };

  return (
    <div>
      <Loading loadingStatus={actionForUpdateGuest.loading}/>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, errors, handleChange, setFieldValue, isSubmitting, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="p-3">
                <Col>
                  <div className="pb-2 d-flex flex-direction-column">
                    <div className={styles.subHeader}> Guest </div>
                    <div className={styles.toolTipIconContainer}>
                      <InfoOutlinedIcon
                        fontSize="small"
                        color="inherit"
                        data-tip
                        data-for="guest"
                        className={styles.toolTipIcon}
                      />
                      <ReactTooltip place="bottom" type="dark" id="guest" effect="solid" className={styles.toolTip}>
                        <span>
                          Changes to the guest here will affect the guest details saved to this guest. This is the information you will find under the guest’s name by clicking on ‘Guests’ in the left menu
                        </span>
                      </ReactTooltip>
                    </div>
                  </div>
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">Guest name*: </Form.Label>
                        <div className={"col-lg-4"}>
                            <Form.Control
                                type="text"
                                name="name"
                                onChange={handleChange}
                                isInvalid={!!errors.name }
                                value={values.name}
                                style={{fontSize:'inherit'}}
                            />
                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                        </div>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">Company: </Form.Label>
                        <div className={"col-lg-4"}>
                            <Form.Control
                                type="text"
                                name="company"
                                onChange={handleChange}
                                isInvalid={!!errors.company }
                                value={values.company}
                                style={{fontSize:'inherit'}}
                            />
                            <Form.Control.Feedback type="invalid">{errors.company}</Form.Control.Feedback>
                        </div>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">Primary email*: </Form.Label>
                        <div className={"col-lg-4"}>
                            <Form.Control
                                type="text"
                                name="emailId"
                                onChange={handleChange}
                                isInvalid={!!errors.emailId }
                                value={values.emailId}
                                style={{fontSize:'inherit'}}
                            />
                            <Form.Control.Feedback type="invalid">{errors.emailId}</Form.Control.Feedback>
                        </div>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="d-flex justify-content-between col-lg-3 col-form-label">
                            <div>Secondary email:</div>
                        </Form.Label>
                        <div className={"col-lg-4"}>
                            <Form.Control
                                type="text"
                                name="secondaryEmailId"
                                onChange={handleChange}
                                isInvalid={!!errors.secondaryEmailId }
                                value={values.secondaryEmailId}
                                style={{fontSize:'inherit'}}
                            />
                            <Form.Control.Feedback type="invalid">{errors.secondaryEmailId}</Form.Control.Feedback>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            if (values.secondaryEmailId) {
                              const emailId = values.emailId;
                              setFieldValue("emailId", values.secondaryEmailId);
                              setFieldValue("secondaryEmailId", emailId);
                            };
                          }}
                          className={`${styles.makePrimary} d-none ml-2 d-lg-flex justify-content-center align-items-center`}
                        >
                          make primary
                        </button>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">Phone number: </Form.Label>
                        <div className={"col-lg-4"}>
                            <PhoneInput
                                country={code}
                                name="phoneNo"
                                value={primaryPhone}
                                onChange={(value, data) => {
                                  const rawVal = value.slice(data.dialCode.length);
                                  const phone = rawVal ? `+${data.dialCode} ${rawVal}` : "";
                                  setFieldValue("phoneNo", phone);
                                  setPrimaryPhone(phone ||`+${dialCode}`);
                                }}
                                inputStyle={{width: '100%'}}
                                countryCodeEditable={false}
                                enableSearch={true}
                                disableSearchIcon={true}
                            />
                            <Form.Control.Feedback type="invalid">{errors.phoneNo}</Form.Control.Feedback>
                        </div>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="d-flex justify-content-between col-lg-3 col-form-label">
                            <div>Secondary phone:</div>
                        </Form.Label>
                        <div className={"col-lg-4"}>
                            <PhoneInput
                                country={code}
                                name="secondaryPhoneNo"
                                value={secondaryPhone}
                                onChange={(value, data) => {
                                  const rawVal = value.slice(data.dialCode.length);
                                  const phone = rawVal ? `+${data.dialCode} ${rawVal}` : "";
                                  setFieldValue("secondaryPhoneNo", phone);
                                  setSecondaryPhone(phone || `+${dialCode}`);
                                  setDialCode(data.dialCode);
                                }}
                                inputStyle={{width: '100%'}}
                                countryCodeEditable={false}
                                enableSearch={true}
                                disableSearchIcon={true}
                            />
                            <Form.Control.Feedback type="invalid">{errors.secondaryPhoneNo}</Form.Control.Feedback>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            const rawVal = values.secondaryPhoneNo.slice(`+${dialCode} `.length);
                            if (rawVal) {
                                const phone = values.phoneNo || `+${dialCode}`;
                                setFieldValue("phoneNo", values.secondaryPhoneNo);
                                setFieldValue("secondaryPhoneNo", phone);
                                setPrimaryPhone(values.secondaryPhoneNo);
                                setSecondaryPhone(phone);
                            };
                          }}
                          className={`${styles.makePrimary} d-none ml-2 d-lg-flex justify-content-center align-items-center`}
                        >
                          make primary
                        </button>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">Select country: </Form.Label>
                        <div className={"col-lg-4"}>
                            <Select
                                id="countrySelect"
                                options={countries}
                                isClearable={true}
                                value={values.country}
                                name={"country"}
                                className={`w-100 ${styles.form_entry_select}`}
                                onChange={(val) => setFieldValue("country", val)}
                            />
                        </div>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">Street and nr:</Form.Label>
                        <div className={"col-lg-4"}>
                            <Form.Control
                                type="text"
                                name="street"
                                onChange={handleChange}
                                isInvalid={!!errors.street }
                                value={values.street}
                                style={{fontSize:'inherit'}}
                            />
                            <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
                        </div>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">State/Province: </Form.Label>
                        <div className={"col-lg-4"}>
                            <Form.Control
                                type="text"
                                name="state"
                                onChange={handleChange}
                                isInvalid={!!errors.state }
                                value={values.state}
                                style={{fontSize:'inherit'}}
                            />
                            <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                        </div>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">Postal code: </Form.Label>
                        <div className={"col-lg-4"}>
                            <Form.Control
                                type="text"
                                name="postalCode"
                                onChange={handleChange}
                                isInvalid={errors.postalCode}
                                value={values.postalCode}
                                style={{fontSize:'inherit'}}
                            />
                            <Form.Control.Feedback type="invalid">{errors.postalCode}</Form.Control.Feedback>
                        </div>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">Address details: </Form.Label>
                        <div className={"col-lg-4"}>
                            <Form.Control
                                type="text"
                                name="address"
                                onChange={handleChange}
                                isInvalid={!!errors.address }
                                value={values.address}
                                style={{fontSize:'inherit'}}
                            />
                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                        </div>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">Nationality: </Form.Label>
                        <div className={"col-lg-4"}>
                            <Form.Control
                                type="text"
                                name="nationality"
                                onChange={handleChange}
                                isInvalid={!!errors.nationality }
                                value={values.nationality}
                                style={{fontSize:'inherit'}}
                            />
                            <Form.Control.Feedback type="invalid">{errors.nationality}</Form.Control.Feedback>
                        </div>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">Language: </Form.Label>
                        <div className={"col-lg-4"}>
                            <Form.Control
                                type="text"
                                name="language"
                                onChange={handleChange}
                                isInvalid={!!errors.language }
                                value={values.language}
                                style={{fontSize:'inherit'}}
                            />
                            <Form.Control.Feedback type="invalid">{errors.language}</Form.Control.Feedback>
                        </div>
                    </Form.Row>
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">Birthday:</Form.Label>
                        <div className={"col-lg-4"}>
                            <CustomDate
                                customClass={errors.dob ? `w-100 ${styles.form_entry_err}` : `w-100 ${styles.form_entry}`}
                                name="dob"
                                handleChange={(date)=>{
                                    setFieldValue("dob",date);
                                }}
                                value={values.dob}
                                max={new Date()}
                                min={new Date(`${(new Date().getUTCFullYear())-100}-01-01`)}
                            />
                            <Form.Control.Feedback type="invalid">{errors.dob}</Form.Control.Feedback>
                        </div>
                    </Form.Row >
                    <Form.Row className="pb-2">
                        <Form.Label className="col-lg-3 col-form-label">More info:</Form.Label>
                        <div className={"col-lg-4"}>
                            <textarea
                                className="form-control"
                                rows="4"
                                name="notes"
                                onChange={handleChange}
                                value={values.notes}
                                style={{fontSize:'inherit'}}
                            />
                            <Form.Control.Feedback type="invalid">{errors.notes}</Form.Control.Feedback>
                        </div>
                    </Form.Row>
                </Col>
              <div className={`d-flex justify-content-center ${styles.button_group}`}>
                <div>
                  <button type="submit" className={styles.settings_save}>
                    Save changes
                  </button>
                </div>
              </div>
            </Form>
        )}
      </Formik>
    </div>
  );
};

export default GuestDetails;

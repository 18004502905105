import React, { useEffect, useState, useRef, useMemo } from 'react';
import styles from './guest.module.css';
import { useSelector, useDispatch } from 'react-redux';
import SearchField from 'react-search-field';
import { Form } from 'react-bootstrap';
import AddGuests from './components/AddGuests/AddGuests';
import ListGuest from './components/List/ListGuests';
import { addGuests, editGuest, loadGuests, deleteGuest } from '../../../../general_redux/guest/actions';
import * as XLSX from 'xlsx';

function Guests() {
  const dropWrapper=useRef(null);

  const [addGuestModal, setAddGuestModal] = useState(false);
  const [edited, setEdited] = useState(undefined);
  const [search, setSearch] = useState('');
  const [includeChannelGuests, setIncludeChannelGuests] = useState(true);
  const [dropActive,setActive]=useState(false);

  const dispatch = useDispatch();

  const allGuests = useSelector(({ guests }) => guests && guests.guestList);

  const filteredGuests = useMemo(() => {
    if (includeChannelGuests) {
      return allGuests;
    } else {
      return allGuests.filter(((guest) => guest?.guestType !== 'channelGuest'))
    };
  },[includeChannelGuests,allGuests])

  useEffect(() => {
    function handler(event) {
      if(!dropWrapper.current?.contains(event.target)){
        setActive(false);
      }
    }
    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler)
  }, []);

  useEffect(() => {
    dispatch(loadGuests());
  }, []);

  const handleSubmit = (data) => {
    if (edited) {
      dispatch(editGuest(data));
    } else {
      dispatch(addGuests(data));
    }
    setAddGuestModal(false);
    setEdited(null);
  };
  const handleEdit = (value) => {
    setEdited(value);
    setAddGuestModal(true);
  };

  const handleDelete = (row) => {
    dispatch(deleteGuest(row.id));
  };

  document.addEventListener('mouseup', function(e) {
    // if(dropActive){
    //   setActive(!dropActive);
    // }
  });

  const handleDownload=(e,eType)=>{
    e.preventDefault();
    const dataTobe=filteredGuests.filter(
        (guest) =>
            guest.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
            guest.emailId.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    const fileName=`GuestList_${Date.now()}`;
    let data=[];
    dataTobe.forEach((items)=>{
      let tmp={};
      Object.keys(items).map((key,index)=>{
        tmp={
          "Name":items['name'],
          "Company":items['company'],
          "Birth Date":items["dob"],
          "Nationality":items["nationality"],
          "Country":items["country"],
          "Street and nr":items["street"],
          "Postal code":items["postalCode"],
          "State/Province":items["state"],
          "Phone(primary)":items["phoneNo"],
          "Phone(secondary)":items["secondaryPhoneNo"],
          "Email":items["emailId"],
          "Email(secondary)": items["secondaryEmailId"]
        }
      })
      data.push(tmp);
    })
    const guestHeading = ["Name", "Company", "Birth Date", "Nationality", "Country", "Street and nr", "Postal code", "State/Province", "Phone","Phone(secondary)", "Email","Email(secondary)"]
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [guestHeading], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'GuestSheet');
    XLSX.writeFile(wb, `${fileName}.${eType}`,{ bookType: eType, type: 'array' });
    return true;
  }

  return (
    <>
      {addGuestModal ?(
          <AddGuests
              show={addGuestModal}
              onHide={(data) => {
                setAddGuestModal(false);
                setEdited(null);
              }}
              onSubmit={(data)=>handleSubmit(data)}
              value={edited}
          />
      ):(
          <>
            <div className={`py-1 d-md-flex ${styles.guestHeader}`}>
              <div className={`m-0 py-1 px-2 pl-md-3 col-12 col-md-4 ${styles.searchContainer}`}>
                <SearchField
                    placeholder="Search Guests"
                    onChange={(val) => setSearch(val)}
                    searchText=""
                    classNames={styles.search_field}
                />
              </div>

              <div onClick={() => setIncludeChannelGuests(!includeChannelGuests)} className={`m-0 py-0 px-1 col-12 col-md-3 ${styles.filterGuestsCheckBox}`}>
                <div className="ml-1">
                  <Form.Check
                    type="checkbox"
                    name="checkIncludeChannelGuests"
                    checked={includeChannelGuests}
                  />
                </div>
                <Form.Label className={`col-form-label ${styles.filterGuestsLabel}`}>
                  Include channel guests
                </Form.Label>
              </div>

              <div className={`m-0 p-1 col-12 col-md-5 ${styles.flexEnd}`}>
                
                <button className={styles.addGuestButton} onClick={() => setAddGuestModal(true)}>
                  Add Guest
                </button>

                <div className={`${styles.dropdown}`} onClick={()=>setActive(!dropActive)} ref={dropWrapper}>
                  <div className={`${styles.dropButtonContainer}`} >
                    <div className={styles.dropButton}>
                      <div className={styles.dropButtonName}>
                        <span>Download</span>
                      </div>
                      <div className={styles.dropIcon}>
                        <i className={`fa fa-caret-down`}/>
                      </div>
                    </div>
                  </div>
                  <div className={dropActive ? styles.dropdownContentActive : styles.dropdownContent}>
                    <a href="" onClick={(e)=>handleDownload(e,"csv")}>Download CSV</a>
                    <a href="" onClick={(e)=>handleDownload(e,"xlsx")}>Export Excel</a>
                  </div>
                </div>

              </div>
            </div>
            <ListGuest search={search} guests={filteredGuests} onDelete={handleDelete} onEdit={handleEdit}/>
          </>
      )}
    </>
  );
}

export default Guests;

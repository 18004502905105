import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import WeeklyRate from './components/AddEditRate/WeeklyRate.jsx';
import MonthlyRate from './components/AddEditRate/MonthlyRate.jsx';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";
import styles from '../../rates.module.css';

const Special = () => {

  const [weeklyRateModal, setWeeklyRateModal] = useState(false);
  const [monthlyRateModal, setMonthlyRateModal] = useState(false);
  const [editRateValues, setEditRateValues] = useState(null);
  const [editFeeValues, setEditFeeValues] = useState([]);
  const [editTaxValues, setEditTaxValues] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [currentRentalId, setCurrentRentalId] = useState(0);

  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
  const rateList = useSelector(({ rates }) => rates && rates.rateSettings);

  const weeklyFixedRateData = useSelector(({ rates }) => rates && rates.weeklyRate);
  const weeklyFixedFeeData = useSelector(({ rates }) => rates && rates.weeklyFee);
  const weeklyFixedTaxData = useSelector(({ rates }) => rates && rates.weeklyTax);
  const monthlyFixedRateData = useSelector(({ rates }) => rates && rates.monthlyRate);
  const monthlyFixedFeeData = useSelector(({ rates }) => rates && rates.monthlyFee);
  const monthlyFixedTaxData = useSelector(({ rates }) => rates && rates.monthlyTax);

  const rentalName = (id) => {
    const tmp = rentals.find((rental) => rental.id === Number(id));
    if (tmp) {
      return tmp.name;
    } else {
      return '';
    }
  };

  const findCurrency = (id) => {
    const tmp = rentals.find((rental) => rental.id === Number(id));
    if (tmp) {
      return tmp.currency;
    } else {
      return '';
    }
  };

  const handleCloseModal = (setClose) => {
    setEditRateValues(null);
    setEditFeeValues([]);
    setEditTaxValues([]);
    setClose(false);
    setEditMode(false);
    setCurrentRentalId(0);
  };
  
  const handleEditWeeklyRate = (rentalId, rateData, feeData, taxData) => {
    setCurrentRentalId(rentalId);
    setEditRateValues(rateData);
    setEditFeeValues(feeData);
    setEditTaxValues(taxData);
    setEditMode(true);
    setWeeklyRateModal(true);
  };
  
  const handleEditMonthlyRate = (rentalId, rateData, feeData, taxData) => {
    setCurrentRentalId(rentalId);
    setEditRateValues(rateData);
    setEditFeeValues(feeData);
    setEditTaxValues(taxData);
    setEditMode(true);
    setMonthlyRateModal(true);
  };

  return (
    <div>
      <WeeklyRate
        show={weeklyRateModal}
        onHide={() => handleCloseModal(setWeeklyRateModal)}
        editMode={editMode}
        rateData={editRateValues}
        feeData={editFeeValues}
        taxData={editTaxValues}
        currentRentalId={currentRentalId}
      />
      <MonthlyRate
        show={monthlyRateModal}
        onHide={() => handleCloseModal(setMonthlyRateModal)}
        editMode={editMode}
        rateData={editRateValues}
        feeData={editFeeValues}
        taxData={editTaxValues}
        currentRentalId={currentRentalId}
      />

      <div className={styles.toolTipIconContainer} style={{ display: 'flex', alignItems: 'center' }}>
        <label htmlFor="capBookingWindow" style={{ whiteSpace: 'nowrap', marginRight: '5px', marginTop: '20px' }}>
          Fixed-term rates, fees, and taxes
        </label>
        <InfoOutlinedIcon
          fontSize="small"
          color="inherit"
          data-tip
          data-for="stripIcon"
          className={styles.toolTipIcon}
        />
        <ReactTooltip place="bottom" type="dark" id="stripIcon" effect="solid" className={styles.toolTip}>
        <span>
          <p>
              Fixed-term rates are used for reservations you add with the button “Add Fixed” on the calendar. You can
              set up to 5 fees and 5 taxes that can be used with this rate.
          </p>
          <p>
              Remember that fees are taxable (if you have percentage taxes), but percentage taxes do not calculate on top of each other.
          </p>
          <p>
              Fixed-term rates are optimal for longer stays where you want to charge a fixed rent for a week or month.
          </p>
        </span>
        </ReactTooltip>
      </div>

      <div className="py-3" style={{ marginTop: '-10px' }}>
        <button
          className={styles.ratelistBtn}
          onClick={() => setWeeklyRateModal(true)}
        >
          Add weekly rate
        </button>
        <button
          className={styles.ratelistBtn}
          onClick={() => setMonthlyRateModal(true)}
        >
          Add monthly rate
        </button>
      </div>
      <div className={`col-sm-8 col-lg-6 col-xl-6 col-xs-12 p-0`}>
      {rateList.length ? (
          rateList.map((data, index) => {
            const rentalId = data.rentalId;

            // Weekly
            const weeklyRatesForRentalId = weeklyFixedRateData.find(item => item?.rentalId === rentalId);
            const weeklyFeesForRentalId = weeklyFixedFeeData.filter(item => item?.rentalId === rentalId);
            const weeklyTaxesForRentalId = weeklyFixedTaxData.filter(item => item?.rentalId === rentalId);
        
            // Monthly
            const monthlyRatesForRentalId = monthlyFixedRateData.find(item => item?.rentalId === rentalId);
            const monthlyFeesForRentalId = monthlyFixedFeeData.filter(item => item?.rentalId === rentalId);
            const monthlyTaxesForRentalId  = monthlyFixedTaxData.filter(item => item?.rentalId === rentalId);

            return (
              <Table responsive hover className={styles.ratelistTable} key={index}>
                <thead>
                  <tr>
                    <th colSpan={3}>
                      <div className={styles.rentalName}>{rentalName(data.rentalId)}</div>
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={1} className={styles.tableSubTitle}>Weekly rent</td>
                    <td colSpan={2}>
                      <span
                        className={styles.icon}
                        onClick={() => handleEditWeeklyRate(rentalId, weeklyRatesForRentalId, weeklyFeesForRentalId, weeklyTaxesForRentalId)}
                      >
                        <i className={`${styles.editIcon} fa fa-pencil-square-o`}/>
                      </span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={`${styles.tableItemLabel} ${styles.tableWidth35}`} colSpan={1}>
                      Weekly rate:
                      {/* <p className={styles.tableItemLabel}>Weekly rate:</p> */}
                    </td>
                    <td className={styles.tableWidth35} colSpan={1}>
                      {`${weeklyRatesForRentalId?.rate || 0} ${findCurrency(data.rentalId)}`}
                    </td>
                    <td style={{ backgroundColor: '#F3F6F5' }}></td>
                  </tr>
                  <tr>
                    <td className={`${styles.tableItemLabel} ${styles.tableWidth35}`}>
                      Weekly extra night:
                      {/* <p className={styles.tableItemLabel}>Weekly extra night:</p> */}
                    </td>
                    <td className={styles.tableWidth35}>
                      {`${weeklyRatesForRentalId?.extraRateNight || 0} ${findCurrency(data.rentalId)}`}
                    </td>
                    <td style={{ backgroundColor: '#F3F6F5' }}></td>
                  </tr>
                    {weeklyFeesForRentalId.length > 0 && (
                      weeklyFeesForRentalId.map((fee, feeIndex) => (
                          <tr key={feeIndex}>
                            <td className={`${styles.tableItemLabel} ${styles.tableWidth35}`}>Weekly fee {feeIndex + 1}:</td>
                            <td className={styles.tableWidth35}>
                              {fee.amount} {fee.feeType === 'percentage' ? '%' : findCurrency(rentalId)}, {fee.modality}
                            </td>
                            <td className={styles.tableWidth35}>{fee.name}</td>
                          </tr>
                        ))
                    )}
                    {weeklyTaxesForRentalId.length > 0 && (
                      weeklyTaxesForRentalId.map((tax, taxIndex) => (
                        <tr key={taxIndex}>
                          <td className={`${styles.tableItemLabel} ${styles.tableWidth35}`}>Weekly tax {taxIndex + 1}:</td>
                          <td className={styles.tableWidth35}>
                            {tax.amount} {tax.taxType === 'percentage' ? '%' : findCurrency(rentalId)}, {tax.modality}
                          </td>
                          <td className={styles.tableWidth35}>{tax.name}</td>
                        </tr>
                      ))
                    )}
                </tbody>
                <thead>
                  <tr>
                    <td colSpan={1} className={styles.tableSubTitle}>Monthly rent</td>
                    <td colSpan={2}>
                      <span
                        className={styles.icon}
                        onClick={() => handleEditMonthlyRate(rentalId, monthlyRatesForRentalId, monthlyFeesForRentalId, monthlyTaxesForRentalId)}
                      >
                        <i className={`${styles.editIcon} fa fa-pencil-square-o`}/>
                      </span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={`${styles.tableItemLabel} ${styles.tableWidth35}`} colSpan={1}>
                      Monthly rate:
                    </td>
                    <td className={styles.tableWidth35} colSpan={1}>
                    {`${monthlyRatesForRentalId?.rate || 0} ${findCurrency(data.rentalId)}`}
                    </td>
                    <td style={{ backgroundColor: '#F3F6F5' }}></td>
                  </tr>
                  <tr>
                    <td className={`${styles.tableItemLabel} ${styles.tableWidth35}`}>
                      Monthly extra night:
                    </td>
                    <td className={styles.tableWidth35}>
                      {`${monthlyRatesForRentalId?.extraRateNight || 0} ${findCurrency(data.rentalId)}`}
                    </td>
                    <td style={{ backgroundColor: '#F3F6F5' }}></td>
                  </tr>
                  {monthlyFeesForRentalId.length > 0 && (
                    monthlyFeesForRentalId.map((fee, feeIndex) => (
                        <tr key={feeIndex}>
                          <td className={`${styles.tableItemLabel} ${styles.tableWidth35}`}>Monthly fee {feeIndex + 1}:</td>
                          <td className={styles.tableWidth35}>
                            {fee.amount} {fee.feeType === 'percentage' ? '%' : findCurrency(rentalId)}, {fee.modality}
                          </td>
                          <td className={styles.tableWidth35}>{fee.name}</td>
                        </tr>
                      ))
                  )}
                  {monthlyTaxesForRentalId.length > 0 && (
                    monthlyTaxesForRentalId.map((tax, taxIndex) => (
                        <tr key={taxIndex}>
                          <td className={`${styles.tableItemLabel} ${styles.tableWidth35}`}>Monthly tax {taxIndex + 1}:</td>
                          <td className={styles.tableWidth35}>
                            {tax.amount} {tax.taxType === 'percentage' ? '%' : findCurrency(rentalId)}, {tax.modality}
                          </td>
                          <td className={styles.tableWidth35}>{tax.name}</td>
                        </tr>
                      ))
                  )}
                </tbody>
              </Table>
            );
          })
        ) : (
          <h1>No table to display</h1>
        )}
      </div>
    </div>
  );
};

export default Special;

import React from 'react';
import * as yup from 'yup';
import FeeForm from "./FeeForm";


const AddEditFee = (props) => {

    const {
        show,
        editValues,
        currency,
        feeList,
        setFeeList
    } = props;

    const validationSchema = yup.object({
        feeType: yup.string().required("Fee type is a required field"),
        name: yup.string().required("Name of fee is a required field"),
        amount: yup.number().required("Amount is a required field").min(0, 'Must be a number greater than 0'),
        modality: yup.string(),
    });

    const initialValues = {
        name: editValues?.name || '',
        amount: editValues?.amount || '',
        feeType: editValues?.feeType || 'flat rate',
        modality: editValues?.modality || 'per stay',
        feeApplyAdults: editValues?.feeApplyAdults || false,
        feeApplyChildren: editValues?.feeApplyChildren || false,
    };

    const handleSubmit = (data) => {

        const feeObject = {
            name: data.name,
            feeType: data.feeType,
            amount: Number(data.amount),
            modality: data.modality,
            // feeApplyAdults: Boolean(Number(data.feeApplyAdults)),
            // feeApplyChildren: Boolean(Number(data.feeApplyChildren)),
        };

        if (editValues) {
            const updateFeeList = feeList;
            updateFeeList[editValues.index] = feeObject;
            setFeeList(updateFeeList);
        } else {
            setFeeList([...feeList, feeObject]);
        };

        show(false);
    };

    return (
        <FeeForm
            showFee={(e) => show(e)}
            initialValues={initialValues}
            validationSchema={validationSchema}
            handleSubmit={handleSubmit}
            currency={currency}
        />
    )
};

export default AddEditFee;
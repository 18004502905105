import React, {useEffect, useState, useMemo} from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Row, Col } from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import * as yup from "yup";
import ReactTooltip from "react-tooltip";
import { saveSpecialBookingData } from "../../../../../../general_redux/calendar/actions"
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import iconPlus from "../../../../../../icons/icons8-plus.svg";
import styles from "./addspecialbooking.module.css";
import CustomDate from '../../../../../../components/CustomDate';
import { chargeCalculation,resetCharges } from '../../../../../../general_redux/calendar/actions';

const myTheme=createTheme({
    palette: {
        primary: { // works
            main: '#439a86',
            contrastText: '#fff',
        },
    },
});

function SpecialBookingForm(props){
    const dispatch = useDispatch();

    const {rentals, guestDetail, handleGuestError, handleSave, onHide} = props;
    const paymentOpt = ["Payment Pending","Partially Paid","Paid"];
    const currentCharges=useSelector(({calendar})=>calendar && calendar.currentCharges); 
    const colorDictionary = useSelector(({ generalSetting }) => {
        const colorsSetting = generalSetting && generalSetting.setting.colors;
        const map = [];
        for (const key in colorsSetting) {
            if (key.startsWith('color')) {
                map.push(colorsSetting[key]);
            }
        };
        return map;
    });
    const {numberOf, allowBookingFor} = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
    const rentalList = useSelector(({rentals}) => rentals && rentals.rentals);
    const tempSpecialBookingData = useSelector(({calendar}) => calendar && calendar.tempSpecialBookingData);
    
    const [price, setPrice] = useState(0);
    const [bookingType, setBookingType] = useState('weekly');
    const [rentalId, setRentalId] = useState(0);
    const [rentalDetail, setRentalDetail] = useState(0);
    const [adult,setAdult] = useState(1);
    const [children,setChildren] = useState(0);
    const [babies,setBabies] = useState(0);
    const [totalGuests, setTotalGuests] = useState(adult+children);
    const [specialBookingData, setSpecialBookingData] = useState(null);
    const [bookingDate, setBookingDate] = useState([""]);

    const currency = useMemo(()=>{
        return rentalDetail ? rentalDetail.currency : "USD";
    },[rentalId]);

    const longTermSchema=yup.object().shape({
        bookingType: yup.string().required('Select Type is required'),
        rental: yup.string().required('Rental is required'),
        totalGuest: yup.number(),
        adult: yup.number().min(1, 'At least 1 adult is required').required('At least 1 adult is required'),
        children: yup.number().min(0, 'Cannot be a negative number'),
        babies: yup.number().min(0, 'Cannot be a negative number'),
        bookingDate: yup.array().min(1, "At least 1 start date is required").of(yup.string().trim().required("Start date is required")),
    });

    const initVal = () => {
        return {
            bookingType: bookingType,
            rental:'',
            totalGuests:1,
            adult: 1,
            children: 0,
            babies: 0,
            price: 1,
            paymentStatus: 'Payment Pending',
            bookingDate: [""],
            color: '#D3D3D3'
        };
    };

    useEffect(() => {
        if(currentCharges){
            setPrice(currentCharges.total)
        }
    }, [currentCharges]);

    useEffect(() => {
        //calculate the charges
        if(bookingDate.length >0 && bookingDate[0]!==""){
            let departDate=moment(bookingDate[0],"YYYY-MM-DD").add(bookingType==="weekly" ? 7 : 1, bookingType==="weekly" ? 'days' : 'M').format('YYYY-MM-DD')
            const chargePayload = {
                arrive:moment(bookingDate[0]).format('YYYY-MM-DD'),
                depart:departDate,
                noOfAdults: adult,
                noOfChildren:children,
                rentalId:rentalId,
                extraCharge:0,
                discount:0,
                bookingType:bookingType
            };
            dispatch(chargeCalculation(chargePayload));
        }
    },[totalGuests, children, bookingDate??bookingDate[0], rentalId, bookingType ])

    useEffect(()=>{
        const tmp=Number(totalGuests)-Number(children);
        if(tmp<1){
            setAdult(1);
            setChildren(0);
        }else{
            setAdult(tmp);
        }
    },[totalGuests,children])
    
    useEffect(() => {
        dispatch(saveSpecialBookingData(specialBookingData));
    }, [specialBookingData])

    useEffect(() => {
        //cleanup function
        return () => {
            dispatch(resetCharges());
        };
    },[])

    const validate = (field,value) => {
        let errorMessage = "";
        if (field === "totalGuests") {
          if (!totalGuests || totalGuests <= 0) {
            errorMessage = 'At least 1 guest is required';
          } else if (totalGuests > rentalDetail.maxOccup) {
            errorMessage = `Maximum ${rentalDetail.maxOccup} guests are allowed`;
          }
        } else if (field === "adult" && adult < 1) {
          return `At least 1 adult is required`;
        } else if (field === "children") {
          if(children < 0){
            return `Cannot be a negative number`;
          };
          if(children > rentalDetail.maxChild){
            return `Maximum ${rentalDetail.maxChild} children are allowed`;
          };
        } else if(field === "babies" && babies < 0){
            return `Cannot be a negative number`;
        }
        return errorMessage;
      };
    
    const getMinDate = (index, bookingDates, bookingType) => {
        return index > 0 ? new Date(`${moment(bookingDates[index-1]).add(bookingType === "weekly" ? 7 : 1, bookingType === "weekly" ? 'd' : 'M').format('YYYY-MM-DD')}`) : new Date("")
    }

    const getMaxDate = (index, bookingDates, bookingType) => {
        return (bookingDates?.[index+1]) ?
            new Date(`${moment(bookingDates?.[index+1]).subtract(bookingType === "weekly" ? 7 : 1,bookingType === "weekly" ? 'd' : 'M').format('YYYY-MM-DD')}`) :
            new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)
    }

    const validateGuest = () => {
        if(!guestDetail || guestDetail === ""){
            handleGuestError("Guest is required")
            return false;
        }
    }

    const handleSubmit = (values) => {
        let guestData={};
        const rentalData = rentalList.find((rental) => rental.id === Number(values.rental));
 
        const payload=[];
        bookingDate.map((item) => {
            let departDate=moment(item,"YYYY-MM-DD").add(values.bookingType==="weekly" ? 7 : 1, values.bookingType==="weekly" ? 'days' : 'M').format('YYYY-MM-DD')
            payload.push({
                rentalId: Number(values.rental),
                price: Number(price),
                noOfAdults: values.adult,
                arrive:moment(item).utc(true).format('YYYY-MM-DD'),
                depart:moment(departDate).utc(true).format('YYYY-MM-DD'),
                arriveUTC : moment(item).utc(true).format('YYYY-MM-DD HH:mm:ss'),
                departUTC : moment(departDate).utc(true).format('YYYY-MM-DD HH:mm:ss'),
                noOfChildren: values.children || 0,
                noOfBabies: values.babies || 0,
                source: "Beehaz",
                checkInTime: rentalData?.checkinTime ?? "",
                checkOutTime: rentalData?.checkoutTime ?? "",
                bookingType: values.bookingType,
                status:"Booked",
                noOfGuests: values.totalGuests,
                paymentStatus: values.paymentStatus,
                title : `${guestDetail.label} | ${values.paymentStatus} | Direct Booking`,
                nights:moment(departDate,"YYYY-MM-DD").diff(moment(item,"YYYY-MM-DD"),'day'),
                color: values.color,
                discountName: "Discount",
                discountAmount:0,
                chargeName:"Extra charge",
                chargeAmount:0,
                fees: 0,
                tax: 0,
                extraGuestFee: 0,
                baseRate: Number(price),
                miniNotes: "",
                notes: ""
            })
        })
        guestData={
            guestId:guestDetail.value
        }
        payload.map((payload) => {
            handleSave(payload,guestData,false);
        });
    }

    return (
        <MuiThemeProvider theme={myTheme}>
            <Formik
                initialValues={tempSpecialBookingData || initVal()}
                validationSchema={longTermSchema}
                onSubmit={(data) => {
                    if(guestDetail && guestDetail !== ""){
                        handleSubmit(data)
                        onHide();
                    }
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={(values) => validateGuest(values)}
            >
                {({ values, setFieldValue, handleChange,errors }) => (
                    <Form>
                        <Row>
                            <Col>
                                <div className={`d-flex ${styles.selectTypeRow}`}>
                                    <div className={`${styles.form_title} ${styles.typeTitle}`}>Select Type:</div>
                                    <div className={styles.customWidth}>
                                        <div className="d-flex" >
                                            <div className="d-flex mx-3">
                                                <Checkbox
                                                    name="bookingType"
                                                    color="primary"
                                                    style={myTheme.palette.primary}
                                                    size={"small"}
                                                    className={styles.term_select}
                                                    onChange={() => {setFieldValue("bookingType","monthly"); setFieldValue("bookingDate",['']); setBookingDate([""]); setBookingType('monthly'); setPrice(0);}}
                                                    value="monthly"
                                                    checked={values.bookingType === "monthly"}
                                                />
                                                <div>Monthly</div>
                                            </div>
                                            <div className="d-flex ml-1">
                                                <Checkbox
                                                    name="bookingType"
                                                    color="primary"
                                                    style={myTheme.palette.primary}
                                                    size={"small"}
                                                    className={styles.term_select}
                                                    onChange={() => {setFieldValue("bookingType","weekly"); setFieldValue("bookingDate",['']); setBookingDate([""]); setBookingType('weekly');}}
                                                    value="weekly"
                                                    checked={values.bookingType === "weekly"}
                                                />
                                                <div className={styles.form_date}>Weekly</div>
                                            </div>
                                        </div>
                                        {errors.bookingType && (
                                            <div className={`ml-3 ${styles.form_error}`}>
                                                {errors.bookingType}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="pl-0 pl-sm-3 mt-2">
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Select rental*:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                as={"select"}
                                                name={"rental"}
                                                className = {(errors.rental)?styles.form_entry_err:styles.form_entry}
                                                onChange={(e)=> {
                                                    const selectedRental = rentals.find((rental) => Number(rental.id) === Number(e.target.value));
                                                    setFieldValue('rental', selectedRental.id);
                                                    setRentalId(selectedRental.id);
                                                    setRentalDetail(selectedRental);
                                                }}
                                            >
                                                <option value={""} label={"---Select Rental---"}/>
                                                {rentals.map((opt)=>(
                                                    <option key={opt.id} value={opt.id}>{opt.name}</option>
                                                ))}
                                            </Field>
                                            {errors.rental && (
                                                <div className={styles.form_error}>
                                                    {errors.rental}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Number of guests:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                type={"number"}
                                                name={"totalGuests"}
                                                className = {(errors.totalGuests)?styles.form_entry_err:styles.form_entry}
                                                value={totalGuests}
                                                onChange={(e)=> {
                                                    const guest = e.target.value;
                                                    setTotalGuests(guest);
                                                    setAdult(guest-children);
                                                }}
                                                validate={(value)=>validate('totalGuests',value)}
                                                min={1}
                                            />
                                            {errors.totalGuests && (
                                                <div className={styles.form_error}>
                                                    {errors.totalGuests}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Adults:</div>
                                    <div className={styles.customWidth}>
                                        <Field
                                            type={"number"}
                                            name={"adult"}
                                            value={adult}
                                            disabled={true}
                                            className={errors.adult ? styles.form_entry_err : styles.form_entry}
                                            validate={(value)=>validate('adult',value)}
                                            min={1}
                                        />
                                        {errors.adult && (
                                            <div className={styles.form_error}>
                                                {errors.adult}
                                            </div>
                                        )}
                                    </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Children:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                type={"number"}
                                                name={"children"}
                                                value={children}
                                                onChange={(e)=> {
                                                    const child = e.target.value;
                                                    setChildren(child);
                                                    setAdult(totalGuests-child);
                                                }}
                                                className={errors.children ? styles.form_entry_err : styles.form_entry}
                                                validate={(value)=>validate('children',value)}
                                                min={0}
                                            />
                                            {errors.children && (
                                                <div className={styles.form_error}>
                                                    {errors.children}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Babies:
                                            <InfoOutlinedIcon
                                                fontSize="small"
                                                color="inherit"
                                                data-tip
                                                data-for="babies"
                                                className={styles.toolTipIcon}
                                            />
                                            <ReactTooltip place="bottom" type="dark" id="babies" effect="solid" className={styles.toolTip}>
                                                <span>
                                                    Babies are not counted in the number of total guests (which consists of the total number of adults + the total number of children). This means they do not have any effect on the price calculation (while the number of adults and children influence the price when you have per person fees in your pricing).
                                                </span>
                                            </ReactTooltip>
                                        </div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                type={"number"}
                                                name={"babies"}
                                                value={babies}
                                                onChange={(e)=>setBabies(e.target.value)}
                                                className={styles.form_entry}
                                                min={0}
                                            />
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Price per period:
                                            <InfoOutlinedIcon
                                                fontSize="small"
                                                color="inherit"
                                                data-tip
                                                data-for="price"
                                                className={styles.toolTipIcon}
                                            />
                                            <ReactTooltip place="bottom" type="dark" id="price" effect="solid" className={styles.toolTip}>
                                                <span>
                                                    The price is calculated according to the input under the ‘Fixed Rates’ tab inside the ‘Rates’ menu. If you choose to book multiple months/weeks), the price will be calculated for each period alone (per month or per week respectively).
                                                </span>
                                            </ReactTooltip>
                                        </div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                type={"text"}
                                                name={"price"}
                                                value={`${price} ${currency}`}
                                                disabled={true}
                                                className={styles.form_entry}
                                            />
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Payment status:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                as={"select"}
                                                name={"paymentStatus"}
                                                className={styles.form_entry}
                                            >
                                                <option key={""} value={""} disabled={true} label={"--Select Status--"}/>
                                                {paymentOpt.map((opt)=>(
                                                    <option value={opt} key={opt}>{opt}</option>
                                                ))}
                                            </Field>
                                        </div>
                                    </div>
                                    <FieldArray name={"bookingDate"}>
                                        {({push, remove}) => (
                                            <>
                                            {bookingDate.map((item, index) => (
                                                <div className={`d-flex ${styles.form_wrapper} ${styles.date_main_wrapper}`} key={`long-${index}`}>
                                                    <div className={styles.dateWrap}>
                                                        <div className={styles.form_title}>Start date*:</div>
                                                        <div className={`${styles.customWidth} ${values.bookingDate.length > 1 && styles.dateWidth}`}>
                                                            <CustomDate
                                                                id={"fsDate"}
                                                                name={`bookingDate-${index}`}
                                                                hasError = {(errors.bookingDate?.[index])}
                                                                min={getMinDate(index, bookingDate, values.bookingType)}
                                                                max={getMaxDate(index, bookingDate, values.bookingType)}
                                                                value={item?? ""}
                                                                handleChange={(date)=>{
                                                                    const newBookingDate = [...bookingDate];
                                                                    newBookingDate[index] = date;
                                                                    setBookingDate(newBookingDate);
                                                                    setFieldValue(`bookingDate[${index}]`, (date ? date : ''));
                                                                }}
                                                            />
                                                            {errors.bookingDate?.length && errors.bookingDate[index] && (
                                                                <div className={styles.form_error}>
                                                                    {errors.bookingDate[index]}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {bookingDate.length > 1 && (
                                                        <span className={styles.delDateIcon}>
                                                            <i className={`fa fa-trash ${index === 0 && 'd-none'}`} onClick={() => {
                                                                remove(index);
                                                                setBookingDate(bookingDate.filter((date,idx) => idx!==index))
                                                            }}/>
                                                        </span>
                                                    )}
                                                </div>
                                            ))}
                                            <div className={`d-flex ${styles.addDateCol}`}>
                                                <img
                                                    src={iconPlus}
                                                    alt={"addStartDate"}
                                                    width={"22px"}
                                                    height={"22px"}
                                                    className={`mr-2 ${styles.addDateIcon} ${bookingDate.length >= 12 && styles.addDateDisabled}`}
                                                    onClick={() => {
                                                        const lastDate = bookingDate.slice(-1)[0];
                                                        if (lastDate) {
                                                            const cloneLastDate = new Date(lastDate);
                                                            cloneLastDate.setDate(cloneLastDate.getDate() + (values.bookingType === 'weekly' ? 7 : 30));
                                                            cloneLastDate && bookingDate.length < 12 && setBookingDate((prevArray) => [...prevArray, cloneLastDate]) && push(cloneLastDate);
                                                        } else {
                                                            bookingDate.length < 12 && setBookingDate((prevArray) => [...prevArray, ""]) && push("");
                                                        };
                                                    }}
                                                />
                                                <div className={`${styles.addDateTitle} ${values.bookingDate.length >= 12 && styles.addDateDisabled}`}>add start date
                                                    <InfoOutlinedIcon
                                                        fontSize="small"
                                                        color="inherit"
                                                        data-tip
                                                        data-for="addDate"
                                                        className={styles.toolTipIcon}
                                                    />
                                                    <ReactTooltip place="bottom" type="dark" id="addDate" effect="solid" className={styles.toolTip}>
                                                        <span>
                                                            You can add up to 12 start dates. These fixed-term period will be treated as separate bookings on the calendar.
                                                        </span>
                                                    </ReactTooltip>
                                                </div>
                                            </div>
                                            </>
                                        )}
                                    </FieldArray>

                                    <div className={`${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Choose colour:</div>
                                        <div>
                                            {colorDictionary.map((item, index)=>(
                                                <div
                                                    className={`${styles.containerBox}`}
                                                    style={{background:`${item}`}}
                                                    onClick={()=>setFieldValue("color", item)}
                                                    key={index}
                                                />
                                            ))}
                                            <Field
                                                type={'color'}
                                                name={'color'}
                                                value={values.color}
                                                onChange={handleChange}
                                                className={styles.form_entry_color}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <button type="submit" className={styles.settings_save}>
                                        {'Save changes'}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        {setSpecialBookingData(values)}
                    </Form>
                )}
            </Formik>
        </MuiThemeProvider>
    )
};

export default SpecialBookingForm;
import React, {useState, useEffect, useRef} from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import styles from '../editBooking.module.css';
import moment from 'moment';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {useSelector,useDispatch} from "react-redux";
import {
  editBooking,
  chargeCalculation,
  fetchBookingById,
  resetCharges,
  fetchDiscountByBooking,
  editDiscount,
  clearBooking
} from "../../../../../../../general_redux/calendar/actions";
import { Row, Col } from 'react-bootstrap';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";
import Loading from "../../../../../../../components/loading";
import {toast} from "react-toastify";
import {editGuest} from "../../../../../../../general_redux/guest/actions";
import CustomDate from "../../../../../../../components/CustomDate";
import {zeroPad} from '../../../../../../../common/functions/utils';


const BookingDetails = ({bookingId, rentals, setViewEditBooking}) => {
  
  const dateObj = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const date = new Date(year, month - 1, day);
    return date;
  };

  const rentalList= useSelector(({rentals})=>rentals && rentals.rentals);

  const findRentalDetail = (rentalId) => {
    const findRental = rentalList.find((rental) => Number(rental.id) === Number(rentalId));
    return findRental || { id: 0, maxOccup: 1, maxChild: 0, checkinTime: '', checkoutTime: '' };
  };

  const firstUpdate = useRef(true);
  const bookingData= useSelector(({calendar}) => calendar && calendar.bookingById);
  const guestDetails = useSelector(({guests})=>guests && guests?.guestByBooking[0]);
  const bookingDiscount=useSelector(({calendar})=>calendar && calendar.bookingDiscount);
  const [moreInfo,setMoreInfo]=useState(guestDetails && guestDetails.notes);
  const [miniNotes,setMiniNotes]=useState(bookingData && bookingData.miniNotes);
  
  const [rentalDetail, setRentalDetail] = useState(findRentalDetail(bookingData.rentalId));
  const currentCharges=useSelector(({calendar})=>calendar && calendar.currentCharges);
  const actionForFetchBooking=useSelector(({calendar})=>calendar && calendar.actionForFetchBooking);
  const {numberOf, allowBookingFor} = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
  const [rental,setRental]=useState(bookingData.rentalId);
  const [source,setSource]=useState(bookingData.source);
  const [color,setColor]=useState(bookingData.color);
  const [paymentStatus,setPaymentStatus]=useState(bookingData.paymentStatus);
  const [notes,setNotes]=useState(bookingData.notes);
  const [children,setChildren]=useState(bookingData.noOfChildren);
  const [babies,setBabies]=useState(bookingData.noOfBabies);
  const [adult,setAdult]=useState(bookingData.noOfAdults);
  const [totalGuest,setTotalGuest]=useState(bookingData.noOfAdults);
  const [arrival,setArrival]=useState((bookingData && bookingData.arrive)? dateObj(bookingData.arrive) : "");
  const [departure,setDeparture]=useState((bookingData && bookingData.depart) ? dateObj(bookingData.depart) : "");
  const [finalPrice,setFinalPrice]=useState(bookingData && bookingData.price);
  const [totalNights,setTotalNights]=useState(bookingData?moment(bookingData.depart).diff(moment(bookingData.arrive),'days'):"")
  const [checkIn, setCheckIn] = useState(bookingData?.checkInTime ? bookingData.checkInTime : rentalDetail.checkinTime);
  const [checkOut, setCheckOut] = useState(bookingData?.checkOutTime ? bookingData.checkOutTime : rentalDetail.checkoutTime);

  const setData=(guest)=>{
    setTotalGuest(guest);
    setAdult(guest-children);
  };

  const getChannelOptions = (channels) => {
    const channelOptions = channels.map((item) => {
      const ChannelNameObject = getChannelName(item.channel);
      return {label: ChannelNameObject.label, value: ChannelNameObject.value};
    });
    return [{label: 'Beehaz', value: 'Beehaz'}, ...channelOptions];
  }; 

  const getChannelName = (channel) => {
    if (channel.ota === 'Other')
        return {label: `${channel.name} (iCal)`, value: channel.name}
    if (channel.ota === 'VRBO')
        return {label: 'VRBO (HomeAway) (iCal)', value: channel.ota}
    else
        return {label: `${channel.ota} (iCal)`, value: channel.ota}
  };

  const validate=(val,name)=>{
    if(name==="totalGuests") {
      if(!totalGuest || totalGuest <= 0){
        return 'At least 1 guest is required';
      } else if (totalGuest > rentalDetail.maxOccup) {
        return `Maximum ${rentalDetail.maxOccup} guests are allowed`;
      }
    }
    if(name==="adult" && adult<1){
      return `At least 1 adult is required`;
    }
    if(name==="children"){
      if(children < 0){
        return `Cannot be a negative number`;
      };
      if(children > rentalDetail.maxChild){
        return `Maximum ${rentalDetail.maxChild} children are allowed`;
      };
    }
    if(name==="babies" && babies<0){
      return `Cannot be a negative number`;
    }
    else{
      return "";
    }
  };

  const channelBookingChangesWarning = () => {
    if (bookingData.bookingType === 'channelBooking') {
      toast.warn(`
        This is a channel reservation. Changes are not recommended.
        Making changes in Beehaz shall not modify the reservation at the source and may lead to calendar errors.
        Change this reservation on the channel from which it derives.
      `)
    }
  };

  const ota = (() => {
    const source = bookingData?.source;
    const OTAs = ['AIRBNB','BEEHAZ','AGODA','BOKKINGCOM','VRBO','OTHERS'];
    const ota = source && OTAs.find(item => item === source.toUpperCase());

    if (!ota) {
        return '';
    };
    if (ota === 'BOKKINGCOM') { 
        return 'Booking.com';
    };
    if (ota === 'VRBO') { 
        return 'VRBO';
    };
    return ota.charAt(0).toUpperCase() + ota.slice(1).toLowerCase();

  })();

  const guestName = guestDetails ? guestDetails.name : bookingData?.guestName;

  const paymentOpt = ['Payment Pending', 'Partially Paid', 'Paid'];
  const dispatch=useDispatch();

  useEffect(()=>{
    dispatch(fetchBookingById(bookingId));
    dispatch(fetchDiscountByBooking(bookingId));
  },[])

  useEffect(()=>{
    return()=>{
      dispatch(resetCharges());
      dispatch(clearBooking());
    }
  },[])

  useEffect(()=>{
    if(actionForFetchBooking.success && bookingData){
      setRental(bookingData.rentalId)
      setPaymentStatus(bookingData.paymentStatus);
      setColor(bookingData.color);
      setAdult(bookingData.noOfAdults);
      setChildren(bookingData.noOfChildren);
      setBabies(bookingData.noOfBabies);
      setArrival(bookingData?.arrive ? dateObj(bookingData.arrive) : "");
      setDeparture(bookingData?.depart ? dateObj(bookingData.depart) : "");
      setTotalGuest(bookingData.noOfAdults + bookingData.noOfChildren);
      setTotalNights(bookingData?moment(bookingData.depart).diff(moment(bookingData.arrive),'days'):"")
      setSource(bookingData.source);
      setPaymentStatus(bookingData.paymentStatus);
      setMiniNotes(bookingData.miniNotes);
      setNotes(bookingData.notes);
      setCheckIn(bookingData.checkInTime);
      setCheckOut(bookingData.checkOutTime);
      setFinalPrice(bookingData.price);
    }
  },[bookingData])

  useEffect(()=>{
    if(arrival===undefined && departure===undefined && rental===undefined && totalGuest===undefined ){
      //do nothing
      return
    }
    else if (firstUpdate.current ){
      firstUpdate.current = false;
    }
    else if(!firstUpdate.current){
      if(bookingDiscount && bookingDiscount.chargeAmount!==undefined && bookingDiscount.discountAmount!==undefined && arrival && departure && rental && totalGuest){
        
        dispatch(chargeCalculation({
          arrive:moment(arrival).format().slice(0,10),
          depart:moment(departure).format().slice(0,10),
          noOfAdults: Number(adult),
          noOfChildren: Number(children),
          rentalId:Number(rental),
          extraCharge:Number(bookingDiscount.chargeAmount),
          discount:Number(bookingDiscount.discountAmount),
          bookingType: "booking"
        }))
        setTotalNights(moment(departure).diff(moment(arrival),'days'));
      }
    }
  },[arrival,departure,rental,totalGuest])

  useEffect(()=>{
    if(currentCharges ){
      
      if(currentCharges && !currentCharges.maxStay){
        toast.warn(`Maximum stay requirements set for this rental: ${currentCharges.maxStayConstrain} nights!!`
          , { style: { color: '#484848' }}
        );
      };
  
      if(currentCharges && !currentCharges.minStay){
        toast.warn(`Minimum stay requirements set for this rental: ${currentCharges.minStayConstrain} nights!!`
          , { style: { color: '#484848' }}
        );
      };
      
      setFinalPrice(currentCharges.total);
    }
  },[currentCharges])

  const updateSchema = yup.object().shape({
    adult: yup.number().when('isDisabled', {
      is: true,
      then: yup.number().min(1, 'At least 1 adult is required').required('At least 1 adult is required')
    }),
    children: yup.number().min(0, 'Cannot be a negative number'),
    babies: yup.number().min(0, 'Cannot be a negative number'),
    arrival: yup.date().required('Arrival date is required').nullable(),
    departure: yup.date().required('Departure date is required').nullable(),
    checkInTime: yup.string().required('Check-In is required'),
    checkOutTime: yup.string().required('Check-Out is required'),
  });

  const rentalSelector = rentals.map((row) => ({ id: row.id, name: row.name }));
  const channels = useSelector(({icalChannels}) => icalChannels && getChannelOptions(icalChannels.channels));

  const colorDictionary = useSelector(({ generalSetting }) => {
    const colorsSetting = generalSetting && generalSetting.setting.colors;
    const map = [];
    for (const key in colorsSetting) {
        if (key.startsWith('color')) {
            map.push(colorsSetting[key]);
        }
    };
    return map;
  });

  const handleSubmit = (data) => {
    const payload = {
      id:bookingData.id,
      rentalId: Number(rental),
      price: finalPrice,
      tax: 0,
      fees:0,
      extraGuestFee:0,
      baseRate:0,
      noOfAdults: adult,
      arrive: moment(arrival).utc(true).format('YYYY-MM-DD'),
      depart: moment(departure).utc(true).format('YYYY-MM-DD'),
      arriveUTC : moment(arrival).utc(true).format('YYYY-MM-DD HH:mm:ss'),
      departUTC : moment(departure).utc(true).format('YYYY-MM-DD HH:mm:ss'),
      noOfChildren: Number(children),
      noOfBabies: Number(babies),
      source: source,
      checkInTime:checkIn,
      checkOutTime:checkOut,
      bookingType:bookingData.bookingType,
      noOfGuests: Number(totalGuest),
      paymentStatus: paymentStatus,
      title : `${(bookingData.bookingType !== 'channelBooking' && guestName) || ((ota && guestName) ? `${ota} ${guestName}` : `${ota} Guest`)} | ${paymentStatus} | Direct Booking`,
      nights: totalNights,
      color: color,
      status:"Booked",
      notes:notes,
      miniNotes:miniNotes,
      discountName:bookingDiscount.discountName,
      discountAmount:bookingDiscount.discountAmount,
      chargeAmount:bookingDiscount.chargeAmount,
      chargeName:bookingDiscount.chargeName,
    }

    if(moreInfo!==guestDetails?.notes){
      const updatedData = {
        address:guestDetails?.address ?? '',
        company:guestDetails?.company ?? '',
        country:guestDetails?.country,
        dob:guestDetails?.dob ?? '',
        emailId:guestDetails?.emailId,
        id:guestDetails?.id,
        language:guestDetails?.language ?? '',
        name:guestDetails?.name,
        nationality:guestDetails?.nationality ?? '',
        notes:moreInfo,
        phoneNo:guestDetails?.phoneNo,
        guestType: 'guest',
        secondaryPhoneNo:guestDetails?.secondaryPhoneNo ?? '',
        postalCode:guestDetails?.postalCode ?? '',
        secondaryEmailId: guestDetails?.secondaryEmailId  ?? '',
        state: guestDetails?.state ?? '',
        street: guestDetails?.street ?? '',
      };
      dispatch(editGuest(updatedData));
    }
    dispatch(editBooking(payload)).then(() => {
      const discountPayload = {
        bookingId: bookingId,
        rentalId: Number(rental),
        discountName: bookingDiscount.discountName,
        discountAmount: bookingDiscount.discountAmount,
        chargeName: bookingDiscount.chargeName,
        chargeAmount: bookingDiscount.chargeAmount,
        tax: currentCharges?.taxes,
        fees: currentCharges?.fees,
        extraGuestFee: currentCharges?.extra_guest_fees,
        baseRate: currentCharges?.daily_rate,
        totalPrice: currentCharges?.total,
        bookingPrice: currentCharges?.total,
        nights: totalNights,
        noOfGuests: adult + children
      }
      dispatch(editDiscount(discountPayload, bookingDiscount.id));
    });
    setViewEditBooking(false);
  };

  const initialValues = {
    rental:bookingData?.rentalId || '',
    totalGuests: (bookingData?.noOfAdults && bookingData?.noOfChildren) ? (bookingData.noOfAdults + bookingData.noOfChildren) : 1,
    adult: bookingData?.noOfAdults || 1,
    children: bookingData?.noOfChildren || 0,
    babies: bookingData?.noOfBabies || 0,
    finalPrice: bookingData?.price || 0.0,
    arrival: bookingData.arrive,
    departure: bookingData.depart,
    checkInTime: checkIn || '',
    checkOutTime: checkOut || '',
    nights:bookingData?.nights || 0,
    source: bookingData?.source || 'Beehaz',
    color: bookingData?.color || '',
    paymentStatus:bookingData?.paymentStatus || 'Payment Pending',
    title:`${(bookingData?.bookingType !== 'channelBooking' && guestName) || ((ota && guestName) ? `${ota} ${guestName}` : `${ota} Guest`)} | ${paymentStatus} | Direct Booking`,
    notes: `${bookingData?.notes ? bookingData.notes : ''}`
  };

  return (
      <div className="mt-3">
        <Loading loadingStatus={actionForFetchBooking.loading} />
        <Formik
            initialValues={initialValues}
            validationSchema={updateSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
          {({ errors, setFieldValue }) => (
              <>
                <Form>
                  <div className="col-12">
                    {['weekly', 'monthly'].includes(bookingData.bookingType) && (
                      <div className="d-flex align-content-center">
                        <div className={styles.warningIconContainer}>
                          <ChangeHistoryIcon className={styles.warningIconOne} />
                          <PriorityHighIcon className={styles.warningIconTwo} />
                        </div>
                        <div>
                          {`This is a fixed-term reservation. ${bookingData.bookingType === 'weekly' ? 'Weekly' : 'Monthly'} pricing is being used.`}
                          <InfoOutlinedIcon
                            fontSize="small"
                            color="inherit"
                            data-tip
                            data-for="fixedTerm"
                            className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="fixedTerm" effect="solid" className={styles.toolTip}>
                            <span>
                              <p>
                                Fixed-term reservations use special rate settings, which you might have set up differently than your nightly rates. If you make any changes to this reservation, the fixed nightly rate will be used to calculate the extra nights.
                              </p>
                              <p>
                                If you plan on changing the length of stay substantially in this fixed-term booking, consider the effect on your pricing. You can correct unintended effects by overwriting the price under the payment tab or making a standard nightly reservation instead.
                              </p>
                            </span>
                          </ReactTooltip>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.mainContainer}>
                    <div className={`col-lg-6 col-md-12 px-xs-1`}>
                      <div className="pb-2">
                        { bookingData.bookingType === 'channelBooking' ? 
                          <>
                            <div className={styles.subHeader}>{`${(ota && guestName) ? `${ota} ${guestName}` : `${ota} Guest`}: Reserved`}</div>
                            <div className={styles.defaultFont}>{guestDetails?.emailId}</div>
                            <div className={styles.defaultFont}>{bookingData?.reservationLink && `Reservation link: ${bookingData.reservationLink}`}</div>
                          </>
                        :
                          <>
                            <div className={styles.subHeader}>{guestDetails?.name}</div>
                            <div className={styles.defaultFont}>{guestDetails?.emailId}</div>
                            <div className={styles.defaultFont}>{guestDetails?.phoneNo}</div>
                          </>
                        }
                      </div>
                    </div>
                    <div className={`col-lg-6 col-md-12`}>
                      <div className="pb-2">
                        <div className="mb-3 mt-sm-3 mt-md-0">
                          <div className={`${styles.smallHeader} font-weight-normal`}>More info
                            <InfoOutlinedIcon
                                fontSize="small"
                                color="inherit"
                                data-tip
                                data-for="moreInfo"
                                className={styles.toolTipIcon}
                            />
                            <ReactTooltip place="bottom" type="dark" id="moreInfo" effect="solid" className={styles.toolTip}>
                          <span>
                            Write more information specific to your guests here. These can be retrieved later if the guest makes another reservation.
                          </span>
                            </ReactTooltip>
                          </div>
                          <Field
                              className={`form-control ${styles.form_entry} w-100`}
                              type={"text"}
                              name={"moreInfo"}
                              onChange={(e)=>setMoreInfo(e.target.value)}
                              value={moreInfo}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-1" />
                  <div className={styles.mainContainer}>
                    <div className={`col-lg-6 col-md-12 px-xs-1`}>
                      <div className="pb-2">
                        <div className={styles.subHeader}> Booking </div>
                        <div className={styles.defaultFont}>{`Booking Number: ${new Date().getUTCFullYear()}${zeroPad(bookingData.id,4)}`}</div>
                      </div>
                      <Row className={`${styles.form_wrapper} m-0`} key={'selectRental'}>
                        <div className={styles.form_title}>{'Select rental: '}</div>
                        <Field
                            as={'select'}
                            name={'rental'}
                            value={rental}
                            onChange={(e)=> {
                              const currentRental = findRentalDetail(e.target.value);
                              setRental(currentRental.id);
                              setCheckIn(currentRental.checkinTime);
                              setCheckOut(currentRental.checkoutTime);
                              setRentalDetail(currentRental);
                              channelBookingChangesWarning();
                            }}
                            className={errors.rental ? styles.form_entry_err : styles.form_entry}
                        >
                          {rentalSelector.map((opt) => (
                              <option key={opt.id} value={opt.id} >
                                {opt.name}
                              </option>
                          ))}
                        </Field>
                        <div className={errors.rental ? styles.form_error : styles.form_error_hidden}>{errors.rental}</div>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'totalGuest'}>
                        <div className={styles.form_title}>{'Number of guests: '}</div>
                        <div className={styles.field_width}>
                          <Field
                              type={'number'}
                              name={'totalGuests'}
                              className={errors.totalGuests ? styles.form_entry_err : styles.form_entry}
                              value={totalGuest}
                              onChange={(e)=>setData(e.target.value)}
                              validate={(val)=>validate(val,"totalGuests")}
                              min={1}
                          />
                          <div className={errors.totalGuests ? styles.form_error : styles.form_error_hidden}>
                            {errors.totalGuests}
                          </div>
                        </div>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'adult'}>
                        <div className={`${styles.form_title}`}>{'Adults: '}</div>
                        <div className={styles.field_width}>
                          <Field
                              type={'number'}
                              name={'adult'}
                              disabled={true}
                              className={
                                errors.adult ? styles.form_entry_err : `${styles.form_entry}`
                              }
                              value={adult}
                              validate={(val)=>validate(val,"adult")}
                              min={1}
                          />
                          <div className={errors.adult ? styles.form_error : styles.form_error_hidden}>
                            {errors.adult}
                          </div>
                        </div>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'children'}>
                        <div className={`${styles.form_title}`}>{'Children: '}</div>
                        <div className={styles.field_width}>
                          <Field
                              type={'number'}
                              name={'children'}
                              value={children}
                              onChange={(e)=> {
                                setChildren(e.target.value)
                                setAdult(totalGuest-e.target.value)
                              }}
                              className={
                                errors.children ? styles.form_entry_err : `${styles.form_entry}`
                              }
                              validate={(val)=>validate(val,"children")}
                              min={0}
                          />
                          <div className={errors.children ? styles.form_error : styles.form_error_hidden}>
                            {errors.children}
                          </div>
                        </div>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'babies'}>
                        <div className={`${styles.form_title}`}>{'Babies: '}
                          <InfoOutlinedIcon
                            fontSize="small"
                            color="inherit"
                            data-tip
                            data-for="babies"
                            className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="babies" effect="solid" className={styles.toolTip}>
                            <span>
                              Babies are not counted in the number of total guests (which consists of the total number of adults + the total number of children). This means they do not have any effect on the price calculation (while the number of adults and children influence the price when you have per person fees in your pricing).
                            </span>
                          </ReactTooltip>
                        </div>
                        <div className={styles.field_width}>
                          <Field
                              type={'number'}
                              name={'babies'}
                              value={babies}
                              onChange={e => setBabies(e.target.value)}
                              className={
                                errors.babies ? styles.form_entry_err : `${styles.form_entry}`
                              }
                              min={0}
                          />
                          <div className={errors.babies ? styles.form_error : styles.form_error_hidden}>
                            {errors.babies}
                          </div>
                        </div>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'arrival'}>
                      <Col className="p-sm-0 pl-0 col-7 d-sm-flex align-items-center">
                          <div className={`${styles.form_title_half} ${styles.checkTitleWidth}`}>Check-in: </div>
                          <div className={styles.calendarWidth}>
                            <CustomDate
                                name={'arrival'}
                                id={'editBookingArrival'}
                                value={arrival}
                                hasError={errors.arrival}
                                handleChange={(date)=>{
                                  channelBookingChangesWarning();
                                  if(bookingData.bookingType==="monthly" || bookingData.bookingType==="weekly"){
                                    toast.warn(`You are modifying a fixed-term reservation. Note the prices will be recalculated using this special rates and not your usual rates.`
                                    ,{
                                      style:{
                                          color:'#484848'
                                      }
                                    });
                                  }
                                  setArrival(date);
                                  setFieldValue('arrival',date);
                                }}
                                max={(departure)? departure: new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                            />
                            <div className={errors.arrival ? styles.form_error : styles.form_error_hidden}>
                              {errors.arrival}
                            </div>
                          </div>
                        </Col>
                        <Col className={`p-0 col-5 mr-0 d-sm-flex align-items-center justify-content-md-end justify-content-lg-center ${styles.checkWrap}`}>
                          <div className={`${styles.form_title_half} ${styles.atWidth}`}>from: </div>
                          <Field
                              type={'time'}
                              name={'checkInTime'}
                              onBlur={channelBookingChangesWarning}
                              value={checkIn}
                              onChange={(e)=>{
                                const value = e.target.value;
                                setCheckIn(value);
                                setFieldValue('checkInTime', value);
                              }}
                              className={errors.checkInTime ? `${styles.form_entry_half_err} ${styles.atEntryWidth}` : `${styles.form_entry_half} ${styles.atEntryWidth}`}
                          />
                        </Col>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'departure'}>
                        <Col className="p-sm-0 pl-0 col-7 d-sm-flex align-items-center">
                          <div className={`${styles.form_title_half} ${styles.checkTitleWidth}`}>Check-out: </div>
                          <div className={styles.calendarWidth}>
                            <CustomDate
                                name={'departure'}
                                id={'editBookingDeparture'}
                                min={arrival}
                                handleChange={(date)=>{
                                  channelBookingChangesWarning();
                                  if(bookingData.bookingType==="monthly" || bookingData.bookingType==="weekly"){
                                    toast.warn(`You are modifying a fixed-term reservation. Note the prices will be recalculated using this special rates and not your usual rates.`
                                    ,{
                                      style:{
                                          color:'#484848'
                                      }
                                    });
                                  }
                                  setDeparture(date);
                                  setFieldValue('departure',date);
                                }}
                                value={departure}
                                max={new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                            />
                            <div className={errors.departure ? styles.form_error : styles.form_error_hidden}>
                              {errors.departure}
                            </div>
                          </div>
                        </Col>
                        <Col className={`p-0 col-5 mr-0 d-sm-flex align-items-center justify-content-md-end justify-content-lg-center ${styles.checkWrap}`}>
                          <div className={`${styles.form_title_half} ${styles.atWidth}`}>until: </div>
                          <Field
                              type={'time'}
                              name={'checkOutTime'}
                              onBlur={channelBookingChangesWarning}
                              value={checkOut}
                              onChange={(e)=>{
                                const value = e.target.value;
                                setCheckOut(value);
                                setFieldValue('checkOutTime', value);
                              }}
                              className={errors.checkOutTime ? `${styles.form_entry_half_err} ${styles.atEntryWidth}` : `${styles.form_entry_half} ${styles.atEntryWidth}`}
                          />
                        </Col>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'nights'}>
                        <div className={styles.form_title}>Total nights: </div>
                        <Field
                            type={'text'}
                            name={'nights'}
                            value={totalNights}
                            disabled={true}
                            className={errors.nights ? styles.form_entry_err : styles.form_entry}
                        />
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'source'}>
                        <div className={styles.form_title}>{'Source: '}</div>
                        <Field
                            as={'select'}
                            name={'source'}
                            value={source}
                            onChange={(e)=>setSource(e.target.value)}
                            className={errors.source ? styles.form_entry_err : styles.form_entry}
                        >
                          {channels.map((opt, index) => (
                              <option key={index} value={opt.value} >
                                {opt.label}
                              </option>
                          ))}
                        </Field>
                      </Row>
                      <hr className="my-1" />
                      <div className="mb-2">
                        <div className={styles.subHeader}>Payment
                          <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="paymentIcon"
                              className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="paymentIcon" effect="solid" className={styles.toolTip}>
                          <span>
                            The total includes rates, extra guest fees, fees, and taxes. Any discount or charge you have set
                            are also considered here. You can change this total under the Payments tab.
                          </span>
                          </ReactTooltip>
                        </div>
                      </div>
                      <Row className={`${styles.form_wrapper} d-flex justify-content-between m-0`} key={'finalPrice'}>
                        <div className="d-flex align-items-center ">
                          <div className={`${styles.form_title_half} ${styles.paymentTitleWidth}`}>Total: </div>
                          <div className={styles.totalEntryWidth}>
                            {bookingData.bookingType === 'channelBooking' ?
                              '-'
                            :
                              <Field
                                  type={'number'}
                                  name={'finalPrice'}
                                  className={
                                    errors.finalPrice
                                        ? styles.form_entry_err
                                        : `${styles.form_entry_half} ${styles.totalEntryWidth}`
                                  }
                                  value={Number(finalPrice).toFixed(2)}
                                  disabled={true}
                                  min={0}
                              />
                            }
                            <div className={errors.finalPrice ? styles.form_error : styles.form_error_hidden}>
                              {errors.finalPrice}
                            </div>
                          </div>
                        </div>
                        <div className={`d-flex align-items-center mt-3 mt-md-0 ${styles.paymentStatus}`}>
                          <div className={`${styles.form_title_half} ${styles.paymentTitleWidth}`}>Status: </div>
                          <Field
                              as={'select'}
                              name={'paymentStatus'}
                              value={paymentStatus}
                              onChange={(e)=> {
                                setPaymentStatus(e.target.value);
                              }}
                              className={
                                errors.paymentStatus
                                    ? styles.form_entry_err
                                    : `${styles.form_entry_half} ${styles.statusEntryWidth}`
                              }
                          >
                            {paymentOpt.map((opt) => (
                                <option value={opt} key={opt}>
                                  {opt}
                                </option>
                            ))}
                          </Field>
                        </div>
                      </Row>
                    </div>
                    <div className={`col-lg-6 col-md-12`}>
                      <hr className="my-1 d-lg-none" />
                      <div>
                        <div className="mt-sm-3 mt-md-0">
                          <div className={`${styles.subHeader} font-weight-normal`}>Mini notes
                            <InfoOutlinedIcon
                                fontSize="small"
                                color="inherit"
                                data-tip
                                data-for="miniNotes"
                                className={styles.toolTipIcon}
                            />
                            <ReactTooltip place="bottom" type="dark" id="miniNotes" effect="solid" className={styles.toolTip}>
                          <span>
                            Write a short note to be displayed in the booking information summary box that appears when you click on a reservation in the calendar.
                          </span>
                            </ReactTooltip>
                          </div>
                        </div>
                        <Field
                            className={`form-control ${styles.form_entry} w-100`}
                            name="miniNotes"
                            type={"text"}
                            value={miniNotes}
                            onChange={(e)=>setMiniNotes(e.target.value)}
                        />
                      </div><br/>
                      <div>
                        <div className="mt-sm-3 mt-md-0">
                          <div className={styles.subHeader}>Notes
                            <InfoOutlinedIcon
                                fontSize="small"
                                color="inherit"
                                data-tip
                                data-for="notesIcon"
                                className={styles.toolTipIcon}
                            />
                            <ReactTooltip place="bottom" type="dark" id="notesIcon" effect="solid" className={styles.toolTip}>
                          <span>
                            Write longer notes specific to this booking here.
                          </span>
                            </ReactTooltip>
                          </div>
                        </div>
                        <textarea
                            className={`form-control ${styles.text_area} `}
                            rows="15"
                            name="notes"
                            onChange={(e)=>setNotes(e.target.value)}
                            value={notes}
                        />
                      </div>
                      <Row className={`${styles.colorSection}`} key={'colour'}>
                        <div className={`${styles.subHeader} ${styles.titleColor}`}>Colour</div>
                        <div className={styles.containerColor}>
                          {colorDictionary.map((item, index)=>(
                              <div
                                  className={`${styles.containerBox} ${(color===item)?` active1`:``}`}
                                  style={{background:`${item}`}}
                                  onClick={()=>setColor(item)}
                                  key={index}
                              />
                          ))}
                          <Field
                              type={'color'}
                              name={'color'}
                              value={color}
                              onChange={(e)=>setColor(e.target.value)}
                              className={styles.form_entry_color}
                          />
                        </div>
                      </Row>
                    </div>
                  </div>
                  <div className={`d-flex justify-content-center ${styles.button_group}`}>
                    <button 
                      type="submit" 
                      className={styles.settings_save} 
                    >
                      Save changes
                    </button>
                  </div>
                </Form>
              </>
          )}
        </Formik>
      </div>
  );
};

export default BookingDetails;

import React, { useEffect, useState } from 'react';
import {useSelector,useDispatch} from "react-redux";
import { Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import Select from "react-select";
import PhoneInput from 'react-phone-input-2';
import countryList from "react-select-country-list";
import {toast} from "react-toastify";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";
import {chargeCalculation,resetCharges} from '../../../../../../general_redux/calendar/actions';
import { findCountry } from '../../../../../../common/functions/utils';
import CustomDate from '../../../../../../components/CustomDate';
import styles from './addnewbooking.module.css';

function BookingForm(props) {
  const { guestList,  rentals,  handleSave,  newBookingFromEmptyNight } = props;
  const bookingWindow=useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.allowBooking);
  const userCountry= useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.country);

  const dispatch=useDispatch();

  const findRentalDetail = (rentalId) => {
    const findRental = rentals.find((rental) => Number(rental.id) === Number(rentalId));
    return findRental || { id: 0, maxOccup: 1, maxChild: 0, checkinTime: '', checkoutTime: '' };
  };
  
  //=============================================
  // SCHEMA REQUIREMENT FOR FORM VALIDATION
  //=============================================

  const SignupSchemaBooking = yup.object().shape({
    totalGuests: yup.number(),
    adult: yup.number().min(1, 'At least 1 adult is required').required('At least 1 adult is required'),
    children: yup.number().min(0, 'Cannot be a negative number'),
    babies: yup.number().min(0, 'Cannot be a negative number'),
    arrival: yup.date().nullable().max(`${moment().add(2,'years').format('YYYY-MM-DD')}`,"Booking not allowed after 2 years").required('Arrival date is required'),
    departure: yup.date().nullable().max(`${moment().add(2,'years').format('YYYY-MM-DD')}`,"Booking not allowed after 2 years").required('Departure date is required'),
    notesBooking:yup.string().max(50,'Maximum 50 characters are allowed!'),
    miniNotes: yup.string().max(27,'Maximum 27 characters are allowed!'),
    checkInTime: yup.string().required('Check-in is required'),
    checkOutTime: yup.string().required('Check-out is required'),
  });

  const signUpSchema=yup.object().shape({
    totalGuests: yup.number(),
    adult: yup.number().min(1, 'At least 1 adult is required').required('At least 1 adult is required'),
    children: yup.number().min(0, 'Cannot be a negative number'),
    babies: yup.number().min(0, 'Cannot be a negative number'),
    arrival: yup.date().required('Arrival date is required').nullable(),
    departure: yup.date().required('Departure date is required').nullable(),
    notesBooking:yup.string().max(50,'Maximum 50 characters are allowed!'),
    miniNotes: yup.string().max(27,'Maximum 27 characters are allowed!'),
    checkInTime: yup.string().required('Check-in is required'),
    checkOutTime: yup.string().required('Check-out is required'),
  });

  //=============================================
  // STATE FOR BOOKING DETAILS
  //=============================================

  const [values, setValues] = useState({});
  const [guestDetail, setGuestDetail] = useState("");

  const [rentalId, setRentalId] = useState((newBookingFromEmptyNight?.rentalId || ''));
  const [arrival,setArrival] = useState((newBookingFromEmptyNight?.arrive || ''));
  const [departure,setDeparture] = useState('');
  const [adult,setAdult] = useState(1);
  const [children,setChildren] = useState(0);
  const [babies,setBabies] = useState(0);
  const [totalGuest, setTotalGuest] = useState(adult+children);
  const [taxes, setTaxes] = useState(0);
  const [extraCharge,setExtraCharge]=useState(0);
  const [fees,setFees]=useState(0);
  const [finalPrice, setFinalPrice] = useState(0);

  const [price,setPrice]=useState(0);
  const [basePrice,setBasePrice]=useState(0);
  const [checkIn,setCheckIn]=useState('');
  const [checkOut,setCheckOut]=useState('');
  const [notesBooking,setNotesBooking]=useState('');
  const [miniNotes,setMiniNotes]=useState('');
  const [srcVal,setSrcVal]=useState('Beehaz');

  const [guestName,setGuestName]=useState("");
  const [company,setCompany]=useState("");
  const [primaryEmail,setPrimaryEmail]=useState("");
  const [secondaryEmail,setSecondaryEmail]=useState("");
  const [phoneNumber,setPhoneNumber]=useState("");
  const [secondaryPhoneNumber,setSecondaryPhoneNumber]=useState("");
  const [country,setCountry]=useState([]);
  const [streetAddress,setStreetAddress]=useState("");
  const [postalCode,setPostalCode]=useState("");
  const [stateProvince,setStateProvince]=useState("");
  const [addressDetails,setAddressDetail]=useState("")
  const [nationality,setNationality]=useState("");
  const [language,setLanguage]=useState("");
  const [birthday,setBirthDay]=useState("");
  const [notes,setNotes]=useState("");
  const [color,setColor]=useState('#d3d3d3');
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [defVal,setDefVal]=useState("");
  const paymentOpt = ["Payment Pending", "Partially Paid", "Paid"];
  const colorDictionary = useSelector(({ generalSetting }) => {
    const colorsSetting = generalSetting && generalSetting.setting.colors;
    const map = [];
    for (const key in colorsSetting) {
        if (key.startsWith('color')) {
            map.push(colorsSetting[key]);
        }
    };
    return map;
  });
  // ==================================================
  // ==========PRICE CALCULATION STARTS HERE===========
  // ==================================================
  const ratesList = useSelector(({rates})=>rates && rates.rateSettings);
  const propertyList = useSelector(({rentals})=>rentals && rentals.rentals);
  const currentCharges=useSelector(({calendar})=>calendar && calendar.currentCharges);
  const {numberOf, allowBookingFor} = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
  const [totalNights, setTotalNights] = useState(0);
  const [rentalDetail, setRentalDetail] = useState(findRentalDetail(rentalId));

  const compare = (a, b) => {
    return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
  };

  const getChannelOptions = (channels) => {
    const channelOptions = channels.map((item) => {
      const ChannelNameObject = getChannelName(item.channel);
      return {label: ChannelNameObject.label, value: ChannelNameObject.value};
    });
    return [{label: 'Beehaz', value: 'Beehaz'}, ...channelOptions];
  }; 

  const getChannelName = (channel) => {
    if (channel.ota === 'Other')
        return {label: `${channel.name} (iCal)`, value: channel.name}
    if (channel.ota === 'VRBO')
        return {label: 'VRBO (HomeAway) (iCal)', value: channel.ota}
    else
        return {label: `${channel.ota} (iCal)`, value: channel.ota}
  };

  const channels = useSelector(({icalChannels}) => icalChannels && getChannelOptions(icalChannels.channels));

  useEffect(()=>{
    return()=>{
      dispatch(resetCharges());
    }
  },[])

  //attempt to relocate : failed
  const [userCode,setUserCode]=useState(()=>findCountry(userCountry));

  useEffect( () => {
    if (rentalId && ratesList) {
      // eslint-disable-next-line array-callback-return
      propertyList.map((p)=>{
        if(p.id===Number(rentalId)){
          setRentalDetail(p);
          setCheckIn(p.checkInTime);
          setCheckOut(p.checkOutTime);
        }
      });
    }
  }, [rentalId])


  useEffect( () => {

    if(rentalId && arrival && departure && totalGuest && (adult > 0)){

      dispatch(chargeCalculation({
        arrive: moment(arrival).format().slice(0,10),
        depart: moment(departure).format().slice(0,10),
        noOfAdults: Number(adult),
        noOfChildren: Number(children),
        rentalId:Number(rentalId),
        extraCharge:Number(extraCharge),
        discount:Number(taxes),
        bookingType:"booking"
      }))

      setTotalNights(moment(departure).diff(moment(arrival),'days'));
      setDisableSubmit(true);

    };


  },[departure, arrival, rentalId, totalGuest, extraCharge, taxes, dispatch]);

  useEffect(()=>{
    if(currentCharges){
      setPrice(Number(currentCharges.total).toFixed(2));
      setFinalPrice(Number(currentCharges.total).toFixed(2));
      setBasePrice(Number((currentCharges.daily_rate + currentCharges.extra_guest_fees).toFixed(2)));
      setFees(Number(currentCharges.fees+currentCharges.taxes).toFixed(2));
    };
    
    if(currentCharges && !currentCharges.maxStay){
      toast.warn(`Maximum stay requirements set for this rental: ${currentCharges.maxStayConstrain} nights!!`
        , { style: { color: '#484848' }}
      );
    };

    if(currentCharges && !currentCharges.minStay){
      toast.warn(`Minimum stay requirements set for this rental: ${currentCharges.minStayConstrain} nights!!`
        , { style: { color: '#484848' }}
      );
    };

    setDisableSubmit(false);

  },[currentCharges])

  // ==================================================
  // ==========PRICE CALCULATION ENDS HERE=============
  // ==================================================

  useEffect(()=>{
    if(guestDetail && guestDetail!==""){
      const guestData= guestList.filter((row)=>(row.name===guestDetail.label))
      setDefVal(guestData[0]);
      setGuestName(guestData[0].name);
      setPrimaryEmail(guestData[0].emailId);
      if(guestData[0].dob && (guestData[0].dob!=="Invalid da" && guestData[0].dob!=="" && guestData[0].dob!==null && guestData[0].dob!=="[NULL]")){
        setBirthDay(new Date(guestData[0].dob));
      }else{
        setBirthDay("");
      }
    }else {
      setDefVal("");
      setGuestName("");
      setCompany("");
      setPrimaryEmail("");
      setSecondaryEmail("");
      setCountry([]);
      setPhoneNumber("");
      setStreetAddress("");
      setPostalCode("");
      setStateProvince("");
      setAddressDetail("");
      setNationality("");
      setLanguage("");
      setNotes("");
      setBirthDay("");
    }
  },[guestDetail])
  //=============================================
  // FORM INITIAL VALUE
  //=============================================

  const initVal = () => {
    const currentRental = findRentalDetail(newBookingFromEmptyNight?.rentalId);

    return {
      rental: (newBookingFromEmptyNight?.rentalId || ''),
      totalGuests:1,
      adult: 1,
      children: 0,
      babies: 0,
      arrival: (newBookingFromEmptyNight?.arrive || ''),
      departure:'',
      checkInTime: (newBookingFromEmptyNight && currentRental.checkinTime || ''),
      checkOutTime: (newBookingFromEmptyNight && currentRental.checkoutTime || ''),
      price: 0,
      taxes:0,
      finalPrice:0,
      phoneNo: '',
      secondaryPhoneNo: '',
      paymentStatus: 'Payment Pending',
    };
  };

  const validate = (field,value) => {
    let errorMessage;
    if(field==="emailId"){
      if(!value || value===""){
        errorMessage = 'Email address is required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        errorMessage = 'Invalid email address';
      }
    }else if(field==="name"){
      if(!value || value===""){
        errorMessage = 'Guest name is required';
      }
    }else if(field==="rental"){
      if(value===""){
        errorMessage= 'Rental is required';
      }
    }else if(field==="totalGuests"){
      if(!totalGuest || totalGuest <= 0){
        errorMessage = 'At least 1 guest is required';
      } else if(totalGuest > rentalDetail.maxOccup){
        errorMessage=`Maximum ${rentalDetail.maxOccup} guests are allowed`;
      }
    } else if(field==="adult" && adult<1){
      return `At least 1 adult is required`;
    } else if(field==="children"){
      if(children < 0){
        return `Cannot be a negative number`;
      };
      if(children > rentalDetail.maxChild){
        return `Maximum ${rentalDetail.maxChild} children are allowed`;
      };
    } else if(field === "babies" && babies < 0){
      return `Cannot be a negative number`;
    } else if(field==="price") {
      if (Number(basePrice) < 0) {
        errorMessage = "Please enter valid price";
      }
    }else if(field==="finalPrice"){
      if(Number(finalPrice)<0){
        errorMessage = "Please enter valid price";
      }
    }
    return errorMessage;
  };

  const guestForm = [
    { title: 'Name*: ', name: 'name' , value: guestName,type:"text"},
    { title: 'Company: ', name: 'company' ,value:company,type:"text"},
    { title: 'Email address*: ', name: 'emailId',value:primaryEmail,type:"text" },
    { title: 'Secondary email: ', name: 'secondaryEmailId',value:secondaryEmail,type:"text" },
    { title: 'Phone: ', name: 'phoneNo' ,value:phoneNumber},
    { title: 'Secondary phone: ', name: 'secondaryPhoneNo',type :"text" },
    { title: 'Country: ', name: 'country',value:country },
    { title: 'Street and nr: ', name: 'address1',value:streetAddress,type:"text"},
    { title: 'Postal code: ', name: 'postalCode',value:postalCode,type:"text" },
    { title: 'State/Province: ', name: 'stateProvince',value:stateProvince,type:"text" },
    { title: 'Address details: ', name: 'address',value: addressDetails,type:"text"},
    { title: 'Nationality: ', name: 'nationality',value:nationality,type:"text" },
    { title: 'Language: ', name: 'language',value:language,type:"text" },
    { title: 'Birthday:',name:'dob',value:birthday,type:'date'},
    { title: 'More info: ', name: 'notes',value:notes,isIcon:true, type:'text',iconContent:" Write more information or notes specific to your guest here. These can be retrieved later if the guest makes another reservation." },
  ];
  //===================================
  // HANDLE SUBMIT
  //===================================

  function handleSubmit() {
    let guestData={};
    const check=(defVal==="");
    const payload = {
      rentalId: Number(rentalId),
      price: Number(Number(finalPrice).toFixed(2)),
      noOfAdults: adult,
      arrive: moment(arrival).utc(true).format('YYYY-MM-DD'),
      depart: moment(departure).utc(true).format('YYYY-MM-DD'),
      arriveUTC : moment(arrival).utc(true).format('YYYY-MM-DD HH:mm:ss'),
      departUTC : moment(departure).utc(true).format('YYYY-MM-DD HH:mm:ss'),
      noOfChildren: Number(children),
      noOfBabies: Number(babies),
      source: srcVal,
      checkInTime:checkIn,
      checkOutTime:checkOut,
      bookingType:"booking",
      status:"Booked",
      noOfGuests: Number(totalGuest),
      paymentStatus: values.paymentStatus,
      title : `${(defVal)?defVal.name:values.name} | ${values.paymentStatus} | Direct Booking`,
      nights: totalNights,
      color: color,
      discountName: "Discount",
      discountAmount:Number(Number(taxes).toFixed(2)),
      chargeName:"Extra charge",
      chargeAmount:Number(Number(extraCharge).toFixed(2)),
      fees:currentCharges.fees,
      tax:currentCharges.taxes,
      extraGuestFee:currentCharges.extra_guest_fees,
      baseRate:currentCharges.daily_rate,
      miniNotes:miniNotes,
      notes:notesBooking,
    }
    if(!check){
      guestData={
        guestId:defVal.id
      }
    }else{
      guestData={
        name: values.name,
        emailId: values.emailId,
        phoneNo: values?.phoneNo.split(" ")?.length !==1 ? values.phoneNo : "",
        guestType: 'guest',
        secondaryPhoneNo: values?.secondaryPhoneNo.split(" ")?.length !==1 ? values.secondaryPhoneNo : "",
        postalCode: values.postalCode?? "",
        secondaryEmailId: values.secondaryEmailId ?? "",
        country: (country.length!==0)?country.label:"",
        address: values.address ?? "",
        street: values.address1 ?? "", 
        state: values.stateProvince ??"",
        nationality: values.nationality ?? "",
        language: values.language ?? "",
        company: values.company ?? "",
        notes: values.notes ?? "",
        dob:birthday?moment(birthday).format().slice(0,10):"",
      }
    }
    handleSave(payload,guestData,check);
  }

  return (
      <>
        <Formik
            initialValues={initVal()}
            validationSchema={bookingWindow?SignupSchemaBooking:signUpSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
          {({ values, errors,setFieldValue }) => (
              <div>
                <Form>
                  <Row>
                    <Col lg={6} className={styles.wrapper}>
                      <h3 className={styles.header}>Booking Details</h3>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"selectRental"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Select rental*: "}</div>
                        <div className={`w-100`}>
                          <Field
                              as={"select"}
                              name={"rental"}
                              value={values.rental}
                              className={(errors.rental)?styles.form_entry_err:styles.form_entry}
                              validate={(value)=>validate('rental',value)}
                              onChange={(e)=> {
                                const currentRental = findRentalDetail(e.target.value);
                                setFieldValue('rental', currentRental.id);
                                setFieldValue('checkInTime', currentRental.checkinTime);
                                setFieldValue('checkOutTime', currentRental.checkoutTime);
                                setCheckIn(currentRental.checkinTime);
                                setCheckOut(currentRental.checkoutTime);
                                setRentalDetail(currentRental);
                              }}
                          >
                            <option value={0} label={"---Select Rental---"}/>
                            {rentals.map((opt)=>(
                                <option key={opt.id} value={opt.id}>{opt.name}</option>
                            ))}
                          </Field>
                          <div className={errors.rental ? styles.form_error : styles.form_error_hidden}>
                            {errors.rental}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"totalGuest"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Number of guests: "}</div>
                        <div className={`w-100`}>
                          <Field
                              type={"number"}
                              name={"totalGuests"}
                              className = {(errors.totalGuests)?styles.form_entry_err:styles.form_entry}
                              value={totalGuest}
                              onChange={(e)=> {
                                const guest = e.target.value;
                                setTotalGuest(guest);
                                setAdult(guest-children);
                              }}
                              validate={(value)=>validate('totalGuests',value)}
                              min={1}
                          />
                          <div className={errors.totalGuests ? styles.form_error : styles.form_error_hidden}>
                            {errors.totalGuests}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"adult"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Adults: "}</div>
                        <div className={`w-100`}>
                        <Field
                            type={"number"}
                            name={"adult"}
                            value={adult}
                            disabled={true}
                            className={(errors.adult)?styles.form_entry_err:styles.form_entry}
                            validate={(value)=>validate('adult', value)}
                            min={1}
                        />
                          <div className={errors.adult ? styles.form_error : styles.form_error_hidden}>
                              {errors.adult}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"children"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Children: "}</div>
                        <div className={`w-100`}>
                        <Field
                            type={"number"}
                            name={"children"}
                            value={children}
                            onChange={(e)=> {
                              const child = e.target.value;
                              setChildren(child);
                              setAdult(totalGuest-child);
                            }}
                            className={(errors.children)?styles.form_entry_err:styles.form_entry}
                            validate={(value)=>validate('children', value)}
                            min={0}
                        />
                          <div className={errors.children ? styles.form_error : styles.form_error_hidden}>
                              {errors.children}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"babies"}>
                        <div className={`d-flex ${styles.form_title}`}>
                          {"Babies: "}
                          <InfoOutlinedIcon
                            fontSize="small"
                            color="inherit"
                            data-tip
                            data-for="babies"
                            className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="babies" effect="solid" className={styles.toolTip}>
                            <span>
                              Babies are not counted in the number of total guests (which consists of the total number of adults + the total number of children). This means they do not have any effect on the price calculation (while the number of adults and children influence the price when you have per person fees in your pricing).
                            </span>
                          </ReactTooltip>
                        </div>
                        <div className={`w-100`}>
                        <Field
                            type={"number"}
                            name={"babies"}
                            value={babies}
                            onChange={(e)=>setBabies(e.target.value)}
                            className={(errors.babies)?styles.form_entry_err:styles.form_entry}
                            min={0}
                        />
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"arrival"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Arrival date*: "}</div>
                        <div className={`w-100`}>
                          <CustomDate
                              id={"arrival"}
                              name={"arrival"}
                              handleChange={(date)=>{
                                setFieldValue('arrival', date);
                                setArrival(date)
                              }}
                              value={arrival}
                              hasError={errors.arrival}
                              max={(departure)?departure:new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                              customClass={errors.arrival ? styles.form_entry_err : styles.form_entry}
                          />
                          <div className={errors.arrival ? styles.form_error : styles.form_error_hidden}>
                            {errors.arrival}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"departure"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Departure date*: "}</div>
                        <div className={`w-100`}>
                          <CustomDate
                              id={"departure"}
                              name={"departure"}
                              handleChange={(date)=>{
                                setFieldValue('departure',date);
                                setDeparture(date)
                              }}
                              value={departure}
                              min={arrival}
                              hasError={errors.departure}
                              max={new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                              customClass={errors.departure ? styles.form_entry_err : styles.form_entry}
                          />
                          <div className={errors.departure ? styles.form_error : styles.form_error_hidden}>
                            {errors.departure}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"nights"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Total nights: "}</div>
                        <div className={`w-100`}>
                        <Field
                          type={"number"}
                          name={"nights"}
                          value={totalNights}
                          disabled={true}
                          min={0}
                          className={ errors.nights ? styles.form_entry_err : styles.form_entry }
                        />
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"price"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Price: "}
                            <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="price"
                              className={styles.toolTipIcon}
                            />
                            <ReactTooltip place="bottom" type="dark" id="price" effect="solid" className={styles.toolTip}>
                              <span>
                                The price takes into account the rates set for these dates and extra guest fees. These can be
                                managed by clicking on the Rates icon. You can manually overwrite the total for this booking by
                                editing the booking later.
                              </span>
                            </ReactTooltip>
                        </div>
                        <div className={`w-100`}>
                          <Field
                              type={"number"}
                              name={"price"}
                              className={(errors.price)?styles.form_entry_err: styles.form_entry}
                              value={basePrice.toFixed(2)}
                              // onChange={(e)=>setPrice(e.target.value)}
                              validate={(value)=>validate('price',value)}
                              disabled={true}
                              min={0}
                          />
                          <div className={errors.price ? styles.form_error : styles.form_error_hidden}>
                            {errors.price}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"taxes"}>
                        <div className={`d-flex ${styles.form_title}`}>
                          {'Discount:'}
                        </div>
                        <div className={`w-100`}>
                        <Field
                            type={"number"}
                            name={"taxes"}
                            className={(errors.taxes)?styles.form_entry_err: styles.form_entry}
                            value={taxes}
                            onChange={(e)=>setTaxes(e.target.value)}
                            min={0}
                        />
                        </div>
                        <div className={errors.taxes ? styles.form_error : styles.form_error_hidden}>
                          {errors.taxes}
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"extraCharge"}>
                        <div className={`d-flex ${styles.form_title}`}>
                          {'Extra charge:'}
                        </div>
                        <div className={`w-100`}>
                        <Field
                            type={"number"}
                            name={"extraCharge"}
                            className={(errors.extraCharge)?styles.form_entry_err: styles.form_entry}
                            value={extraCharge}
                            onChange={(e)=>setExtraCharge(e.target.value)}
                            min={0}
                        />
                        </div>
                        <div className={errors.extraCharge ? styles.form_error : styles.form_error_hidden}>
                          {errors.extraCharge}
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"fees"}>
                        <div className={`d-flex ${styles.form_title}`}>
                          {'Fees and taxes:'}
                        </div>
                        <div className={`w-100`}>
                        <Field
                            type={"number"}
                            name={"fees"}
                            className={(errors.fees)?styles.form_entry_err: styles.form_entry}
                            value={fees}
                            disabled={true}
                            min={0}
                        />
                        </div>
                        <div className={errors.fees ? styles.form_error : styles.form_error_hidden}>
                          {errors.fees}
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"finalPrice"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Final price: "}
                          <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="finalPriceIcon"
                              className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="finalPriceIcon" effect="solid" className={styles.toolTip}>
                            <span>
                             Considers rates, extra guest fees, fees, and taxes. These can be managed by clicking on the
                             Rates icon. Any discount or charge you have set above are also considered here. You can
                             manually overwrite the total for this booking by editing the booking later
                            </span>
                          </ReactTooltip>
                        </div>
                        <div className={`w-100`}>
                          <Field
                              type={"number"}
                              name={"finalPrice"}
                              className={(errors.finalPrice)?styles.form_entry_err: styles.form_entry}
                              value={finalPrice}
                              validate={(value)=>validate('finalPrice',value)}
                              disabled={true}
                              min={0}
                          />
                          <div className={errors.finalPrice ? styles.form_error : styles.form_error_hidden}>
                            {errors.finalPrice}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"paymentStatus"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Payment status: "}</div>
                        <div className={`w-100`}>
                        <Field
                            as={"select"}
                            name={"paymentStatus"}
                            className={(errors.paymentStatus)?styles.form_entry_err: styles.form_entry}
                        >
                          <option key={""} value={""} disabled={true} label={"--Select Status--"}/>
                          {paymentOpt.map((opt)=>(
                              <option value={opt} key={opt}>{opt}</option>
                          ))}
                        </Field>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"source:"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Source: "}</div>
                        <div className={`w-100`}>
                        <Field
                            name={"source"}
                            as={'select'}
                            className={(errors.source)?styles.form_entry_err: styles.form_entry}
                            value={srcVal}
                            onChange={(e)=>setSrcVal(e.target.value)}
                        >
                          {channels.map((opt, index) => (
                              <option key={index} value={opt.value} >
                                {opt.label}
                              </option>
                          ))}
                        </Field>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"checkintime"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Check-in time*: "}</div>
                        <div className={`w-100`}>
                          <Field
                              type={"time"}
                              name={"checkInTime"}
                              id={"checkInInp"}
                              value={values.checkInTime}
                              onChange={e => setFieldValue('checkInTime', e.target.value)}
                              className={errors.checkInTime ? styles.form_entry_err : styles.form_entry}
                          />
                          <div className={errors.checkInTime ? styles.form_error : styles.form_error_hidden}>
                            {errors.checkInTime}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"checkouttime"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Check-out time*: "}</div>
                        <div className={`w-100`}>
                          <Field
                              type={"time"}
                              name={"checkOutTime"}
                              id={"checkOutInp"}
                              value={values.checkOutTime}
                              onChange={e => setFieldValue('checkOutTime', e.target.value)}
                              className={errors.checkOutTime ? styles.form_entry_err : styles.form_entry}
                          />
                          <div className={errors.checkOutTime ? styles.form_error : styles.form_error_hidden}>
                            {errors.checkOutTime}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"notesBooking"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Notes:"}
                          <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="notesBooking"
                              className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="notesBooking" effect="solid" className={styles.toolTip}>
                            <span>
                              Write longer notes specific to this booking here.
                            </span>
                          </ReactTooltip>
                        </div>
                        <div className={`w-100`}>
                        <Field
                            type={"text"}
                            name={"notes"}
                            id={"notesBooking"}
                            className={styles.form_entry}
                            value={notesBooking}
                            onChange={(e)=>(setNotesBooking(e.target.value))}
                        />
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"miniNotes"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Mini notes:"}
                          <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="miniNotes"
                              className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="miniNotes" effect="solid" className={styles.toolTip}>
                            <span>
                              Write a short note to be displayed in the booking information summary box that appears when
                              you click on a reservation in the calendar.
                            </span>
                          </ReactTooltip>
                        </div>
                        <div className={`w-100`}>
                        <Field
                            type={"text"}
                            name={"miniNotes"}
                            id={"miniNotes"}
                            className={`${styles.form_entry}`}
                            value={miniNotes}
                            onChange={(e)=>(setMiniNotes(e.target.value))}
                        />
                        </div>
                      </div>
                    </Col>
                    <Col s={12} lg={6} className={styles.wrapper}>
                      <h3 className={styles.header}>Guest Details</h3>
                      <div className={`${styles.searchContainer}`}>
                        <Select
                          className='w-100'
                          options={guestList.map((row) => ({label: row.name, value: row.id})).sort(compare)}
                          onChange={setGuestDetail}
                          value={guestDetail}
                          isSearchable={true}
                          isClearable={true}
                          placeholder={"Search guest..."}
                        />
                        <div className={`${styles.paragraphBelowSearch} d-flex align-items-center`}>
                          <hr className={`${styles.hrClass} flex-grow-1`}/>
                          <p className='mx-3'>or add new guest</p>
                          <hr className={`${styles.hrClass} flex-grow-1`}/>
                        </div>
                      </div>
                      {guestForm.map((form) => {
                        const err = errors[form.name];
                        return (
                            <div className={`d-flex ${styles.form_wrapper}`} key={form.title}>
                              {form.isIcon ? (
                                  <>
                                    <div className={`d-flex ${styles.form_title}`}>{form.title}
                                      <InfoOutlinedIcon
                                          fontSize="small"
                                          color="inherit"
                                          data-tip
                                          data-for={`${form.title}-icon`}
                                          className={styles.toolTipIcon}
                                      />
                                      <ReactTooltip place="bottom" type="dark" id={`${form.title}-icon`} effect="solid" className={styles.toolTip}>
                                    <span>
                                      {form.iconContent}
                                    </span>
                                      </ReactTooltip>
                                    </div>
                                  </>
                              ):(
                                  <div className={`d-flex ${styles.form_title}`}>{form.title}</div>
                              )}
                              {(guestDetail && guestDetail!=="")? (
                                      (form.name==="dob" )?(
                                        <div className="w-100">
                                          <CustomDate
                                            id={"dob"}
                                            name={"dob"}
                                            readOnly={true}
                                            handleChange={(date)=>setBirthDay(date)}
                                            value={birthday}
                                            max={new Date()}
                                            min={new Date(`${(new Date().getUTCFullYear())-100}-01-01`)}
                                            customClass={errors.dob ? styles.form_entry_err : styles.form_entry}
                                          />
                                        </div>
                                      ):(
                                          <Field
                                              id={form.name}
                                              readOnly={true}
                                              type={form.type}
                                              value={defVal[form.name]}
                                              className={styles.form_entry}
                                          />
                                      )
                                  ):
                                  (<>
                                        { form.name==="country" ? (
                                            <div className="w-100">
                                              <Select
                                                  id="contrySelect"
                                                  options={countryList().getData()}
                                                  isClearable={true}
                                                  value={country}
                                                  className={styles.form_entry_select}
                                                  onChange={(val)=>setCountry(val)}
                                              />
                                            </div>
                                            ) : form.name==="phoneNo" ? (
                                                    <div className="w-100">
                                                      <PhoneInput
                                                          name="phoneNo"
                                                          country={userCode}
                                                          value={phoneNumber}
                                                          onChange={(value, data, event, formattedValue) => {
                                                            const rawVal = value.slice(data.dialCode.length);
                                                            const phone = rawVal ? `+${data.dialCode} ${rawVal}` : "";
                                                            setFieldValue("phoneNo", phone);
                                                            setPhoneNumber(phone || data.dialCode);
                                                          }}
                                                          inputStyle={{width: '100%'}}
                                                          countryCodeEditable={false}
                                                          enableSearch={true}
                                                          disableSearchIcon={true}
                                                      />
                                                    </div>
                                            ) : form.name==="secondaryPhoneNo" ? (
                                                    <div className="w-100">
                                                      <PhoneInput
                                                          name="secondaryPhoneNo"
                                                          country={userCode}
                                                          value={secondaryPhoneNumber}
                                                          onChange={(value, data, event, formattedValue) => {
                                                            const rawVal = value.slice(data.dialCode.length);
                                                            const phone = rawVal ? `+${data.dialCode} ${rawVal}` : "";
                                                            setFieldValue("secondaryPhoneNo", phone);
                                                            setSecondaryPhoneNumber(phone || data.dialCode);
                                                          }}
                                                          inputStyle={{width: '100%'}}
                                                          countryCodeEditable={false}
                                                          enableSearch={true}
                                                          disableSearchIcon={true}
                                                      />
                                                    </div>
                                            ) : form.name==="dob" ? (
                                                    <div className={`w-100`}>
                                                      <CustomDate 
                                                        id={"dob"}
                                                        name={"dob"}
                                                        handleChange={(date)=>setBirthDay(date)}
                                                        value={birthday}
                                                        max={new Date()}
                                                        min={new Date(`${(new Date().getUTCFullYear())-100}-01-01`)}
                                                        customClass={errors.dob ? styles.form_entry_err : styles.form_entry}
                                                      />
                                                    </div>
                                            ) : (
                                                    <div className={`w-100`}>
                                                      <Field
                                                          id={form.name}
                                                          name={form.name}
                                                          type={form.type}
                                                          className={err ? styles.form_entry_err : styles.form_entry}
                                                          value={form.value}
                                                          validate={(value)=>validate(form.name,value)}
                                                      />
                                                      <div className={err ? styles.form_error : styles.form_error_hidden}>{errors[form.name]}</div>
                                                    </div>
                                            )}
                                      </>
                                  )}
                            </div>
                        );
                      })}
                      <Row className={`pl-3`}>
                        <div className={`d-flex ${styles.form_title}`}>Choose colour: </div>
                        <div className={styles.containerColor}>
                          {colorDictionary.map((item, index)=>(
                              <div
                                  className={`${styles.containerBox} ${(color===item)?` active1`:``}`}
                                  style={{background:`${item}`}}
                                  onClick={()=>setColor(item)}
                                  key={index}
                              />
                          ))}
                          <Field
                              type={'color'}
                              name={'color'}
                              value={color}
                              onChange={(e)=>setColor(e.target.value)}
                              className={`d-flex ${styles.form_entry_color}`}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={`d-flex justify-content-center`}>
                        <div>
                          <button type="submit" className={styles.settings_save} disabled={disableSubmit}>
                            {'Save changes'}
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
                {setValues(values)}
                {setRentalId(values.rental)}
                {setGuestName(values.guestName)}
                {setCompany(values.company)}
                {setPrimaryEmail(values.primaryEmail)}
                {setSecondaryEmail(values.secondaryEmail)}
                {setStreetAddress(values.streetAddress)}
                {setPostalCode(values.postalCode)}
                {setStateProvince(values.stateProvince)}
                {setAddressDetail(values.addressDetails)}
                {setNationality(values.nationality)}
                {setLanguage(values.language)}
                {setNotes(values.notes)}
                {setCheckIn(values.checkInTime)}
                {setCheckOut(values.checkOutTime)}
              </div>
          )}
        </Formik>
      </>
  )
};
export default BookingForm;

import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import getSymbolFromCurrency from 'currency-symbol-map';
import styles from '../../../../rates.module.css';


const TaxForm = (props) => {

    const {
        showTax,
        initialValues,
        validationSchema,
        handleSubmit,
        currency
    } = props;

    const resetForm = (handleReset) => {
        handleReset();
        showTax(false);
    };

    const onSubmit = (validateForm, setSubmitting, data) => {

        validateForm(data).then((errors) => {

            if (!Object.keys(errors).length) {
                handleSubmit(data);
                setSubmitting(false);
            };
        });
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ values, errors, isSubmitting, setSubmitting, setFieldValue, validateForm, handleChange, handleReset }) => (
            <Form>
                <Form.Row>
                    <Form.Label className="col-sm-6 col-lg-4 col-form-label">Name of tax:</Form.Label>
                    <div className="col-sm-5 col-lg-4">
                        <Form.Control
                            type="text"
                            name="name"
                            onChange={handleChange}
                            defaultValue={values.name}
                            className={styles.form_entry}
                            isInvalid={!!errors.name}
                        />
                        <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.name}</div>
                    </div>
                </Form.Row>
                <br/>
                <Form.Row>
                    <Form.Label className="col-sm-6 col-lg-4 col-form-label">Type of tax:</Form.Label>
                    <div className="d-flex align-content-center col-sm-6">
                        <Form.Check
                            inline
                            name="taxType"
                            label="percentage"
                            onChange={(data) => {
                                handleChange(data);
                                setFieldValue("modality", "per stay");
                                setFieldValue('taxApplyAdults', false);
                                setFieldValue('taxApplyChildren', false);
                            }}
                            value="percentage"
                            type="radio"
                            checked={values.taxType==="percentage"}
                        />
                        <Form.Check
                            inline
                            name="taxType"
                            label="flat rate"
                            onChange={handleChange}
                            value="flat rate"
                            type="radio"
                            checked={values.taxType==="flat rate"}
                            className="ml-3"
                        />
                    </div>
                </Form.Row>
                <br/>
                <Form.Row>
                    <Form.Label className="col-sm-6 col-lg-4 col-form-label">Amount:</Form.Label>
                    <div className="col-sm-5 col-lg-4">
                    <InputGroup className="mb-1">
                        <Form.Control
                            type="text"
                            name="amount"
                            onChange={handleChange}
                            defaultValue={values.amount}
                            className={styles.form_entry}
                            isInvalid={!!errors.amount}
                            min={0}
                        />
                        <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2" className={styles.form_entry}
                        >{(values.taxType==="percentage")?"%":(getSymbolFromCurrency(`${currency}`))}</InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                    </InputGroup>
                    </div>
                </Form.Row>
                <br/>
                <Form.Row>
                    <Form.Label className="col-sm-6 col-lg-4 col-form-label">Modality:</Form.Label>
                    <div className="col-sm-5 d-sm-flex flex-sm-column d-lg-block">
                    <Form.Check
                        inline
                        name="modality"
                        label="per stay"
                        onChange={(data) => {
                            handleChange(data);
                            setFieldValue('taxApplyAdults', false);
                            setFieldValue('taxApplyChildren', false);
                        }}
                        value="per stay"
                        type="radio"
                        checked={values.modality==="per stay"}
                    />
                    <Form.Check
                        inline
                        name="modality"
                        label="per night"
                        onChange={(data) => {
                            handleChange(data);
                            setFieldValue('taxApplyAdults', false);
                            setFieldValue('taxApplyChildren', false);
                        }}
                        value="per night"
                        type="radio"
                        disabled={values.taxType==="percentage"}
                        checked={values.modality==="per night"}
                    />
                    <Form.Check
                        inline
                        name="modality"
                        label="per person"
                        onChange={(data) => {
                        if (["per stay", "per night"].includes(values.modality)) {
                            setFieldValue('taxApplyAdults', true);
                            setFieldValue('taxApplyChildren', true);
                        };
                        handleChange(data);
                        }}
                        value="per person"
                        type="radio"
                        disabled={values.taxType==="percentage"}
                        checked={values.modality==="per person"}
                    />
                    <Form.Check
                        inline
                        name="modality"
                        label="per person per night"
                        onChange={(data) => {
                        if (["per stay", "per night"].includes(values.modality)) {
                            setFieldValue('taxApplyAdults', true);
                            setFieldValue('taxApplyChildren', true);
                        };
                        handleChange(data);
                        }}
                        value="pppn"
                        type="radio"
                        disabled={values.taxType==="percentage"}
                        checked={values.modality==="pppn"}
                    />
                    </div>
                </Form.Row>
                <br/>
                {/* TODO: confirm that this functionality can be implemented in this form */}
                {/* { ["per person", "pppn"].includes(values.modality) &&
                <>
                    <Form.Row>
                        <Form.Label className="col-sm-6 col-lg-4 col-form-label">Does this tax apply to adults?</Form.Label>
                        <div className="d-flex align-content-center col-sm-5 col-lg-4">
                        <Form.Check
                            inline
                            name="taxApplyAdults"
                            label="Yes"
                            onChange={handleChange}
                            value={1}
                            type="radio"
                            checked={Boolean(Number(values.taxApplyAdults))===true}
                        />
                        <Form.Check
                            inline
                            name="taxApplyAdults"
                            label="No"
                            onChange={handleChange}
                            value={0}
                            type="radio"
                            checked={Boolean(Number(values.taxApplyAdults))===false}
                            className="ml-3"
                        />
                        </div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label className="col-sm-6 col-lg-4 col-form-label">Does this tax apply to children?</Form.Label>
                        <div className="d-flex align-content-center col-sm-5 col-lg-4">
                        <Form.Check
                            inline
                            name="taxApplyChildren"
                            label="Yes"
                            onChange={handleChange}
                            type="radio"
                            value={1}
                            checked={Boolean(Number(values.taxApplyChildren))===true}
                        />
                        <Form.Check
                            className="ml-3"
                            inline
                            name="taxApplyChildren"
                            label="No"
                            onChange={handleChange}
                            type="radio"
                            value={0}
                            checked={Boolean(Number(values.taxApplyChildren))===false}
                        />
                        </div>
                    </Form.Row>
                    <br/>
                </>
                } */}
                <div className='d-flex justify-content-start'>
                    <div className='m-1 p-1 mb-4 m-lg-0 p-lg-0 mb-lg-4 col-12 col-sm-8 col-lg-6 d-flex justify-content-around d-lg-inline'>
                        <button
                            type="button"
                            disabled={isSubmitting}
                            className={`ml-0 p-1 mx-sm-2 ml-lg-0 col-5 col-sm-6 col-lg-5 ${styles.allBtn}`}
                            onClick={() => onSubmit(validateForm, setSubmitting, values)}
                        >Save weekly tax</button>
                        <button
                            type="button"
                            className={`p-1 mx-2 col-5 col-sm-6 col-lg-5 ${styles.allBtn}`}
                            style={{backgroundColor:"#6c757d"}}
                            onClick={() => resetForm(handleReset)}
                        >Discard</button>
                    </div>
                </div>
            </Form>
            )}
        </Formik>
    )
};

export default TaxForm;
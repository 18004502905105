import React, { useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import ModalButton from '../../../../../../components/ModalButton';
import { Formik, Field } from 'formik';
import { useSelector } from 'react-redux';
import styles from '../../rentals.module.css';

function GroupForm(props) {
  const { schema, handleSubmit } = props;
  const [color, setColor] = useState('#dcdcdc');
  const colorDictionary = useSelector(({ generalSetting }) => {
    const colorsSetting = generalSetting && generalSetting.setting.colors;
    const map = [];
    for (const key in colorsSetting) {
        if (key.startsWith('color')) {
            map.push(colorsSetting[key]);
        }
    };
    return map;
  });

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={
          props.initValue
            ? props.initValue
            : {
                groupName: '',
                color: '#dcdcdc',
              }
        }
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, handleChange, handleSubmit, initialValues, values }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Label className="mr-sm-0 col-sm-3 col-form-label">Group name*:</Form.Label>
              <div className="ml-sm-0 col-md-6">
                <Form.Control
                  type="text"
                  name="groupName"
                  onChange={handleChange}
                  isInvalid={!!errors.groupName}
                  defaultValue={initialValues.groupName}
                  style={{ fontSize: 'inherit' }}
                />
                <Form.Control.Feedback type="invalid">{errors.groupName}</Form.Control.Feedback>
              </div>
            </Form.Row>
            <br />
            <br />
            <Form.Row>
              <Form.Label className="col-1 col-sm-2 col-form-label">Colour:</Form.Label>
              <Col className='ml-5 ml-sm-4'>
                <Col className={styles.containerColor}>
                    {colorDictionary.map((item) => (
                        <div
                        className={`${styles.containerBox} ${color === item ? styles.active : ``}`}
                        style={{ background: `${item}` }}
                        onClick={() => {
                            setColor(item);
                            values.color = item;
                        }}
                        key={item}
                        />
                    ))}
                </Col>
                <Col className="p-0 mt-2 ml-2 d-flex align-items-center">
                    <span className={styles.color_picker_text}>Colour Picker:</span>
                    <Field
                        type={'color'}
                        name={'color'}
                        value={values.color}
                        onChange={handleChange}
                        onClick={() => setColor(null)}
                        className={styles.form_entry_color}
                        required
                    />
                </Col>
              </Col>
            </Form.Row>
            <br />
            <ModalButton btnText={'Save changes'} />
          </Form>
        )}
      </Formik>
    </>
  );
}

export default GroupForm;

import React from 'react';
import { Modal } from 'react-bootstrap';
import * as yup from 'yup';
import { withFormik } from 'formik';
import RentalForm from "./RentalForm";
import styles from '../../rentals.module.css';

import { toast } from "react-toastify";
toast.configure();

const EditMultipleRentals = (props) => {
    
    const {
        show,
        modalType,
        value,
        onEdit,
        onHide
    } = props;

    const handleSubmit = (data) => {

        const payloadMapper = {
            id: data.id,
            groupId: data.groupId && Number(data.groupId),
            propertyId: data.propertyId && Number(data.propertyId),
            addressInformation: data.addressInformation,
            parentRentalId: data.parentRentalId && Number(data.parentRentalId),
            rentalType: data.rentalType,
            maxOccup: data.maxOccup,
            maxChild: data.maxChild,
            wifiName: data.wifiName,
            wifiPassword: data.wifiPassword,
            securityCode: data.securityCode,
            notes: data.notes,
        };

        data['checkId'] = true;
        const payload = {};

        Object.keys(payloadMapper).forEach((field)=> {

            const fieldValue = payloadMapper[field];
            const check = `check${field.charAt(0).toUpperCase()}${field.slice(1)}`;

            if (fieldValue != undefined && data[check]) {
                if (['', 0].includes(fieldValue) && ['parentRentalId','rentalType'].includes(field)) {
                    payload[field] = null;
                } else {
                    payload[field] = fieldValue;
                };
            } else {
                if ('propertyId' === field && !data[check] && data.validatePropertyId && data.checkParentRentalId) {
                    payload[field] = data.newPropertyId ? Number(data.newPropertyId) : null;
                };
                
                if ('groupId' === field && !data[check] && data.validateGroupId && (data.checkParentRentalId || data.checkPropertyId)) {
                    payload[field] = data.newGroupId ? Number(data.newGroupId) : null;
                };

                if ('parentRentalId' === field && !data[check] && data.validateParentRentalId && data.checkPropertyId) {
                    payload[field] = data.newParentRentalId ? Number(data.newParentRentalId) : null;
                };
            };
        });
        
        // console.log(payload,'Edit Multiple Rentals');
        onEdit(payload);
    }

    const validateOnRelative = (value, checkTarget1, checkTarget2) => {
        switch (true) {
            case checkTarget1 && !value:
            case checkTarget2 && !value:
                return false;
            default:
                return true;
        };
    };

    const FormikEnhancer = withFormik({
        validationSchema:yup.object({
            id: yup.array().of(yup.number()).test('minLength', 'Rental selection is required', value => value && value.length > 0),
            groupId: yup.number().test('groupIdRequired', 'Select an option or uncheck the box', function(value) { return this.parent.checkGroupId && !value ? false : true }),
            propertyId: yup.number().test('propertyIdRequired', 'Property is required', function(value) {
                return validateOnRelative(value, this.parent.checkPropertyId, (!this.parent.validatePropertyId && this.parent.checkParentRentalId));
              }),
            addressInformation: yup.string(),
            parentRentalId: yup.mixed().test('parentRentalIdRequired', 'Parent Rental is required', function(value) {
                const isValidate = validateOnRelative(value, this.parent.checkParentRentalId, (!this.parent.validateParentRentalId && this.parent.propertyId));
                if (!isValidate && this.parent.propertyId) {
                    toast.warn('Property selected. Please select a "parent rental" that belongs to this property or set the value to None.');
                };
                return isValidate;
              }),
            rentalType: yup.string().test('rentalTypeRequired', 'Select an option or uncheck the box', function(value) { return this.parent.checkRentalType && !value ? false : true }),
            maxOccup: yup.number().test('maxOccupRequired', 'Must be a number greater or equal to 1', function(value) {
                return validateOnRelative(value, this.parent.checkMaxOccup, this.parent.checkMaxChild) && (this.parent.checkMaxOccup ? (value >= 1) : true);
              }),
            maxChild: yup.number().min(0, 'Must be a number greater than 0').test('maxChildRequired', 'Must be a number less than total number of occupants-1', function(value) {
                return validateOnRelative(value, this.parent.checkMaxChild, this.parent.checkMaxOccup) && (this.parent.checkMaxChild ? (value <= this.parent.maxOccup-1) : true);
              }),
            wifiName: yup.string(),
            wifiPassword: yup.string(),
            securityCode: yup.string(),
            notes: yup.string().max(250, 'Must be less than 250 characters'),
        }),
        validateOnBlur: false,
        validateOnChange: false,
        handleSubmit :  (values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
        },
        displayName: 'RentalForm',
      })(RentalForm);

    return(
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
                    Edit multiple rentals
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.deleteModalBody}>
                <FormikEnhancer modalType={modalType} />
            </Modal.Body>
        </Modal>
    )
}
export default EditMultipleRentals;
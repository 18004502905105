import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import * as yup from 'yup';

import DefaultChangeForm from './DefaultChangeForm';
import { editBulkDefault } from '../../../../../../general_redux/rates/actions';
import styles from '../../../Rentals/rentals.module.css';

function BulkDefaultChange(props) {
  const dispatch = useDispatch();

  const schemaDefaultRate = yup.object({
    multiRentals: yup.array().test('minLength', 'Rental selection is required', value => value && value.length > 0),
    dailyRate: yup.number().min(1, 'Must be a number greater than or equal to 1'),
    minimumStayRequirement: yup.number().min(1, 'Must be a number greater than or equal to 1').integer('Must be an integer'),
    maximumStayRequirement: yup.number().min(0, 'Must be a number greater than 0').integer('Must be an integer'),
    blockDateBefore: yup.number().min(0, 'Must be a number greater than 0').integer('Must be an integer'),
    blockDateAfter: yup.number().min(0, 'Must be a number greater than 0').integer('Must be an integer'),
  });

  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);

  const handleSubmit = (data, selectedData) => {

    const payloadMapper = {
      dailyRate: data.dailyRate && Number(data.dailyRate),
      minimumStayRequirement: data.minimumStayRequirement && Number(data.minimumStayRequirement),
      maximumStayRequirement: data.maximumStayRequirement && Number(data.maximumStayRequirement),
      blockDateBefore: data.blockDateBefore && Number(data.blockDateBefore),
      blockDateAfter: data.blockDateAfter && Number(data.blockDateAfter),
    };

    const selectedFields = {};

    Object.keys(payloadMapper).forEach((field)=> {

        const fieldValue = payloadMapper[field];
        const check = `check${field.charAt(0).toUpperCase()}${field.slice(1)}`;

        if (fieldValue != undefined && data[check]) {
            selectedFields[field] = fieldValue;
        };
    });

    const payload = [];
    selectedData.forEach((rental) => {
      if (rental.value === "default") {
        payload.push({
          default:{
            ...selectedFields,
          }
        })
      } else {
        payload.push({
          rentalId: Number(rental.value),
          ...selectedFields,
        })
      };
    });
    // console.log(payload);
    dispatch(editBulkDefault(payload));
    props.onHide();
  };

  const getOptions= ()=>{
    const tmp=[];
    tmp.push({
      value: "default",
      label:"New Rentals"
    });
    rentals.map((row) => (tmp.push({ value: row.id, label: row.name })));
    return tmp;
  }
  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>Set default rates and rules</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.deleteModalBody}>
        <DefaultChangeForm
          schema={schemaDefaultRate}
          handleSubmit={handleSubmit}
          options={getOptions()}
          onHide={props.onHide}
          initValue = {props.initValue}
        />
      </Modal.Body>
    </Modal>
  );
}
export default BulkDefaultChange;

import React from 'react';
import * as yup from 'yup';
import TaxForm from "./TaxForm";


const AddEditTax = (props) => {

    const {
        show,
        editValues,
        currency,
        taxList,
        setTaxList
    } = props;

    const validationSchema = yup.object({
        taxType: yup.string().required("Tax type is a required field"),
        name: yup.string().required("Name of tax is a required field"),
        amount: yup.number().required("Amount is a required field").min(0, 'Must be a positive number'),
        modality: yup.string(),
    });
    
    const initialValues = {
        name: editValues?.name || '',
        amount: editValues?.amount || '',
        taxType: editValues?.taxType || 'flat rate',
        modality: editValues?.modality || 'per stay',
        taxApplyAdults: editValues?.taxApplyAdults || false,
        taxApplyChildren: editValues?.taxApplyChildren || false,
    };

    const handleSubmit = (data) => {
        const taxObject = {
            name: data.name,
            taxType: data.taxType,
            amount: Number(data.amount),
            modality: data.modality,
            // taxApplyAdults: Boolean(Number(data.taxApplyAdults)),
            // taxApplyChildren: Boolean(Number(data.taxApplyChildren)),
        };

        if (editValues) {
            const updateTaxList = taxList;
            updateTaxList[editValues.index] = taxObject;
            setTaxList(updateTaxList);
        } else {
            setTaxList([...taxList, taxObject]);
        };
        
        show(false);
    };

    return (
        <TaxForm
            showTax={(e) => show(e)}
            initialValues={initialValues}
            validationSchema={validationSchema}
            handleSubmit={handleSubmit}
            currency={currency}
        />
    )
};

export default AddEditTax;
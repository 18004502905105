import React from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch} from "react-redux";
import * as yup from 'yup';
import styles from "../../../../Rentals/rentals.module.css";
import ExtraGuestForm from "./ExtraGuestForm";
import {addExtraGuestFee} from '../../../../../../../general_redux/rates/actions';

function ExtraGuestFee(props){
    const dispatch=useDispatch();

    const validationSchema = yup.object({
        rentalIds: yup.array().of(yup.number()).test('minLength', 'Rental selection is a required field', value => value && value.length > 0),
        guestLimit: yup.number().required('Guest limit is a required field').min(1, 'Must be a number greater than 0').integer('Must be a integer'),
        amount: yup.number().required('Amount per guest is a required field').min(0, 'Must be a positive number'),
        usdPerChild: yup.number().min(0, 'Must be a positive number').integer('Must be a integer'),
        // .test((value) => {
        //     return !this.parent.extraChildFee && 
        //     !(typeof value === 'number') ? false : true;
        // }),
    });

    const initialValues = {
        guestLimit: 2,
        amount: 0,
        extraChildFee: false,
        usdPerChild: 0,
    };

    const handleSubmit = (data) => {
        const payload = data.rentalIds.map((rentalId) => ({
            rentalId: rentalId,
            guestLimit: Number(data.guestLimit),
            amount: Number(data.amount),
            extraChildFee: Boolean(Number(data.extraChildFee)),
            usdPerChild: Boolean(Number(data.extraChildFee)) ? Number(data.amount) : Number(data.usdPerChild)
        }));
        dispatch(addExtraGuestFee(payload));
        props.onHide();
    };

    return(
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
                        Add/edit extra guest fee
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.deleteModalBody}>
                    <ExtraGuestForm
                        validationSchema={validationSchema}
                        handleSubmit={handleSubmit}
                        initValue={initialValues}
                        currency={props.currency}
                        onHide={props.onHide}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ExtraGuestFee;
import React from 'react';
import * as yup from 'yup';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CreateMode from '../../Calendar/components/EditBooking/components/CreateMode/CreateMode';
import styles from '../../Calendar/components/EditBooking/editBooking.module.css';

function EditInvoice(props){
    const { selectedData, handleSubmit, bookingId, handleReset } = props;
    const settings = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
    
    const dateObj = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
        return date;
    };

    const invoiceSchema =  yup.object().shape({
        invoiceNr:yup.string().trim().required('Invoice Nr is required!'),
        issueDate:yup.date().required('Issue Date is required!'),
        dueDate:yup.date().required('Due date is required!'),
        status: yup.string().required('Please add a status of payment!'),
        r_name:yup.string().required("Please enter recipient's name!"),
        r_comp:yup.string(),
        r_address1:yup.string(),
        r_address2:yup.string(),
        r_address3:yup.string(),
        s_name:yup.string().required("Sender name is required!"),
        s_address1:yup.string(),
        s_address2:yup.string(),
        s_address3:yup.string(),
        s_country:yup.string(),
        invoiceText:yup.string(),
        invoiceFooter:yup.string(),
        total:yup.number(),
        stripeButton:yup.bool(),
        markBookingAsPaid:yup.bool(),
        whenPaidMarkBookingAsPaid:yup.bool(),
    });

    const initialValues = {
        invoiceNr: selectedData && selectedData?.invoiceNumber || "",
        issueDate: selectedData && dateObj(selectedData?.invoiceDate) || new Date(),
        dueDate: selectedData && dateObj(selectedData?.dueDate) || new Date(),
        status: selectedData && selectedData?.paymentStatus || "Not Paid",
        r_name: selectedData && selectedData?.recipientDetail.name || "",
        r_comp: selectedData && selectedData?.recipientDetail.company || "",
        r_address1: selectedData && selectedData?.recipientDetail.address1 || "",
        r_address2: selectedData && selectedData?.recipientDetail.address2 || "",
        r_address3: selectedData && selectedData?.recipientDetail.address3 || "",
        s_name: selectedData && selectedData?.senderDetail.name || "",
        s_address1: selectedData && selectedData?.senderDetail.address1 || `${settings?.address1}`,
        s_address2: selectedData && selectedData?.senderDetail.address2 || `${settings?.address2}`,
        s_address3: selectedData && selectedData?.senderDetail.address3 || `${settings?.address3}`,
        s_country: selectedData && selectedData?.senderDetail.country || `${settings?.country}`,
        invoiceText: selectedData && selectedData?.invoiceText || "",
        invoiceFooter: selectedData && selectedData?.invoiceFooter || "",
        total: selectedData && selectedData?.total || 0,
        tableData: selectedData && selectedData?.invoiceItem || [],
        tableData2: selectedData && selectedData?.feeItems || [],
        tableData3: selectedData && selectedData?.taxItems || [],
        bookingNumber: selectedData && selectedData?.bookingNumber || "0",
        stripeButton: selectedData && selectedData?.stripeButton || false,
        markBookingAsPaid: selectedData && selectedData?.markBookingAsPaid || false,
        whenPaidMarkBookingAsPaid: selectedData && selectedData?.whenPaidMarkBookingAsPaid || false,
    };

    return(
        <>
        <Modal
          onHide={props.onHide}
          show={props.show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`px-sm-2 p-0 ${styles.modal_body}`}>
                <div className="p-3">
                    <CreateMode
                        validationSchema={invoiceSchema}
                        onSubmit={handleSubmit}
                        onReset={handleReset}
                        initialValues={initialValues}
                        bookingId={bookingId}
                    />
                </div>
            </Modal.Body>    
        </Modal>
        </>
    )
};

export default EditInvoice;
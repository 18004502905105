import React from 'react';
import {Modal} from 'react-bootstrap';
import * as yup from 'yup';

import styles from '../../../../Rentals/rentals.module.css';
import FeeForm from './FeeForm';

import { toast } from "react-toastify";
toast.configure();


function AddFee(props){

  const feeData = props.viewForEdit;

  const checkBoxWarning = () => {
    toast.warning("Does this fee apply to adults, children or both? It is mandatory to select at least one.");
    return false;
  };

  const validationSchema = yup.object({
    rentalIds: feeData ? yup.array() : yup.array().of(yup.number()).test('minLength', 'Rental selection is a required field', value => value && value.length > 0),
    name: yup.string().required("Name of fee is a required field"),
    feeType: yup.string().required("Fee type is a required field"),
    amount: yup.number().required("Amount is a required field").min(0, 'Must be a positive number'),
    modality: yup.string(),
    feeApplyAdults: yup.boolean(),
    feeApplyChildren: yup.boolean().test("feeApplyChildren", "",  function(value) {
      return ["per person", "pppn"].includes(this.parent.modality)
      && !this.parent.feeApplyAdults
      && !value ? checkBoxWarning() : true
    }),
  });

  const initialValues = {
    id: feeData?.id || null,
    rentalId: feeData?.rentalId || null,
    name: feeData?.name || '',
    amount: feeData?.amount || '',
    feeType: feeData?.feeType || 'flat rate',
    modality: feeData?.modality || 'per stay',
    feeApplyAdults: feeData?.feeApplyAdults || false,
    feeApplyChildren: feeData?.feeApplyChildren || false,
  };

  const handleSubmit=(data)=>{
    const payloadMapper = {
      name: data.name,
      feeType: data.feeType,
      amount: Number(data.amount),
      modality: data.modality,
      feeApplyAdults: Boolean(Number(data.feeApplyAdults)),
      feeApplyChildren: Boolean(Number(data.feeApplyChildren)),
    };

    if(feeData){
      const payload = {
        ...payloadMapper,
        id: data.id,
        rentalId: data.rentalId,
      };
      props.onEdit(payload);
    }else{
      const payload = data.rentalIds.map((rentalId) => ({
        ...payloadMapper,
        rentalId: rentalId,
      }));
      props.onEdit(payload);
    };
  };

    return(
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
            { feeData ? 'Edit fee' : 'Add fee' }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.deleteModalBody}>
        <FeeForm
         validationSchema={validationSchema}
         handleSubmit={handleSubmit}
         initValue={initialValues}
         currency={props.currency}
         onHide={props.onHide}
        />
      </Modal.Body>
    </Modal>
    )
}
export default AddFee;
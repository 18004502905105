import React, { useState,useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Modal } from 'react-bootstrap';
import {Container, Row, Col} from 'react-bootstrap';
import * as yup from "yup";
import moment from "moment";
import {Box, Popover} from "@material-ui/core";
import Table from '../../../../../../components/Table';
import InvoiceSend from "../../../../../../components/InvoiceSend";
import CreateMode from "../../../Calendar/components/EditBooking/components/CreateMode/CreateMode";
import Loading from "../../../../../../components/loading";
import {addInvoice, deleteInvoice, editInvoiceByGuest} from "../../../../../../general_redux/invoice/actions";
import styles from "../../../Calendar/components/EditBooking/editBooking.module.css";
import styles2 from "../../../Calendar/components/EditBooking/components/invoiceDetail.module.css";

function GuestInvoiceList(props) {
    const dispatch=useDispatch();
    const guestName=props.guestName;
    const guestDetails=props.guestDetail;

    const [viewSendInvoice, setViewSendInvoice] = useState(false);
    const [selectedInvoice,setSelectedInvoice]=useState(null);
    const [createMode,setCreateMode]=useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentDelete,setCurrentDelete]=useState(null);

    const settings = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
    const actionForEditInvoice = useSelector(({ invoice }) => invoice && invoice.actionForEditInvoice);
    const actionForDeleteInvoice = useSelector(({ invoice }) => invoice && invoice.actionForDeleteInvoice);

    const handleDiscardChanges = () => {
        setCreateMode(false);
        setSelectedInvoice(null);
    }

    useEffect(() => {
        if (actionForEditInvoice.success) {
            handleDiscardChanges();
        }
    }, [actionForEditInvoice.success]);

    const cols = [
        {lable: 'Created', key: 'invoiceDate'},
        {lable: 'Invoice No.', key: 'invoiceNumber'},
        {lable: 'Total', key: 'total'},
        {lable: 'Sent', key: 'markSent'},
        {lable: 'Payment Status', key: 'paymentStatus'},
        {lable: 'Actions', key: ''},
    ]

    const invoiceSchema =  yup.object().shape({
        invoiceNr:yup.string().trim().required('Invoice Nr is required!'),
        issueDate:yup.date().required('Issue Date is required!'),
        dueDate:yup.date().required('Due date is required!'),
        status: yup.string().required('Please add a status of payment!'),
        r_name:yup.string().required("Please enter recipient's name!"),
        r_comp:yup.string(),
        r_address1:yup.string(),
        r_address2:yup.string(),
        r_address3:yup.string(),
        s_name:yup.string().required("Sender name is required!"),
        s_address1:yup.string(),
        s_address2:yup.string(),
        s_address3:yup.string(),
        s_country:yup.string(),
        invoiceText:yup.string(),
        invoiceFooter:yup.string(),
        total:yup.number(),
        stripeButton:yup.bool(),
        markBookingAsPaid:yup.bool(),
        whenPaidMarkBookingAsPaid:yup.bool(),
    })

    const handleDownload=(invoiceD)=>{
        window.open(invoiceD.downloadLink,"_parent")
    }

    const dateObj = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
        return date;
    }

    const handleSendInvoice = (data) => {
        setSelectedInvoice(data);
        setViewSendInvoice(true);
    }

    const handleSendInvoiceEdit = (data) => {
        setViewSendInvoice(false);
        handleEdit(data);
    }
    
    const handleDelete=()=>{
        if(currentDelete && currentDelete.id){
            dispatch(deleteInvoice(currentDelete.id))
        }
        setAnchorEl(null);
        setCurrentDelete(null);
    }

    const handleEdit=(data)=>{
        setSelectedInvoice(data);
        setCreateMode(true);
    }

    const handleSubmit = (data, totalCost, tableData, tableData2, tableData3)=>{
        const payload={
            id: selectedInvoice.id,
            guestId:guestDetails.id,
            bookingId:selectedInvoice.bookingId,
            dueDate: moment(data.dueDate).utc(false).format('YYYY-MM-DD'),
            dueDateUtc: moment(data.dueDate).utc(true).format('YYYY-MM-DD'),
            invoiceDate: moment(data.issueDate).utc(false).format('YYYY-MM-DD'),
            invoiceDateUtc: moment(data.issueDate).utc(true).format('YYYY-MM-DD'),
            invoiceNumber:data.invoiceNr || selectedInvoice.invoiceNumber,
            invoiceItem: tableData.map(({ id, ...rest }) => rest),
            feeItems: tableData2.map(({ id, ...rest }) => rest),
            taxItems: tableData3.map(({ id, ...rest }) => rest),
            currency:selectedInvoice.currency,
            total: Number(totalCost.toFixed(2)),    
            markSent:selectedInvoice.markSent || false,
            downloadLink:selectedInvoice?.downloadLink || "",
            recipientDetail:{
                name:data.r_name,
                company:data.r_comp,
                address1:data.r_address1,
                address2:data.r_address2,
                address3: data.r_address3,
                email:guestDetails.email_id,
            },
            senderDetail:{
                name:data.s_name,
                address1:data.s_address1,
                address2:data.s_address2,
                address3: data.s_address3,
                country: data.s_country
            },
            invoiceText:data.invoiceText,
            invoiceFooter:data.invoiceFooter,
            paymentStatus:data.status,
            bookingNumber: data.bookingNumber || "0",
            stripeButton: data.stripeButton,
            markBookingAsPaid: data.markBookingAsPaid,
            whenPaidMarkBookingAsPaid: data.whenPaidMarkBookingAsPaid,
        }
        if(!selectedInvoice){
            dispatch(addInvoice(payload));
        }else{
            dispatch(editInvoiceByGuest(payload))
        }
    }

    const onHide=()=>{
        setCreateMode(false);
        setSelectedInvoice(null);
    }

    const initialValuesEdit = {
        invoiceNr: selectedInvoice && selectedInvoice?.invoiceNumber || "",
        issueDate: selectedInvoice && dateObj(selectedInvoice?.invoiceDate) || new Date(),
        dueDate: selectedInvoice && dateObj(selectedInvoice?.dueDate) || new Date(),
        status: selectedInvoice && selectedInvoice?.paymentStatus || "Not Paid",
        r_name: selectedInvoice && selectedInvoice?.recipientDetail.name || "",
        r_comp: selectedInvoice && selectedInvoice?.recipientDetail.company || "",
        r_address1: selectedInvoice && selectedInvoice?.recipientDetail.address1 || "",
        r_address2: selectedInvoice && selectedInvoice?.recipientDetail.address2 || "",
        r_address3: selectedInvoice && selectedInvoice?.recipientDetail.address3 || "",
        s_name: selectedInvoice && selectedInvoice?.senderDetail.name || "",
        s_address1: selectedInvoice && selectedInvoice?.senderDetail.address1 || `${settings?.address1}`,
        s_address2: selectedInvoice && selectedInvoice?.senderDetail.address2 || `${settings?.address2}`,
        s_address3: selectedInvoice && selectedInvoice?.senderDetail.address3 || `${settings?.address3}`,
        s_country: selectedInvoice && selectedInvoice?.senderDetail.country || `${settings?.country}`,
        invoiceText: selectedInvoice && selectedInvoice?.invoiceText || "",
        invoiceFooter: selectedInvoice && selectedInvoice?.invoiceFooter || "",
        total: selectedInvoice && selectedInvoice?.total || 0,
        tableData: selectedInvoice && selectedInvoice?.invoiceItem || [],
        tableData2: selectedInvoice && selectedInvoice?.feeItems || [],
        tableData3: selectedInvoice && selectedInvoice?.taxItems || [],
        bookingNumber: selectedInvoice && selectedInvoice?.bookingNumber || "0",
        stripeButton: selectedInvoice && selectedInvoice?.stripeButton || false,
        markBookingAsPaid: selectedInvoice && selectedInvoice?.markBookingAsPaid || false,
        whenPaidMarkBookingAsPaid: selectedInvoice && selectedInvoice?.whenPaidMarkBookingAsPaid || false,
    };

    const handleConfirmDialog = (e,row) => {
        setCurrentDelete(row);
        setAnchorEl( anchorEl ? null : e.currentTarget);
    }

    const invoiceDeleteDialog = () => {
        return (
            <Popover
                id={"popover-1"}
                open={Boolean(anchorEl)}
                onClose={handleConfirmDialog}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        borderRadius: 0,
                        display: "flex",
                    },
                }}
            >
                <div className={styles2.contentWrap}>
                    <div className={styles2.deleteTitle}>
                        Are you sure you want to delete this invoice?
                    </div>
                    <div className="text-center m-1">
                        <button
                            onClick={() => handleDelete()}
                            className={styles2.delConfirmBtn}
                        >
                            Delete
                        </button>
                        <button
                            onClick={handleConfirmDialog}
                            className={styles2.cancelBtn}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                <Box
                    className={styles2.rightArrow}
                />
            </Popover>
        );
    };

    return (
        <div>
            <Loading loadingStatus={actionForEditInvoice.loading || actionForDeleteInvoice.loading} />
            <Modal
                show={createMode}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className={styles.modalTitle}>Edit Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`px-sm-2 p-0 ${styles.modal_body}`}>
                    <div className="p-3">
                        <CreateMode
                            initialValues={initialValuesEdit}
                            validationSchema={invoiceSchema}
                            onSubmit={handleSubmit}
                            onReset={handleDiscardChanges}
                            bookingId={props.bookingId ? props.bookingId : selectedInvoice ? selectedInvoice.bookingId : ""}
                        />
                    </div>
                </Modal.Body>
            </Modal>
            {guestName && (
                <div className={styles.guestName}>{`Guest: ${guestName}`}</div>
            )}
            <Container fluid>
                <Row >
                    <Col xs={12} md={8} className={`pt-3`}>
                        <Table
                            rows={props.invoiceGuest}
                            cols={cols}
                            startKey={cols[0].key}
                            onDownload={handleDownload}
                            onDelete={(val) => handleDelete(val)}
                            onEdit={(data)=>handleEdit(data)}
                            confirmDeleteDialog={invoiceDeleteDialog}
                            handleConfirmDialog={handleConfirmDialog}
                            onSendGuest={(data) => handleSendInvoice(data)}
                        />
                    </Col>
                </Row>
            </Container>
            <InvoiceSend
                show = {viewSendInvoice}
                onHide = {() => setViewSendInvoice(false)}
                invoiceData = {selectedInvoice}
                onEdit={(data) => handleSendInvoiceEdit(data)}
            />
        </div>
    )
}
export default GuestInvoiceList;
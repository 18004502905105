import React, {useEffect} from 'react';
import {Modal} from 'react-bootstrap';

import {hideTawkComponent} from '../../common/functions/utils';
import styles from './invoiceSend.module.css';
import MainFile from './MainFile';

function InvoiceSend(props){

    useEffect(() => {
        hideTawkComponent(props.show);
    }, [props.show]);

    return(
        <div className={`pl-0`}>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className={styles.modalHeader}>
                        {"Sending Invoice"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.deleteModalBody}>
                    <MainFile {...props}/>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default InvoiceSend;
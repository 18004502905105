import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import getSymbolFromCurrency from 'currency-symbol-map';
import { Form,InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import styles from '../../../rates.module.css';
import MultiSelect from '../../BulkDefaultChanges/MultiSelect';
import {useSelector} from "react-redux";

function TaxForm({validationSchema, handleSubmit, initValue, currency}){
    const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
    const [count,setCount]=useState(0);
    const [selectedRentals, setSelectedRentals] = useState([]);
    const selectedCurr = initValue?.currency || currency;

    const sameCurrency=(row)=>{
      const currencyVal = rentals && rentals.length !== 0 && rentals[0].currency;
      return row.currency === currencyVal;
    };

    function diffCurrencyWarning() {
        if (!rentals.every(sameCurrency)) {
            setCount(count+1);
        }
    };

    useEffect( () => {
        count === 1 && toast.warn(`Different currencies used in different rentals. Check the changes.`
            ,{
                style:{
                    color:'#484848'
                }
            });
    }, [count]);

    const getOptions = () => {
        const options = rentals.map((row) => ({ value: row.id, label: row.name }));
        return options;
    };

    const resetForm = (handleReset) => {
      handleReset();
      setSelectedRentals([]);
  };

    return(
        <>
        <Formik
            validationSchema={validationSchema}
            initialValues={initValue}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
        {({ values, errors, handleChange, handleReset, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              { !values.id &&
                <>
                  <Form.Row>
                    <Form.Label className={`col-md-4 col-form-label`}>Select rental:*</Form.Label>
                    <div className="col-md-6">
                        <MultiSelect
                            options={getOptions(rentals)}
                            value={selectedRentals}
                            name="rentalIds"
                            onChange={(selectedOptions) => {
                                const selectedIds = selectedOptions.map(option => option.value);
                                setFieldValue('rentalIds', selectedIds);
                                setSelectedRentals(selectedOptions);
                            }}
                            onBlur={diffCurrencyWarning}
                            fieldHasError={errors.rentalIds}
                            placeholder={"---Select Rental---"}
                        />
                        <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.rentalIds}</div>
                    </div>
                  </Form.Row>
                  <br/>
                </>
              }
              <Form.Row>
                  <Form.Label className="col-md-4 col-form-label">Name of tax:*</Form.Label>
                  <div className="col-md-6">
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={handleChange}
                      defaultValue={values.name}
                      className={styles.form_entry}
                      isInvalid={!!errors.name}
                    />
                      <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.name}</div>
                  </div>
              </Form.Row>
              <br/>
              <Form.Row>
                  <Form.Label className="col-md-4 col-form-label">Type of tax:</Form.Label>
                  <div className="d-flex align-content-center col-md-6">
                    <Form.Check
                      inline
                      name="taxType"
                      label="percentage"
                      onChange={(data) => {
                        handleChange(data);
                        setFieldValue("modality", "per stay");
                        setFieldValue('taxApplyAdults', false);
                        setFieldValue('taxApplyChildren', false);
                      }}
                      value="percentage"
                      type="radio"
                      checked={values.taxType==="percentage"}
                    />
                    <Form.Check
                      inline
                      name="taxType"
                      label="flat rate"
                      onChange={handleChange}
                      value="flat rate"
                      type="radio"
                      checked={values.taxType==="flat rate"}
                      className="ml-3"
                    />
                  </div>
              </Form.Row>
              <br/>
              <Form.Row>
                  <Form.Label className="col-md-4 col-form-label">Amount:*</Form.Label>
                  <div className="col-md-6">
                  <InputGroup className="mb-1">
                    <Form.Control
                      type="text"
                      name="amount"
                      onChange={handleChange}
                      defaultValue={values.amount}
                      className={styles.form_entry}
                      isInvalid={!!errors.amount}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon2" className={styles.form_entry}
                      >{(values.taxType==="percentage")?"%":(getSymbolFromCurrency(`${selectedCurr}`))}</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                  </InputGroup>
                </div>
              </Form.Row>
              <br/>
              <Form.Row>
                  <Form.Label className="col-md-4 col-form-label">Modality:</Form.Label>
                  <div className="col-md-8">
                  <Form.Check
                    inline
                    name="modality"
                    label="per stay"
                    onChange={(data) => {
                        handleChange(data);
                        setFieldValue('taxApplyAdults', false);
                        setFieldValue('taxApplyChildren', false);
                    }}
                    value="per stay"
                    type="radio"
                    checked={values.modality==="per stay"}
                  />
                  <Form.Check
                    inline
                    name="modality"
                    label="per night"
                    onChange={(data) => {
                        handleChange(data);
                        setFieldValue('taxApplyAdults', false);
                        setFieldValue('taxApplyChildren', false);
                    }}
                    value="per night"
                    type="radio"
                    disabled={values.taxType==="percentage"}
                    checked={values.modality==="per night"}
                  />
                   <Form.Check
                    inline
                    name="modality"
                    label="per person"
                    onChange={(data) => {
                      if (["per stay", "per night"].includes(values.modality)) {
                        setFieldValue('taxApplyAdults', true);
                        setFieldValue('taxApplyChildren', true);
                      };
                      handleChange(data);
                    }}
                    value="per person"
                    type="radio"
                    disabled={values.taxType==="percentage"}
                    checked={values.modality==="per person"}
                  />
                  <Form.Check
                    inline
                    name="modality"
                    label="per person per night"
                    onChange={(data) => {
                      if (["per stay", "per night"].includes(values.modality)) {
                        setFieldValue('taxApplyAdults', true);
                        setFieldValue('taxApplyChildren', true);
                      };
                      handleChange(data);
                    }}
                    value="pppn"
                    type="radio"
                    disabled={values.taxType==="percentage"}
                    checked={values.modality==="pppn"}
                  />
                  </div>
              </Form.Row>
              <br/>
            { ["per person", "pppn"].includes(values.modality) &&
            <>
                <Form.Row>
                    <Form.Label className="col-md-6 col-form-label">Does this tax apply to adults?</Form.Label>
                    <div className="d-flex align-content-center col-md-4">
                      <Form.Check
                        inline
                        name="taxApplyAdults"
                        label="Yes"
                        onChange={handleChange}
                        value={1}
                        type="radio"
                        checked={Boolean(Number(values.taxApplyAdults))===true}
                      />
                      <Form.Check
                        inline
                        name="taxApplyAdults"
                        label="No"
                        onChange={handleChange}
                        value={0}
                        type="radio"
                        checked={Boolean(Number(values.taxApplyAdults))===false}
                        className="ml-3"
                      />
                    </div>
                </Form.Row>
                <Form.Row>
                    <Form.Label className="col-md-6 col-form-label">Does this tax apply to children?</Form.Label>
                    <div className="d-flex align-content-center col-md-4">
                      <Form.Check
                        inline
                        name="taxApplyChildren"
                        label="Yes"
                        onChange={handleChange}
                        type="radio"
                        value={1}
                        checked={Boolean(Number(values.taxApplyChildren))===true}
                      />
                      <Form.Check
                        className="ml-3"
                        inline
                        name="taxApplyChildren"
                        label="No"
                        onChange={handleChange}
                        type="radio"
                        value={0}
                        checked={Boolean(Number(values.taxApplyChildren))===false}
                      />
                    </div>
                </Form.Row>
                <br/>
            </>
            }
              <div className='p-2 d-flex justify-content-center'>
                  <div className='col-sm-8 d-flex justify-content-around'>
                      <button type="submit" className={`p-1 mx-2 col-5 ${styles.allBtn}`}>Save</button>
                      <button
                          type="reset"
                          className={`p-1 mx-2 col-5 ${styles.allBtn}`}
                          style={{backgroundColor:"#6c757d"}}
                          onClick={() => resetForm(handleReset)}
                      >Discard</button>
                  </div>
              </div>
          </Form>
        )}
        </Formik>
        </>
    )
}

export default TaxForm;